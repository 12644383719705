import { getDataTypeCategoriesRequest } from '../utils/myDataTypeDataRequestUtils';
import { getSchemas, setSchemas } from './useSchemasHook';
import { useStoreValue, setStoreState, getStoreState, setStoreStates } from './useStoreStateHook';
import { DATA_TYPE_TREE_VIEW_NESTING_LIMIT, DATA_TYPE_ID_OBJECT } from '../utils/constants';

export const storeNamespace = 'myDataTypes';

/**
 * Default State
 */
export const myDataTypesStates = {
    isReady: false,
    selectedDataType: {},
    recentlyUpdatedDataTypeId: '',
    categories: [],
    allDataTypes: {},
    fieldDataTypes: {},
    fields: [],

    /**
     * Data Table states
     */
    myDataTypes: [],
    currentDataTypesPage: 1,
    shouldReloadMyDataTypes: false,
};

/**
 * Reset my data types states
 * @param {Object} states
 */
export const resetMyDataTypeStates = (states = {}) => {
    setStoreStates({
        isReady: false,
        myDataTypes: [],
        selectedDataType: {},
        recentlyUpdatedDataTypeId: '',
        categories: [],
        fieldDataTypes: {},
        fields: [],
        ...states,
    });
};

/**
 * Reset selected Data Type states
 * @param {Object} states
 */
export const resetSelectedDataTypeStates = (states = {}) => {
    setStoreStates({
        isReady: false,
        selectedDataType: {},
        recentlyUpdatedDataTypeId: '',
        fields: [],
        ...states,
    });
};

/**
 * Initializes my data type states
 * Create fields array for my data types screen
 * Allows for drag and drop functionality
 * @param {Object} datatype
 */
export const initializeDataTypeFields = (datatype) => {
    setSelectedDataType(datatype);
    const translatedFields = [];
    let nestedLevel = 1;

    if (datatype?.fields?.length === 0) {
        return [];
    }

    /**
     * Traverse fields and add to fields array
     * @param {Array<Object>} fields
     * @param {Array<String>} parentPath
     * @param {Boolean} [parentIsObject=false]
     */
    const traverseFields = (fields, parentPath, parentIsObject = false) => {
        if (fields.length) {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];

                const newPath = [...parentPath, field._id];
                const isObject = field?.dataTypeId === DATA_TYPE_ID_OBJECT;
                const isSelfReference = field.dataTypeId === datatype._id;

                const fieldProps = {
                    ...field,
                    path: newPath,
                    hasNestedFields: false,
                    nestedLevel: nestedLevel,
                    isLastFieldInCurrentLevel: i === fields.length - 1,
                    expanded: false,
                    isObjectDataType: isObject,
                    canEdit: parentIsObject ? true : nestedLevel === 1 || (parentIsObject && field.fields?.length > 0),
                    isFirstFieldInCurrentLevel: i === 0,
                    isSelfReference,
                };

                translatedFields.push(fieldProps);

                const dataTypeFields = isObject
                    ? field?.fields || []
                    : (!isSelfReference && getDataTypeByDataTypeId(field?.dataTypeId)?.fields) || [];

                if (dataTypeFields.length && nestedLevel < DATA_TYPE_TREE_VIEW_NESTING_LIMIT) {
                    fieldProps.hasNestedFields = true;
                    nestedLevel += 1;
                    traverseFields(dataTypeFields, newPath, isObject);
                }
            }

            nestedLevel -= 1;
        }
    };

    traverseFields(datatype.fields, [datatype._id]);
    setFields(translatedFields);
};

/**
 * Set data type categories state after request
 */
export const setDataTypeCategories = async () => {
    if (getDataTypeCategories()?.length < 1) {
        const response = await getDataTypeCategoriesRequest();
        setStoreState('categories')(response.categories);
    }
};

/**
 * Get data type category state
 * @returns {Array}
 */
export const getDataTypeCategories = () => getStoreState('categories')([]);

/**
 * Get current page of myDataTypes data table and subscribe to changes
 * @returns {Integer}
 */
export const useCurrentPage = () => useStoreValue('currentDataTypesPage', storeNamespace)(1);

/**
 * Sets myDataTypes data table current page
 * @param {Integer} currPage
 */
export const setCurrentPage = (currPage) => setStoreState('currentDataTypesPage')(currPage);

/**
 * Set data types state
 * @param {Object} dataTypes Object of <dataTypeId, dataTypeObject>
 */
export const setMyDataTypes = (dataTypes) => {
    setStoreState('myDataTypes')(dataTypes);
};

/**
 * Set all data types state
 * @param {Object} dataTypes Object of <dataTypeId, dataTypeObject>
 */
export const setAllDataTypes = (dataTypes) => {
    setStoreState('allDataTypes')(dataTypes);
};

/**
 * Get all data types.
 * @returns {Object}
 */
export const getDataTypes = () => getStoreState('allDataTypes')({});

/**
 * Get data type by data type id.
 * @param {String} dataTypeId
 * @returns {Object}
 */
export const getDataTypeByDataTypeId = (dataTypeId) => getStoreState('allDataTypes')({})[dataTypeId] || {};

/**
 *
 * @param dataTypeId
 * @returns {*|{}}
 */
export const getDataTypeCloneByDataTypeId = (dataTypeId) => {
    const dataTypes = getStoreState('allDataTypes')({});

    return dataTypes[dataTypeId] ? { ...dataTypes[dataTypeId] } : {};
};

/**
 * Set recently updated Data Type Id
 * @param {String} dataTypeId
 */
export const setRecentlyUpdatedDataTypeId = (dataTypeId) => setStoreState('recentlyUpdatedDataTypeId')(dataTypeId);

/**
 * Get selected data type and subscribe to changes.
 * @returns {Object}
 */
export const useSelectedDataType = () => useStoreValue('selectedDataType', storeNamespace)({});

/**
 * Get selected data type.
 */
export const getSelectedDataType = () => getStoreState('selectedDataType')({});

/**
 * Set selected data type
 * @param {Object} dataType
 */
export const setSelectedDataType = (dataType) => {
    setStoreState('selectedDataType')(dataType);
};

/**
 * Get the recently updated dataType ID and subscribe to changes.
 * @returns {String}
 */
export const useRecentlyUpdatedDataTypeId = () => useStoreValue('recentlyUpdatedDataTypeId', storeNamespace)('');

/**
 * Handles updates to data types for data type table
 * @param {Object} dataTypeData
 * @param {String} action
 */
export const handleMyDataTypesUpdates = async (dataTypeData, action) => {
    const dataTypes = getStoreState('myDataTypes')([]),
        schemas = getSchemas(),
        allDataTypes = getStoreState('allDataTypes')({}),
        allDataTypesCopy = { ...allDataTypes };

    switch (action) {
        case 'create':
            setSchemas([dataTypeData, ...schemas]);
            setMyDataTypes([dataTypeData, ...dataTypes]);
            setAllDataTypes({ ...allDataTypesCopy, [dataTypeData._id]: dataTypeData });
            setRecentlyUpdatedDataTypeId(dataTypeData._id);
            break;
        case 'edit':
            setSchemas(schemas.map((schema) => (schema._id === dataTypeData._id ? dataTypeData : schema)));
            setMyDataTypes(dataTypes.map((dataType) => (dataType._id === dataTypeData._id ? dataTypeData : dataType)));
            setAllDataTypes({ ...allDataTypesCopy, [dataTypeData._id]: dataTypeData });
            setRecentlyUpdatedDataTypeId(dataTypeData._id);
            break;
        case 'delete':
            delete allDataTypesCopy[dataTypeData._id];
            setAllDataTypes({ ...allDataTypesCopy });
            setSchemas(schemas.filter((schemas) => schemas._id !== dataTypeData._id));
            setMyDataTypes(dataTypes.filter((dataType) => dataType._id !== dataTypeData._id));
            break;
        case 'copy':
            setSchemas([dataTypeData, ...schemas]);
            if (dataTypes.length > 10) {
                dataTypes.pop();
                setMyDataTypes([dataTypeData, ...dataTypes]);
            } else {
                setMyDataTypes([dataTypeData, ...dataTypes]);
            }
            setAllDataTypes({ ...allDataTypesCopy, [dataTypeData._id]: dataTypeData });
            setRecentlyUpdatedDataTypeId(dataTypeData._id);
            break;
        default:
            break;
    }

    setTimeout(() => {
        setStoreState('recentlyUpdatedDataTypeId')('');
    }, 4000);
};

/**
 * Get fields
 */
export const getFields = () => getStoreState('fields')([]);

/**
 * Get fields + added fields and subscribe to changes.
 * @returns {Array}
 */
export const useFields = () => useStoreValue('fields', storeNamespace)([]);

/**
 * Set fields.
 * @param {Array<Object>} fields Array of field objects
 */
export const setFields = (fields) => setStoreState('fields')(fields);

/**
 * Determine if field is visible
 * @param {Object} field
 * @returns {Boolean}
 */
export const isFieldVisible = (field) => {
    if (!field || !field.path) {
        return false;
    }

    const fields = getFields();

    if (field.path.length > 2) {
        const parentFieldPath = field.path.slice(0, 2);
        let parentField = null;

        while (parentFieldPath.length < field.path.length) {
            parentField = fields.find(
                (field) =>
                    field.path.length === parentFieldPath.length - 1 &&
                    field.path.every((id, index) => id === parentFieldPath[index]),
            );

            if (parentField?.expanded === false) {
                return false;
            } else {
                parentFieldPath.push(field.path[parentFieldPath.length]);
            }
        }

        parentField = fields.find(
            (field) =>
                field.path.length === parentFieldPath.length - 1 &&
                field.path.every((id, index) => id === parentFieldPath[index]),
        );

        if (parentField) {
            return parentField.expanded;
        }
    } else {
        return true;
    }
};

/**
 * Expand or collapse field
 * @param {Array<string>} fieldIds
 */
export const toggleFieldExpand = (fieldIds) => {
    const fields = getFields();
    const fieldRef = fields.find((field) => fieldIds.every((id, index) => id === field.path[index]));
    if (fieldRef) {
        fieldRef.expanded = !fieldRef.expanded;
        setFields([...fields]);
    }
};

/**
 * Handles updates to data type field for selected data type
 * @param {Object} fieldData
 * @param {String} action
 * @param {Number} index
 * @param {Array<String>} path of field ids
 * @param {String} addUnderField - fieldId to add under
 */
export const handleMyDataTypeFieldUpdates = async (fieldData, action, path, addUnderField) => {
    const selectedDataType = getStoreState('selectedDataType')({});
    const fields = getStoreState('fields')([]);

    switch (action) {
        case 'create': {
            const pathLength = addUnderField.length ? path.length - 1 : path.length;
            let dataTypeFields = selectedDataType.fields;

            for (let i = 1; i < pathLength; i++) {
                const field = dataTypeFields.find((field) => field._id === path[i]);
                if (field) {
                    if (!field?.fields) {
                        field.fields = [];
                    }
                    dataTypeFields = field.fields;
                }
            }

            if (addUnderField?.length) {
                dataTypeFields.splice(
                    dataTypeFields.findIndex((field) => field._id === addUnderField) + 1,
                    0,
                    fieldData,
                );
            } else {
                dataTypeFields.push({
                    ...fieldData,
                    fields: fieldData?.fields || [],
                });
            }

            const newFields = [
                {
                    ...fieldData,
                    path: addUnderField.length ? [...path.slice(0, -1), fieldData._id] : [...path, fieldData._id],
                    hasNestedFields: getDataTypeByDataTypeId(fieldData.dataTypeId)?.fields.length > 0 || false,
                    nestedLevel: addUnderField.length ? path.length - 1 : path.length,
                    isLastFieldInCurrentLevel: true,
                    expanded: false,
                    isObjectDataType: fieldData?.dataTypeId === DATA_TYPE_ID_OBJECT,
                    canEdit: true,
                    isFirstFieldInCurrentLevel: dataTypeFields.length - 1 === 0,
                },
            ];
            const dataType = getDataTypeByDataTypeId(fieldData.dataTypeId);
            let nestedLevel = path.length;

            if (dataType?.fields.length > 0) {
                const traverseFields = (fields, parentFieldPath) => {
                    nestedLevel++;
                    fields.forEach((field, index) => {
                        const dataType = getDataTypeByDataTypeId(field.dataTypeId);

                        newFields.push({
                            ...field,
                            path: [...parentFieldPath, field._id],
                            hasNestedFields: dataType.fields.length > 0 || false,
                            nestedLevel: nestedLevel,
                            isLastFieldInCurrentLevel: index === fields.length - 1,
                            expanded: false,
                            isObjectDataType: field.dataTypeId === DATA_TYPE_ID_OBJECT,
                            canEdit: false,
                            isFirstFieldInCurrentLevel: index === 0,
                        });

                        if (dataType.fields?.length && nestedLevel < DATA_TYPE_TREE_VIEW_NESTING_LIMIT) {
                            traverseFields(dataType.fields, [...parentFieldPath, field._id]);
                        }
                    });
                    nestedLevel--;
                };

                traverseFields(dataType.fields, [...path, fieldData._id]);
            }

            if (addUnderField.length) {
                // Index of the last field belonging to the field to add under
                const fieldIndex = fields.findIndex((field) => field._id === addUnderField);
                const originalNestedLevel = fields[fieldIndex].nestedLevel;

                if (fields[fieldIndex].isLastFieldInCurrentLevel) {
                    fields[fieldIndex].isLastFieldInCurrentLevel = false;
                } else {
                    newFields[0].isLastFieldInCurrentLevel = false;
                }

                let lastFieldIndex = fieldIndex + 1;

                if (lastFieldIndex < fields.length) {
                    while (lastFieldIndex < fields.length && fields[lastFieldIndex].nestedLevel > originalNestedLevel) {
                        lastFieldIndex++;
                    }
                }

                fields.splice(lastFieldIndex, 0, ...newFields);
            } else {
                if (fields.length < 1) {
                    fields.push(...newFields);
                } else {
                    // Adding top level field
                    if (path.length === 1) {
                        fields.find(
                            (field) => field.nestedLevel === 1 && field.isLastFieldInCurrentLevel,
                        ).isLastFieldInCurrentLevel = false;
                        fields.push(...newFields);
                    } else {
                        // Adding nested field
                        const fieldIndex = fields.findIndex(
                            (field) =>
                                field.path.length === path.length &&
                                field.path.every((id, index) => id === path[index]),
                        );
                        fields[fieldIndex].hasNestedFields = true;
                        fields[fieldIndex].expanded = true;

                        let i = fieldIndex + 1;

                        // If there are no nested fields in a object field
                        if (i > fields.length - 1 || fields[i].nestedLevel === path.length - 1) {
                            fields.splice(i, 0, ...newFields);
                        } else {
                            let lastNestedFieldIndex = i;

                            while (fields[i] && fields[i].nestedLevel > path.length - 1) {
                                if (fields[i].nestedLevel === path.length) {
                                    lastNestedFieldIndex = i;
                                }
                                i++;
                            }

                            if (fields[lastNestedFieldIndex].isLastFieldInCurrentLevel) {
                                fields[lastNestedFieldIndex].isLastFieldInCurrentLevel = false;
                            }

                            fields.splice(i, 0, ...newFields);
                        }
                    }
                }
            }

            break;
        }
        case 'update': {
            let dataTypeFields = selectedDataType.fields;

            for (let i = 1; i < path.length - 2; i++) {
                dataTypeFields = dataTypeFields.find((field) => field._id === path[i]).fields;
            }

            const dataTypeFieldIndex = dataTypeFields.findIndex((field) => field._id === path[path.length - 1]);
            const isDataTypeChanged = fieldData.dataTypeId !== dataTypeFields[dataTypeFieldIndex].dataTypeId;
            const updatedField = {
                ...dataTypeFields[dataTypeFieldIndex],
                ...fieldData,
            };

            if (updatedField.dataTypeId !== DATA_TYPE_ID_OBJECT) {
                updatedField.fields = [];
            }

            dataTypeFields[dataTypeFieldIndex] = updatedField;

            const fieldIndex = fields.findIndex(
                (field) => field.path.length === path.length && field.path.every((id, index) => id === path[index]),
            );

            const updatedFieldProps = {
                ...fields[fieldIndex],
                ...updatedField,
            };

            fields[fieldIndex] = updatedFieldProps;

            // Remove previous field nested fields and replace with updated nested fields
            let i = fieldIndex + 1;

            while (fields[i] && fields[i].nestedLevel > updatedFieldProps.nestedLevel) {
                i++;
            }

            if (isDataTypeChanged) {
                const newFields = [];
                const dataType = getDataTypeByDataTypeId(updatedField.dataTypeId);

                let nestedLevel = updatedFieldProps.nestedLevel;

                const traverseFields = (fields, parentFieldPath) => {
                    nestedLevel++;
                    fields.forEach((field, index) => {
                        const dataType = getDataTypeByDataTypeId(field.dataTypeId);

                        newFields.push({
                            ...field,
                            path: [...parentFieldPath, field._id],
                            hasNestedFields: dataType.fields.length > 0 || false,
                            nestedLevel: nestedLevel,
                            isLastFieldInCurrentLevel: index === fields.length - 1,
                            expanded: false,
                            isObjectDataType: field.dataTypeId === DATA_TYPE_ID_OBJECT,
                            canEdit: false,
                            isFirstFieldInCurrentLevel: index === 0,
                        });

                        if (dataType.fields?.length) {
                            traverseFields(dataType.fields, [...parentFieldPath, field._id]);
                        }
                    });
                    nestedLevel--;
                };

                traverseFields(dataType.fields, path);
                fields.splice(fieldIndex + 1, i - fieldIndex - 1, ...newFields);
                fields[fieldIndex].hasNestedFields = newFields.length > 0;
            }

            break;
        }
        case 'delete': {
            let dataTypeFields = selectedDataType.fields;

            for (let i = 1; i < path.length - 2; i++) {
                dataTypeFields = dataTypeFields.find((field) => field._id === path[i]).fields;
            }

            if (dataTypeFields.length) {
                const dataTypeFieldIndex = dataTypeFields.findIndex((field) => field._id === path.at(-1));
                dataTypeFields.splice(dataTypeFieldIndex, 1);
            }

            const fieldIndex = fields.findIndex(
                (field) => field.path.length === path.length && field.path.every((id, index) => id === path[index]),
            );
            const field = fields[fieldIndex];

            // Delete the field and all its nested fields
            // i is the ending index of the fields to delete
            let i = fieldIndex + 1;

            while (fields[i] && fields[i].nestedLevel > field.nestedLevel) {
                i++;
            }

            if (field.isFirstFieldInCurrentLevel && fields[i]) {
                fields[i].isFirstFieldInCurrentLevel = true;
            } else if (field.isLastFieldInCurrentLevel) {
                let newLastFieldIndex = fieldIndex - 1;

                while (fields[newLastFieldIndex] && fields[newLastFieldIndex].nestedLevel > field.nestedLevel) {
                    newLastFieldIndex--;
                }

                if (newLastFieldIndex >= 0) {
                    fields[newLastFieldIndex].isLastFieldInCurrentLevel = true;
                }
            }

            fields.splice(fieldIndex, i - fieldIndex);
            break;
        }
        default:
            break;
    }

    setStoreState('fields')([...fields]);
    setSelectedDataType({ ...selectedDataType });
};

const isNonObjectPrimitiveDataType = (dataTypeId) => {
    return getDataTypeByDataTypeId(dataTypeId).isPrimitive && dataTypeId !== DATA_TYPE_ID_OBJECT;
};

/**
 * Move field upwards
 * @param {string} fieldId
 * @param {string} direction
 */
export const moveFieldSinglePosition = (fieldId, direction, path) => {
    const selectedDataType = getStoreState('selectedDataType')({});
    let datatypeFields = selectedDataType.fields;

    for (let i = 1; i < path.length - 1; i++) {
        datatypeFields = datatypeFields.find((field) => field._id === path[i]).fields;
    }

    // i is the field index
    const i = datatypeFields.findIndex((field) => field._id === fieldId);
    const temp = datatypeFields[i];

    if (direction === 'up') {
        datatypeFields[i] = datatypeFields[i - 1];
        datatypeFields[i - 1] = temp;
    } else {
        datatypeFields[i] = datatypeFields[i + 1];
        datatypeFields[i + 1] = temp;
    }

    const fields = getFields();
    const fieldIndex = fields.findIndex((field) => field._id === fieldId);
    let fieldIndexToMoveTo = fieldIndex + (direction === 'up' ? -1 : 1);

    while (fields[fieldIndexToMoveTo].nestedLevel > fields[fieldIndex].nestedLevel) {
        fieldIndexToMoveTo += direction === 'up' ? -1 : 1;
    }

    const currentField = fields[fieldIndex];
    const displacedField = fields[fieldIndexToMoveTo];

    const isFieldPrimitive = isNonObjectPrimitiveDataType(currentField.dataTypeId);
    const isDisplacedFieldPrimitive = isNonObjectPrimitiveDataType(displacedField.dataTypeId);

    if (direction === 'up') {
        if (displacedField.isFirstFieldInCurrentLevel) {
            fields[fieldIndex].isFirstFieldInCurrentLevel = true;
            fields[fieldIndexToMoveTo].isFirstFieldInCurrentLevel = false;
        } else if (currentField.isLastFieldInCurrentLevel) {
            fields[fieldIndex].isLastFieldInCurrentLevel = false;
            fields[fieldIndexToMoveTo].isLastFieldInCurrentLevel = true;
        }
    } else if (direction === 'down') {
        if (displacedField.isLastFieldInCurrentLevel) {
            fields[fieldIndex].isLastFieldInCurrentLevel = true;
            fields[fieldIndexToMoveTo].isLastFieldInCurrentLevel = false;
        } else if (currentField.isFirstFieldInCurrentLevel) {
            fields[fieldIndex].isFirstFieldInCurrentLevel = false;
            fields[fieldIndexToMoveTo].isFirstFieldInCurrentLevel = true;
        }
    }

    if (!isFieldPrimitive && !isDisplacedFieldPrimitive) {
        if (direction === 'up') {
            // 'i' wil end on the index on the last field for the field being moved if it's an object
            const fieldsToMove = [currentField];
            let i = fieldIndex + 1;
            while (fields[i] && fields[i].nestedLevel > currentField.nestedLevel) {
                fieldsToMove.push(fields[i]);
                i++;
            }

            /**
             * Move the field to the new position after removing it from the current position
             */
            fields.splice(fieldIndex, fieldsToMove.length);
            fields.splice(fieldIndexToMoveTo, 0, ...fieldsToMove);
        } else {
            // 'i' wil end on the index on the last field for the displaced field if it's an object
            let i = fieldIndexToMoveTo + 1;
            while (fields[i] && fields[i].nestedLevel > displacedField.nestedLevel) {
                i++;
            }

            const fieldsToMove = fields.slice(fieldIndex, fieldIndexToMoveTo);
            fields.splice(i, 0, ...fieldsToMove);
            fields.splice(fieldIndex, fieldsToMove.length);
        }
    } else if (!isFieldPrimitive) {
        if (direction === 'up') {
            let i = fieldIndex + 1;
            while (fields[i].nestedLevel > currentField.nestedLevel) {
                i++;
            }

            // Move the displaced field to after the current field and remove displaced field from its original position
            fields.splice(i, 0, displacedField);
            fields.splice(fieldIndexToMoveTo, 1);
        } else {
            // Move the displaced field to before the current field and remove displaced field from its original position
            fields.splice(fieldIndexToMoveTo, 1);
            fields.splice(fieldIndex, 0, displacedField);
        }
    } else if (!isDisplacedFieldPrimitive) {
        if (direction === 'up') {
            fields.splice(fieldIndex, 1);
            fields.splice(fieldIndexToMoveTo, 0, currentField);
        } else {
            let i = fieldIndexToMoveTo + 1;
            while (fields[i] && fields[i].nestedLevel > displacedField.nestedLevel) {
                i++;
            }

            fields.splice(i, 0, currentField);
            fields.splice(fieldIndex, 1);
        }
    } else {
        const temp = fields[fieldIndexToMoveTo];
        fields[fieldIndexToMoveTo] = fields[fieldIndex];
        fields[fieldIndex] = temp;
    }

    setFields([...fields]);
    setSelectedDataType({ ...selectedDataType });
};

/**
 * Move field after drag ends
 * @param {Object} draggedField
 * @param {Object} draggedOverField
 * @param {Boolean} isAboveDestinationField
 * @param {Array<String>} fieldPath
 *
 * Note: draggedOverField is the field next to which the field is to be moved
 * It can be a child field of another field
 * isAboveDestinationField determines if the draggedField is above or below the draggedOverField field
 */
export const handleMoveField = (draggedField, draggedOverField, isAboveDestinationField, fieldPath) => {
    const fields = getFields();
    const fieldStartIndex = fields.findIndex((field) => field._id === draggedField._id);
    let destinationFieldStartIndex = fields.findIndex(
        (field) =>
            field.path.length === draggedOverField.path.length &&
            field.path.every((id, index) => id === draggedOverField.path[index]),
    );

    while (fields[destinationFieldStartIndex].nestedLevel > fields[fieldStartIndex].nestedLevel) {
        destinationFieldStartIndex--;
    }

    const field = fields[fieldStartIndex];
    const destinationField = fields[destinationFieldStartIndex];

    const selectedDataType = getStoreState('selectedDataType')({});
    let datatypeFields = selectedDataType.fields;

    for (let i = 1; i < fieldPath.length - 1; i++) {
        datatypeFields = datatypeFields.find((field) => field._id === fieldPath[i]).fields;
    }

    const fieldIndex = datatypeFields.findIndex((field) => field._id === draggedField._id);
    const temp = datatypeFields[fieldIndex];
    const destinationFieldIndex = datatypeFields.findIndex((field) => field._id === destinationField._id);

    if (isAboveDestinationField) {
        datatypeFields.splice(destinationFieldIndex, 0, temp);
        datatypeFields.splice(fieldIndex + 1, 1);
    } else {
        datatypeFields.splice(destinationFieldIndex + 1, 0, temp);
        datatypeFields.splice(fieldIndex, 1);
    }

    if (isAboveDestinationField) {
        if (field.isLastFieldInCurrentLevel) {
            field.isLastFieldInCurrentLevel = false;
            fields.find((field) => field._id === datatypeFields[fieldIndex]._id).isLastFieldInCurrentLevel = true;
        }

        if (destinationFieldIndex === 0) {
            field.isFirstFieldInCurrentLevel = true;
            fields.find((field) => field._id === datatypeFields[1]._id).isFirstFieldInCurrentLevel = false;
        }
    } else {
        if (field.isFirstFieldInCurrentLevel) {
            field.isFirstFieldInCurrentLevel = false;
            fields.find((field) => field._id === datatypeFields[0]._id).isFirstFieldInCurrentLevel = true;
        }

        if (destinationFieldIndex === datatypeFields.length - 1) {
            field.isLastFieldInCurrentLevel = true;
            fields.find(
                (field) => field._id === datatypeFields[datatypeFields.length - 2]._id,
            ).isLastFieldInCurrentLevel = false;
        }
    }

    let fieldEndIndex = fieldStartIndex + 1;
    let destinationFieldEndIndex = destinationFieldStartIndex + 1;

    while (fields[fieldEndIndex] && fields[fieldEndIndex].nestedLevel > field.nestedLevel) {
        fieldEndIndex++;
    }

    while (
        fields[destinationFieldEndIndex] &&
        fields[destinationFieldEndIndex].nestedLevel > destinationField.nestedLevel
    ) {
        destinationFieldEndIndex++;
    }

    const fieldsToMove = fields.slice(fieldStartIndex, fieldEndIndex);
    fields.splice(fieldStartIndex, fieldsToMove.length);

    if (isAboveDestinationField) {
        fields.splice(destinationFieldStartIndex, 0, ...fieldsToMove);
    } else {
        fields.splice(destinationFieldEndIndex - fieldsToMove.length, 0, ...fieldsToMove);
    }

    setFields([...fields]);
};

/**
 * Sets if field tree view for data type is ready to be displayed
 * @param {Boolean} isReady
 */
export const setIsReady = (isReady) => setStoreState('isReady')(isReady);

/**
 * Get isReady and subscribe to changes
 * @returns {Boolean}
 */
export const useIsReady = () => useStoreValue('isReady', storeNamespace)(false);

/**
 * Sets if myDataTypes needs to reload
 * @param {Boolean} shouldReload
 */
export const setShouldReload = (shouldReload) => setStoreState('shouldReloadMyDataTypes')(shouldReload);

/**
 * Get should reload and subscribe to changes
 * @returns {Boolean}
 */
export const useShouldReload = () => useStoreValue('shouldReloadMyDataTypes', storeNamespace)(false);

/**
 * Get the my data types list data and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useMyDataTypes = () => useStoreValue('myDataTypes', storeNamespace)([]);

/**
 * Get all data types and subscribe to changes.
 * @returns {Object}
 */
export const useAllDataTypes = () => useStoreValue('allDataTypes', storeNamespace)({});
