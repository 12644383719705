export const ENVIRONMENT_LOCAL = 'local';
export const ENVIRONMENT_TEST = 'test';
export const ENVIRONMENT_DEVELOPMENT = 'dev';
export const ENVIRONMENT_STAGING = 'staging';
export const ENVIRONMENT_PRODUCTION = 'production';

export const DOMAIN_INTELY = 'intely.io';

export const IS_PRODUCTION = process.env.REACT_APP_CURRENT_ENVIRONMENT === ENVIRONMENT_PRODUCTION;
export const IS_DEVELOPMENT =
    process.env.REACT_APP_CURRENT_ENVIRONMENT === ENVIRONMENT_DEVELOPMENT ||
    process.env.REACT_APP_CURRENT_ENVIRONMENT === ENVIRONMENT_LOCAL;

let domainSuffix;
switch (process.env.REACT_APP_CURRENT_ENVIRONMENT) {
    case ENVIRONMENT_PRODUCTION:
        domainSuffix = DOMAIN_INTELY;
        break;
    case ENVIRONMENT_STAGING:
        domainSuffix = 'stg.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_DEVELOPMENT:
        domainSuffix = 'dev.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_TEST:
        domainSuffix = 'test.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_LOCAL:
    default:
        domainSuffix = 'local.' + DOMAIN_INTELY;
        break;
}
export const CURRENT_DOMAIN_SUFFIX = domainSuffix;

/**
 * Determines how often(in ms) the front-end will validate user information with the back-end.
 * @type {number}
 */
export const SESSION_TIMEOUT_CHECK_INTERVAL = 30000;

/**
 * Determines how long at a minimum to wait before refreshing the user's session based on activity. If set to 600,000,
 * then the first activity after ten minutes will trigger a session refresh.
 * @type {number}
 */
export const SESSION_TOKEN_REFRESH_MINIMUM_WAIT_THRESHOLD = 600000; // 10 minutes

/**
 * Determines how close to session expiration the refresh popup will be displayed to a user. If set to 60,000, then user
 * will receive the popup six minutes before their session is terminated.
 * @type {number}
 */
export const SESSION_TOKEN_REFRESH_DISPLAY_POPUP_THRESHOLD = 360000; // 6 minutes

/**
 * Set to true if we want to refresh the users session based on activity.
 * @type {boolean}
 */
export const SESSION_TOKEN_REFRESH_ENABLE_ON_USER_ACTIVITY = true;

export const FLAG_ENABLE_STRIPE_BILLING = false;

export const INTEGRATION_STATUS_ACTIVE = 1;
export const INTEGRATION_STATUS_PENDING = 2;
export const INTEGRATION_STATUS_DISABLED = 3;

export const TRIGGER_SCHEDULER_FREQUENCY_ONCE = 0;
export const TRIGGER_SCHEDULER_FREQUENCY_RECURRING = 1;

export const TRIGGER_SCHEDULER_INTERVAL_HOURLY = 0;
export const TRIGGER_SCHEDULER_INTERVAL_DAILY = 1;
export const TRIGGER_SCHEDULER_INTERVAL_WEEKLY = 2;
export const TRIGGER_SCHEDULER_INTERVAL_MONTHLY = 3;
export const TRIGGER_SCHEDULER_INTERVAL_CUSTOM = 4;

export const TRIGGER_SCHEDULER_MONTHLY_INTERVAL_LAST = 'last';
export const TRIGGER_SCHEDULER_MONTHLY_INTERVAL_FIRST = 'first';
export const TRIGGER_SCHEDULER_MONTHLY_INTERVAL_MIDDLE = 'middle';

export const TRIGGER_API_CALL = 'API';
export const TRIGGER_TYPE_MANUAL = 'Manual';
export const TRIGGER_TYPE_SCHEDULER = 'Scheduler';
export const TRIGGER_TYPE_SCHEDULER_LABEL = 'Schedule';
export const TRIGGER_API_CALL_LABEL = 'API Call';
export const TRIGGER_API_CALL_DESCRIPTION = 'Run an integration via an API call';
export const TRIGGER_TYPE_MANUAL_DESCRIPTION = 'Run an integration manually';
export const TRIGGER_TYPE_SCHEDULER_DESCRIPTION = 'Time-based executions';

export const STEP_TYPE_LOG = 'Log';
export const STEP_TYPE_LOOP = 'Loop';
export const STEP_TYPE_DELAY = 'Delay';
export const STEP_TYPE_TRIGGER = 'Trigger';
export const STEP_TYPE_CONVERT = 'Convert';
export const STEP_TYPE_STOP_LOOP = 'Break Loop';
export const STEP_TYPE_COMPARISON = 'Comparison';
export const STEP_TYPE_APP_REQUEST = 'App Request';
export const STEP_TYPE_CONDITIONAL = 'Conditional';
export const STEP_TYPE_STOP_WORKFLOW = 'Exit';
export const STEP_TYPE_CONTINUE_LOOP = 'Continue Loop';
export const STEP_TYPE_END_CONDITIONAL = 'End Conditional';
export const STEP_TYPE_SET_VARIABLE = 'Set Variable';
export const STEP_TYPE_INCREMENT_DECREMENT_COUNTER = 'Increment/Decrement Counter';
export const STEP_TYPE_RUN_INTEGRATION = 'Run Integration';
export const STEP_TYPE_SPLIT_FILE = 'Split File';
export const STEP_TYPE_COMBINE_FILES = 'Combine Files';
export const STEP_TYPE_RESTFUL_REQUEST = 'RESTful Request';
export const STEP_TYPE_RESTFUL_AUTHORIZATION = 'Authorization';
export const STEP_TYPE_CROSSWALK = 'Crosswalk';
export const STEP_TYPE_EXECUTE_SCRIPT = 'Execute Script';

export const STEP_TYPE_NAME_LOOP = 'Loop';
export const STEP_TYPE_NAME_LOG = 'Logging';
export const STEP_TYPE_NAME_DELAY = 'Delay';
export const STEP_TYPE_NAME_TRIGGER = 'Trigger';
export const STEP_TYPE_NAME_CONVERT = STEP_TYPE_CONVERT;
export const STEP_TYPE_NAME_FUNCTION = 'Function';
export const STEP_TYPE_NAME_STOP_LOOP = 'Stop Loop';
export const STEP_TYPE_NAME_COMPARISON = STEP_TYPE_COMPARISON;
export const STEP_TYPE_NAME_CONDITIONAL = STEP_TYPE_CONDITIONAL;
export const STEP_TYPE_NAME_APP_REQUEST = STEP_TYPE_APP_REQUEST;
export const STEP_TYPE_NAME_STOP_WORKFLOW = 'Stop Workflow';
export const STEP_TYPE_NAME_CONTINUE_LOOP = STEP_TYPE_CONTINUE_LOOP;
export const STEP_TYPE_NAME_END_CONDITIONAL = STEP_TYPE_END_CONDITIONAL;
export const STEP_TYPE_NAME_SET_VARIABLE = 'Modify Variable';
export const STEP_TYPE_NAME_INCREMENT_DECREMENT_COUNTER = 'Inc/Dec Counter';
export const STEP_TYPE_NAME_INTEGRATION = 'Run Integration';
export const STEP_TYPE_NAME_SPLIT_FILE = STEP_TYPE_SPLIT_FILE;
export const STEP_TYPE_NAME_COMBINE_FILES = STEP_TYPE_COMBINE_FILES;
export const STEP_TYPE_NAME_RESTFUL_REQUEST = STEP_TYPE_RESTFUL_REQUEST;
export const STEP_TYPE_NAME_RESTFUL_AUTHORIZATION = 'Authorization';
export const STEP_TYPE_NAME_CROSSWALK = STEP_TYPE_CROSSWALK;
export const STEP_TYPE_NAME_EXECUTE_SCRIPT = STEP_TYPE_EXECUTE_SCRIPT;

export const STEP_MODE_NORMAL = 'normal';
export const STEP_MODE_ADVANCED = 'advanced';

export const STEP_LOOP_INPUT_TYPE_PREVIOUS_STEP_OUTPUT = 'previousStepValue';
export const STEP_LOOP_INPUT_TYPE_MULTI_RECORD_FILE = 'multiRecordFile';

export const STEP_LOOP_TYPE_FOR_EACH = 'foreach';
export const STEP_LOOP_TYPE_WHILE = 'while';
export const STEP_LOOP_TYPE_DO_WHILE = 'do-while';

export const DASHBOARD_TAB_VENDORS = 1;
export const DASHBOARD_TAB_INTEGRATIONS = 0;

/**
 * MUI exact-prop key.
 * Note: The value of this property has a symbol attached to it.
 */
export const MUI_EXACT_PROP_KEY = 'exact-prop: ​';

export const MAPPING_TREE_VIEW_NESTING_LIMIT = 10;
export const DATA_TYPE_TREE_VIEW_NESTING_LIMIT = 20;

export const SIDEBAR_WIDTH = 215;
export const SIDEBAR_COLLAPSE_WIDTH = 70;
export const ROSETTA_MAPPER_SIDEBAR_WIDTH = 250;
export const INTEGRATION_BUILDER_STEP_SIDEBAR_WIDTH = 500;
export const INTEGRATION_BUILDER_STEP_SIDEBAR_HEIGHT = '81.3vh';
export const INTEGRATION_BUILDER_STEP_SIDEBAR_MAX_WIDTH = 900;

export const APP_INSTANCES_DASHBOARD = 'dashboard';
export const APP_INSTANCES_APP_GALLERY = 'gallery';
export const APP_INSTANCES_REGISTRATION = 'registration';

export const STEP_REQUEST_BODY_VALUE_TYPE_NONE = 'none';
export const STEP_REQUEST_BODY_VALUE_TYPE_TEXT = 'text';
export const STEP_REQUEST_BODY_VALUE_TYPE_MAPPING = 'mapping';
export const STEP_REQUEST_BODY_VALUE_TYPE_STEP_OUTPUT = 'stepOutput';

export const INTELY_ORGANIZATION_ID = '6229203805eef05a633fffff';

export const CONDITIONAL_EXPRESSION_NESTED_LEVEL_LIMIT = 2;

export const CONDITIONAL_TYPE_IF = 'if';
export const CONDITIONAL_TYPE_CASE = 'switch';

export const CONDITIONAL_TYPES = [
    { value: CONDITIONAL_TYPE_IF, label: 'Complex (IF) ' },
    { value: CONDITIONAL_TYPE_CASE, label: 'Case' },
];

export const STEP_ELSE_CONDITIONAL_NAME = 'Else';
export const STEP_CONDITIONAL_TYPE_DEFAULT = 'if';
export const STEP_CONDITIONAL_NAME_DEFAULT = 'Condition 1';

export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EQUALS = '==';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EQUALS_INSENSITIVE = '=~';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EQUALS = '!=';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EQUALS_INSENSITIVE = '!~';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_GREATER_THAN = '>';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_GREATER_THAN_EQUALS = '>=';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_LESS_THAN = '<';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_LESS_THAN_EQUALS = '<=';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_CONTAINS = 'contains';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_CONTAINS = 'not contains';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EXISTS = 'exists';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EXISTS = 'not exists';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EMPTY = 'empty';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EMPTY = 'not empty';
export const INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_REGEX = 'regex';

// '==', '!=', '=~', '!~', '>', '>=', '<', '<=';
export const STEP_TYPE_CONDITIONAL_COMPARISON_OPERATORS = [
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EQUALS, label: 'Equal To (case sensitive)' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EQUALS, label: 'Not Equal To (case sensitive)' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EQUALS_INSENSITIVE, label: 'Equal To (case insensitive)' },
    {
        value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EQUALS_INSENSITIVE,
        label: 'Not Equal To (case insensitive)',
    },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_GREATER_THAN, label: 'Greater Than' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_GREATER_THAN_EQUALS, label: 'Greater Than or Equal To' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_LESS_THAN, label: 'Less Than' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_LESS_THAN_EQUALS, label: 'Less Than or Equal To' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_CONTAINS, label: 'Contains' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_CONTAINS, label: 'Not Contains' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_REGEX, label: 'Regular Expression' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EMPTY, label: 'Is Empty' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EMPTY, label: 'Not Empty' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EXISTS, label: 'Exists' },
    { value: INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EXISTS, label: 'Not Exists' },
];

export const INTEGRATION_BUILDER_COLOR_SCHEMES = {
    [STEP_TYPE_TRIGGER]: {
        borderColor: '#DCE8FF',
        primaryColor: '#3A70D4',
    },
    [STEP_TYPE_APP_REQUEST]: {
        borderColor: '#c656f229',
        primaryColor: '#c656f2',
    },
    [STEP_TYPE_CONVERT]: {
        borderColor: '#95f1ff57',
        primaryColor: '#00C4E1',
    },
    [STEP_TYPE_CONDITIONAL]: {
        borderColor: '#C4F0D7',
        primaryColor: '#27AE60',
    },
    [STEP_TYPE_END_CONDITIONAL]: {
        borderColor: '#6ca822',
        primaryColor: '#72ae27',
    },
    [STEP_TYPE_STOP_WORKFLOW]: {
        borderColor: '#FFE0E0',
        primaryColor: '#EB5757',
    },
    [STEP_TYPE_LOOP]: {
        borderColor: '#D4BAEC',
        primaryColor: '#875BAF',
    },
    [STEP_TYPE_STOP_LOOP]: {
        borderColor: '#FFE8D3',
        primaryColor: '#F2994A',
    },
    [STEP_TYPE_LOG]: {
        borderColor: '#E0E0E0',
        primaryColor: '#BDBDBD',
    },
    [STEP_TYPE_CONTINUE_LOOP]: {
        borderColor: '#a000ff1f',
        primaryColor: '#a000ff',
    },
    [STEP_TYPE_DELAY]: {
        borderColor: '#484747d1',
        primaryColor: '#484747',
    },
    [STEP_TYPE_SET_VARIABLE]: {
        borderColor: '#82b29c',
        primaryColor: '#2d805a',
    },
    [STEP_TYPE_COMPARISON]: {
        borderColor: '#dc840c',
        primaryColor: '#e18200',
    },
    [STEP_TYPE_INCREMENT_DECREMENT_COUNTER]: {
        borderColor: '#f2c94c',
        primaryColor: '#f3d886',
    },
    [STEP_TYPE_RUN_INTEGRATION]: {
        borderColor: '#496820',
        primaryColor: '#8bc53d',
    },
    [STEP_TYPE_SPLIT_FILE]: {
        borderColor: '#dcaaff',
        primaryColor: '#5f2eae',
    },
    [STEP_TYPE_COMBINE_FILES]: {
        borderColor: '#3ab280',
        primaryColor: '#71bf66',
    },
    [STEP_TYPE_RESTFUL_REQUEST]: {
        borderColor: '#30c2b6',
        primaryColor: '#46c4ba',
    },
    [STEP_TYPE_RESTFUL_AUTHORIZATION]: {
        borderColor: '#113946',
        primaryColor: '#BCA37F',
    },
    [STEP_TYPE_CROSSWALK]: {
        borderColor: '#34a0a4',
        primaryColor: '#1a759f',
    },
    [STEP_TYPE_EXECUTE_SCRIPT]: {
        borderColor: '#34a482',
        primaryColor: '#1a589f',
    },
};

export const STEP_RUN_INTEGRATION_MODE_SYNC = 'sync';
export const STEP_RUN_INTEGRATION_MODE_ASYNC = 'async';

export const STEP_FIELD_SELECT_INPUT_TYPE_STEP_PARAMETER = 'stepParameter';
export const STEP_FIELD_SELECT_INPUT_TYPE_CONVERT = STEP_TYPE_CONVERT;
export const STEP_FIELD_SELECT_INPUT_TYPE_REQUEST_BODY_STEP_OUTPUT = STEP_REQUEST_BODY_VALUE_TYPE_STEP_OUTPUT;
export const STEP_FIELD_SELECT_INPUT_TYPE_SET_VARIABLE = STEP_TYPE_SET_VARIABLE;
export const STEP_FIELD_SELECT_INPUT_TYPE_SET_CROSSWALK_INPUT = 'crosswalkInput';
export const STEP_FIELD_SELECT_INPUT_TYPE_SPLIT_FILE_RELATIVE_PATH = 'splitFileRelativePath';
export const STEP_FIELD_SELECT_INPUT_TYPE_SPLIT_FILE_DESTINATION_PATH = 'splitFileDestinationPath';
export const STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_RELATIVE_PATH = 'combineFileRelativePath';
export const STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_DESTINATION_PATH = 'combineFileDestinationPath';
export const STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_FILE_NAME = 'combineFileFileName';
export const STEP_FIELD_SELECT_INPUT_TYPE_ADVANCED_SET_FIELD = 'advancedField';
export const STEP_FIELD_SELECT_INPUT_TYPE_ADVANCED_SET_OPTION = 'advancedOption';
export const STEP_FIELD_SELECT_INPUT_TYPE_COMPARISON_INPUT = 'Comparison Inputs';
export const STEP_FIELD_SELECT_INPUT_TYPE_COMPARISON_FILE_DESTINATION = 'Comparison File Destination';
export const STEP_FIELD_SELECT_INPUT_TYPE_LOOP_INTELY_STORAGE_FILE_PATH = 'intelyStorageFilePath';
export const STEP_FIELD_SELECT_INPUT_TYPE_SET_EXECUTE_SCRIPT_PARAMETER = 'setExecuteScriptParameter';
export const STEP_FIELD_SELECT_INPUT_TYPE_CASE_CONDITIONAL = 'caseConditional';
export const STEP_FIELD_SELECT_INPUT_TYPE_CASE_CONDITIONAL_VALUE = 'caseConditionalValue';
export const STEP_FIELD_SELECT_INPUT_TYPE_EXPRESSION_GROUP_FIELD_LEFT = 'expressionGroupFieldLeft';
export const STEP_FIELD_SELECT_INPUT_TYPE_EXPRESSION_GROUP_FIELD_RIGHT = 'expressionGroupFieldRight';
export const STEP_FIELD_SELECT_INPUT_TYPE_LOOP_STEP_LOOP_ON = 'loopStepLoopOn';
export const STEP_FIELD_SELECT_INPUT_TYPE_RUN_INTEGRATION_TRIGGER_DATA = 'runIntegrationTriggerData';
export const STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_QUERY_PARAM = 'restfulRequestQueryParam';
export const STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_HEADER = 'restfulRequestStepHeaders';
export const STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_BODY = 'restfulRequestStepBody';

export const STEP_FIELD_SELECT_RETRIEVE_VALUE_TYPE_VALUE = 'value';
export const STEP_FIELD_SELECT_RETRIEVE_VALUE_TYPE_LENGTH = 'length';

/**
 * Prepare app data globally constants
 */
export const PREPARE_APP_DATA_GLOBALLY_AUTO_REFRESH_INTERVAL = 600000; // 1000 * 60 * 10

export const ROSETTA_DATA_TYPE_CATEGORY_DELIMITED = 'Delimited';

export const ROSETTA_DATA_TYPE_MAPPING_OUTPUT_DATA_TYPE_ID = '6495c4d1b1d9d8d77cffcc4c';
export const ROSETTA_DATA_TYPE_MAPPING_OUTPUT_DATA_FIELD_ID = '6495c839036c067145afc07c';

export const OCCURRENCE_MODAL_COLOR_SCHEMES = {
    occurrences: '#E0E0E0',
    filters: '#A581FB',
    crosswalks: '#2F80ED',
};

export const STEP_TYPES_WITH_INVALID_RETURNS = [
    STEP_TYPE_LOOP,
    STEP_TYPE_STOP_LOOP,
    STEP_TYPE_STOP_WORKFLOW,
    STEP_TYPE_CONTINUE_LOOP,
    STEP_TYPE_CONDITIONAL,
    STEP_TYPE_LOG,
    STEP_TYPE_DELAY,
    STEP_TYPE_SET_VARIABLE,
    STEP_TYPE_INCREMENT_DECREMENT_COUNTER,
    STEP_TYPE_RUN_INTEGRATION,
];

// Comparison CSV and JSoN datatypes IDs
export const COMPARISON_STEP_CSV_DATA_TYPE_ID = '646cc4de7e57130f268a1ac6';
export const COMPARISON_STEP_JSON_DATA_TYPE_ID = '646cc5b67e57130f268a4a52';
export const COMPARISON_STEP_OUTPUT_DATA_TYPE_ID = '64c15c858602fa3c92b85653';

// RESTful authorization data type
export const RESTFUL_AUTHORIZATION_STEP_OUTPUT_DATA_TYPE_ID = '65692cd4c8168c1d47722731';

// Crosswalk Response Output
export const CROSSWALK_STEP_OUTPUT_DATA_TYPE_ID = '6578ae5ab0ef57f01ccf777c';

// Reserved variable/counter names
export const RESERVED_VARIABLE_NAMES = [
    'integrationId',
    'executionId',
    'integrationExitStatus',
    'integrationLogs',
    'integrationErrors',
];
export const RESERVED_COUNTER_NAMES = [];

// Split/Combine File datatype IDs
export const SPLIT_FILE_STEP_OUTPUT_DATA_TYPE_ID = '650c8545d33ee9156e6c582c';
export const COMBINE_FILES_STEP_OUTPUT_DATA_TYPE_ID = '650de493d33ee9156e6d65f4';
// Split/Combine Folder/File unsupported characters
export const SPLIT_COMBINE_FOLDER_FILE_UNSUPPORTED_CHARACTERS = ['/', '\\', ':', '&', '"', '<', '>', '|'];

// TODO remove and replace with lib-common
export const DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY hh:mm:ss a';
export const DATE_FORMAT_WITH_TIME_NO_SECONDS = 'MM/DD/YYYY hh:mm a';
export const DATE_FORMAT = 'MM/DD/YYYY';

export const RESTFUL_REQUEST_STEP_METHODS = [
    { label: 'GET', value: 'GET' },
    { label: 'POST', value: 'POST' },
    { label: 'PUT', value: 'PUT' },
    { label: 'PATCH', value: 'PATCH' },
    { label: 'DELETE', value: 'DELETE' },
    // { label: 'COPY', value: 'COPY' },
    // { label: 'HEAD', value: 'HEAD' },
    // { label: 'OPTIONS', value: 'OPTIONS' },
];

export const TEXT_CONTENT_TYPES = [
    { label: 'Text', value: 'text/plain' },
    { label: 'HTML', value: 'text/html' },
    { label: 'JSON', value: 'application/json' },
    { label: 'XML', value: 'application/xml' },
    { label: 'FHIR', value: 'application/fhir+json' },
];

export const RESTFUL_REQUEST_STEP_RETURN_DATA_TYPES = [
    { label: 'JSON', value: 'json' },
    { label: 'CSV', value: 'csv' },
];

export const VARIABLE_DATATYPE_STRING = 'string';
export const VARIABLE_DATATYPE_NUMBER = 'number';
export const VARIABLE_DATATYPE_BOOLEAN = 'boolean';
export const VARIABLE_DATATYPE_DATE = 'date';
export const VARIABLE_DATATYPE_OBJECT = 'object';

export const VARIABLE_DATATYPES = [
    { label: 'String', value: VARIABLE_DATATYPE_STRING },
    { label: 'Number', value: VARIABLE_DATATYPE_NUMBER },
    { label: 'Boolean', value: VARIABLE_DATATYPE_BOOLEAN },
    { label: 'Date', value: VARIABLE_DATATYPE_DATE },
    { label: 'Object', value: VARIABLE_DATATYPE_OBJECT },
];

export const MODIFY_VARIABLE_TYPE_SET = 'set';
export const MODIFY_VARIABLE_TYPE_PUSH = 'push';
export const MODIFY_VARIABLE_TYPE_POP = 'pop';
export const MODIFY_VARIABLE_TYPE_CONCAT = 'concat';

export const MODIFY_VARIABLE_ACTION_TYPES = [
    { label: 'Set', value: MODIFY_VARIABLE_TYPE_SET },
    { label: 'Add Element', value: MODIFY_VARIABLE_TYPE_PUSH },
    { label: 'Remove Element', value: MODIFY_VARIABLE_TYPE_POP },
    { label: 'Concatenate', value: MODIFY_VARIABLE_TYPE_CONCAT },
];

export const APPLICATION_ID_CONNECT = '633bb8f323e1df14da1d58ae';

// Data Type ID Constants
export const DATA_TYPE_ID_OBJECT = '7123a45b67cd8e9f1abcd23b';
export const DATA_TYPE_ID_STRING = '7123a45b67cd8e9f1abcd21a';
export const DATA_TYPE_ID_INTELY_FILE_PATH = '7123a45b67cd8e9f1abcd24a';
