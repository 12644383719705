import { Fragment } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import md5 from 'crypto-js/md5';

import DifferenceIcon from '@mui/icons-material/Difference';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import HttpsIcon from '@mui/icons-material/Https';
import CrosswalkIcon from '@mui/icons-material/CompareArrows';
import DataObjectIcon from '@mui/icons-material/DataObject';

import * as constants from './constants';
import { isEmpty, isSameNumber } from './helpers';
import TriggerSchedulerIcon from '../assets/icons/TriggerSchedulerIcon';
import TriggerApiCallIcon from '../assets/icons/TriggerApiCallIcon';
import TriggerManualIcon from '../assets/icons/TriggerManualIcon';
import CodeIcon from '../assets/icons/StepFunction';
import ConditionIcon from '../assets/icons/StepConditional';
import LoopIcon from '../assets/icons/StepLoop';
import DescriptionIcon from '@mui/icons-material/Description';
import MappingIcon from '../assets/icons/MappingIcon';
import StepLogIcon from '../assets/icons/StepLog';
import StepStopWorkflowIcon from '../assets/icons/StepStopWorkflow';
import StepContinueLoopIcon from '../assets/icons/StepContinueLoop';
import StepStopLoopIcon from '../assets/icons/StepStopLoop';
import SetVariableIcon from '../assets/icons/SetVariableIcon';
import IncrementDecrementIcon from '@mui/icons-material/Iso';
import CogIcon from '../assets/icons/CogIcon';

import CallSplitIcon from '@mui/icons-material/CallSplit';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import {
    getIntegrationBuilderState,
    getIntegrationBuilderStates,
    setIntegrationBuilderState,
    setIntegrationBuilderStates,
} from '../hooks/useIntegrationBuilderHook';

import { getSchemas } from '../hooks/useSchemasHook';
import { getDataTypeByDataTypeId, getDataTypeCloneByDataTypeId } from '../hooks/useMyDataTypesHook';
import { getMappingsList } from '../hooks/useMappingsHook';
import { StringOutput, ToggleObjectData } from '../components/LogOutput';
import { isUrlValid } from './urlUtils';
import { showSnackBarErrorNotification, showSnackBarSuccessNotification } from './snackBarNotificationUtil';
import { isValidKeyValuePairList } from '../components/InputFields/KeyValuePairFieldSet';
import * as stringUtils from './stringUtils';
import * as envUtils from './envUtils';
import { getIntegrationById } from './integrationDataRequestUtils';

dayjs.extend(utc);

export const ERROR_CATEGORY_TRIGGER = 1;
export const ERROR_CATEGORY_APP_REQUEST = 2;
export const ERROR_CATEGORY_CONVERT = 3;
export const ERROR_CATEGORY_RESTFUL_REQUEST = 4;
export const ERROR_CATEGORY_CONDITIONAL = 5;
export const ERROR_CATEGORY_COMPARISON = 6;
export const ERROR_CATEGORY_LOOP = 7;
export const ERROR_CATEGORY_SPLIT_FILE = 8;
export const ERROR_CATEGORY_COMBINE_FILES = 9;
export const ERROR_CATEGORY_AUTHORIZATION = 10;
export const ERROR_CATEGORY_CROSSWALK = 11;
export const ERROR_CATEGORY_VARIABLE = 12;
export const ERROR_CATEGORY_STOP_WORKFLOW = 13;
export const ERROR_CATEGORY_LOG = 14;
export const ERROR_CATEGORY_INCREMENT_DECREMENT_COUNTER = 15;
export const ERROR_CATEGORY_RUN_INTEGRATION = 16;
export const ERROR_CATEGORY_EXECUTE_SCRIPT = 17;

/**
 * @typedef {Object} IntegrationVariable
 * @property {String} name
 * @property {String} dataType
 * @property {String} value
 * @property {Boolean} isPersistent
 * @property {Boolean} isArray
 */

/**
 * @typedef {Object} IntegrationCounter
 * @property {String} name
 * @property {Number} value
 * @property {Boolean} isPersistent
 */

/**
 *
 * @returns {{stepType: string, numberOfRetries: number, stepId: null, description: string, nextSteps: *[], continueIfFailed: boolean, prevSteps: number[], parameters: *[], autoRetry: boolean}}
 */
const getDefaultStepObject = () => {
    return {
        stepId: null,
        stepType: null,
        stepMode: constants.STEP_MODE_NORMAL,
        nextSteps: [],
        prevSteps: [],
        parameters: [],
        description: '',
        autoRetry: false,
        numberOfRetries: 0,
        continueIfFailed: false,
    };
};

/**
 * @typedef ValidationErrorObject
 * @property {number} categoryId
 * @property {string} errorMessage
 * @property {Object} step
 * @property {Object|null} topLevelLoopStepData
 * @property {Object|null} conditionalStepValidation
 */

/**
 * @param {int} categoryId
 * @param {string} errorMessage
 * @param {Object} step
 * @param {Object|null} topLevelLoopStepData
 * @param {Object|null} conditionalStepValidation
 * @param {[]} errorList
 */
const addValidationErrorObject = (
    categoryId,
    errorMessage,
    step,
    errorList,
    topLevelLoopStepData = null,
    conditionalStepValidation = null,
) => {
    errorList.push({ categoryId, errorMessage, step, topLevelLoopStepData, conditionalStepValidation });
};

/**
 *
 * @returns {{nextSteps: *[], stepType: string, stepId: number, freq: string, action: string, description: string, startTime: Date, interval: string, prevSteps: *[], monthlyInterval: string, triggerType: string}}
 */
const getDefaultTriggerObject = () => {
    return {
        // stepId: getRandomInt(),
        // stepId: getIntegrationBuilderState('lastGeneratedStepId',) + 1,
        stepId: 1,
        stepType: constants.STEP_TYPE_TRIGGER,
        prevSteps: [],
        nextSteps: [],
        startTime: null,
        freq: '',
        interval: '',
        action: '',
        description: '',
        monthlyInterval: null,
        triggerType: constants.TRIGGER_TYPE_MANUAL,
    };
};

/**
 *
 * @returns {[{nextSteps: [], stepType: string, stepId: number, freq: string, action: string, description: string, startTime: *, interval: string, prevSteps: [], monthlyInterval: string, triggerType: string}]}
 */
const getDefaultStepList = () => {
    return [getDefaultTriggerObject()];
};

/**
 * Get a list of translated step values for UI representation for a list of database steps.
 * @param {{}} data
 * @param {[{}]} data.steps Array of step objects directly from database load
 * @returns {{}}
 */
const getTranslatedStepDataFromIntegrationData = (data) => {
    if (data.steps && data.steps.length > 0) {
        // When steps are added between other existing steps,
        // steps need to be reprocessed to update the conditionalContainer step data
        const stepsToReprocess = [];
        const translatedStepData = _.cloneDeep(data.steps);

        const translateStepData = (steps, isFirstIteration) => {
            for (let index = 0; index < steps.length; index++) {
                let integrationStep = steps[index];

                integrationStep = updateIntegrationStepDataFromSchemaChanges(integrationStep);
                integrationStep['autoRetry'] = integrationStep.numberOfRetries > 0;

                if (integrationStep?.stepType === constants.STEP_TYPE_END_CONDITIONAL) {
                    const conditionalStep = (
                        integrationStep.containerStepId !== undefined ? steps : translatedStepData
                    ).find((step) => step?.conditionalEndStepId === integrationStep?.stepId);

                    if (conditionalStep?.isInConditional) {
                        integrationStep.conditionalContainerStepId = steps?.conditionalContainerStepId;
                        integrationStep.conditionalContainerEndStepId = conditionalStep?.conditionalContainerEndStepId;
                        integrationStep.isInConditional = true;
                    }

                    integrationStep.conditionalStepId = conditionalStep?.stepId;
                } else if (integrationStep?.prevSteps?.[0]) {
                    const prevStep = (integrationStep.containerStepId !== undefined ? steps : translatedStepData).find(
                        (step) => step?.stepId === integrationStep?.prevSteps?.[0],
                    );

                    if (isFirstIteration && prevStep?.stepId && prevStep?.stepId > integrationStep?.stepId) {
                        stepsToReprocess.push(integrationStep);
                        continue;
                    }

                    const isInConditional = !!(
                        prevStep?.stepType === constants.STEP_TYPE_CONDITIONAL || prevStep?.isInConditional
                    );

                    if (isInConditional) {
                        const endConditionalId =
                            prevStep?.stepType === constants.STEP_TYPE_CONDITIONAL
                                ? prevStep?.conditionalEndStepId
                                : prevStep?.conditionalContainerEndStepId;

                        integrationStep.conditionalContainerStepId = prevStep?.stepId;
                        integrationStep.conditionalContainerEndStepId = endConditionalId;
                        integrationStep.isInConditional = true;
                    } else {
                        integrationStep.isInConditional = false;
                    }
                }

                if (integrationStep.stepType === constants.STEP_TYPE_LOOP) {
                    translateStepData(integrationStep.containedSteps, isFirstIteration);
                    stepsToReprocess.push(...integrationStep.containedSteps);
                }
            }
        };

        translateStepData(translatedStepData, true);

        if (stepsToReprocess.length > 0) {
            translateStepData(stepsToReprocess, false);
        }

        return translatedStepData;
    } else {
        return getDefaultStepList();
    }
};

/**
 *
 * @param {[{}]} steps
 * @returns {[{}]}
 */
const getTranslatedStepListForSave = (steps) => {
    const stepsDataTemp = _.cloneDeep(steps),
        stepsLen = stepsDataTemp.length,
        isSingleStep = stepsLen === 1;

    for (let i = 0; i < stepsDataTemp.length; i++) {
        const item = stepsDataTemp[i];
        if (item.stepType === constants.STEP_TYPE_TRIGGER) {
            stepsDataTemp[i].action = '';
            delete stepsDataTemp[i].parameters;
            delete stepsDataTemp[i].prevSteps;
            delete stepsDataTemp[i].startTime;
            delete stepsDataTemp[i].inputMappings;
            delete stepsDataTemp[i].requestBodySourceInputPath;

            if (isSingleStep) {
                delete stepsDataTemp[i].nextSteps;
            }

            const isNotRecurringInterval = !isSameNumber(
                    item.interval,
                    constants.TRIGGER_SCHEDULER_FREQUENCY_RECURRING,
                ),
                isNotRecurringMonthlyFrequency =
                    isNotRecurringInterval || !isSameNumber(item.freq, constants.TRIGGER_SCHEDULER_INTERVAL_MONTHLY);

            if (isNotRecurringInterval) {
                delete stepsDataTemp[i].freq;
            }

            if (isNotRecurringMonthlyFrequency) {
                delete item.monthlyInterval;
            }

            break;
        }
    }

    return stepsDataTemp;
};

/**
 * Normalize app request step parameter
 * @param {object} parameter App request step parameter
 * @return {object}
 */
const normalizeAppRequestStepParameter = (parameter) => {
    if (parameter.name && (parameter.value || parameter?.sourceDisplayLabel)) {
        if (parameter.type && parameter.dataType) {
            parameter.type = parameter.dataType;
            delete parameter.dataType;
        }
    }
    return parameter;
};

/**
 *
 * @param {Number} frequencyToCheck
 * @returns {boolean}
 */
const isValidTriggerSchedulerFrequency = (frequencyToCheck) => {
    return (
        isSameNumber(frequencyToCheck, constants.TRIGGER_SCHEDULER_FREQUENCY_ONCE) ||
        isSameNumber(frequencyToCheck, constants.TRIGGER_SCHEDULER_FREQUENCY_RECURRING)
    );
};

/**
 *
 * @param {Number} intervalToCheck
 * @returns {boolean}
 */
const isValidTriggerSchedulerInterval = (intervalToCheck) => {
    return (
        isSameNumber(intervalToCheck, constants.TRIGGER_SCHEDULER_INTERVAL_HOURLY) ||
        isSameNumber(intervalToCheck, constants.TRIGGER_SCHEDULER_INTERVAL_DAILY) ||
        isSameNumber(intervalToCheck, constants.TRIGGER_SCHEDULER_INTERVAL_WEEKLY) ||
        isSameNumber(intervalToCheck, constants.TRIGGER_SCHEDULER_INTERVAL_MONTHLY) ||
        isSameNumber(intervalToCheck, constants.TRIGGER_SCHEDULER_INTERVAL_CUSTOM)
    );
};

/**
 *
 * @param {String} monthlyIntervalToCheck
 * @returns {boolean}
 */
const isValidTriggerSchedulerRecurringMonthlyFrequencyInterval = (monthlyIntervalToCheck) => {
    return (
        monthlyIntervalToCheck === constants.TRIGGER_SCHEDULER_MONTHLY_INTERVAL_FIRST ||
        monthlyIntervalToCheck === constants.TRIGGER_SCHEDULER_MONTHLY_INTERVAL_MIDDLE ||
        monthlyIntervalToCheck === constants.TRIGGER_SCHEDULER_MONTHLY_INTERVAL_LAST
    );
};

/**
 *
 * @param {{}} triggerProperties
 * @returns {boolean}
 */
const hasValidTriggerSchedulerIntervalAndFrequency = (triggerProperties) => {
    return (
        !isSameNumber(triggerProperties.freq, constants.TRIGGER_SCHEDULER_FREQUENCY_RECURRING) ||
        isValidTriggerSchedulerInterval(triggerProperties.interval)
    );
};

/**
 *
 * @param {{}} triggerProperties
 * @returns {boolean}
 */
const hasValidTriggerSchedulerIntervalAndMonthlyInterval = (triggerProperties) => {
    return (
        triggerProperties.interval !== constants.TRIGGER_SCHEDULER_INTERVAL_MONTHLY ||
        isValidTriggerSchedulerRecurringMonthlyFrequencyInterval(triggerProperties.monthlyInterval)
    );
};

/**
 *
 * @param {{}} triggerProperties
 * @returns {boolean}
 */
const hasValidTriggerSchedulerValues = (triggerProperties) => {
    return (
        triggerProperties.startTime &&
        isValidTriggerSchedulerFrequency(triggerProperties.freq) &&
        hasValidTriggerSchedulerIntervalAndFrequency(triggerProperties) &&
        hasValidTriggerSchedulerIntervalAndMonthlyInterval(triggerProperties)
    );
};

/**
 *
 * @param {[{}]} triggers
 * @returns {[{}]}
 */
const getTriggerList = () => {
    const style = { fontSize: '2.5rem' };
    return [
        {
            name: constants.TRIGGER_TYPE_MANUAL,
            label: constants.TRIGGER_TYPE_MANUAL,
            description: constants.TRIGGER_TYPE_MANUAL_DESCRIPTION,
            icon: <TriggerManualIcon style={style} />,
        },
        {
            name: constants.TRIGGER_TYPE_SCHEDULER,
            label: constants.TRIGGER_TYPE_SCHEDULER_LABEL,
            description: constants.TRIGGER_TYPE_SCHEDULER_DESCRIPTION,
            icon: <TriggerSchedulerIcon style={style} />,
        },
        {
            name: constants.TRIGGER_API_CALL,
            label: constants.TRIGGER_API_CALL_LABEL,
            description: constants.TRIGGER_API_CALL_DESCRIPTION,
            icon: <TriggerApiCallIcon style={style} />,
        },
    ];
};

/**
 *
 * @param label
 * @returns {*|string}
 */
const getTriggerValueForLabel = (label) => {
    switch (label) {
        case constants.TRIGGER_TYPE_SCHEDULER_LABEL:
            return constants.TRIGGER_TYPE_SCHEDULER;
        case constants.TRIGGER_API_CALL_LABEL:
            return constants.TRIGGER_API_CALL;
        default:
            return label;
    }
};

/**
 * Get step type icon
 * @param {String} stepType Step type icon to get
 * @return {ReactComponentElement|null}
 */
const getStepTypeIcon = (stepType) => {
    switch (stepType) {
        case constants.STEP_TYPE_APP_REQUEST:
            return DescriptionIcon;
        case constants.STEP_TYPE_NAME_LOOP:
            return LoopIcon;
        case constants.STEP_TYPE_CONDITIONAL:
        case constants.STEP_TYPE_END_CONDITIONAL:
            return ConditionIcon;
        case constants.STEP_TYPE_NAME_FUNCTION:
            return CodeIcon;
        case constants.STEP_TYPE_CONVERT:
            return MappingIcon;
        case constants.STEP_TYPE_RUN_INTEGRATION:
            return CogIcon;
        case constants.STEP_TYPE_LOG:
            return StepLogIcon;
        case constants.STEP_TYPE_STOP_WORKFLOW:
            return StepStopWorkflowIcon;
        case constants.STEP_TYPE_CONTINUE_LOOP:
            return StepContinueLoopIcon;
        case constants.STEP_TYPE_STOP_LOOP:
            return StepStopLoopIcon;
        case constants.STEP_TYPE_DELAY:
            return PendingActionsIcon;
        case constants.STEP_TYPE_SET_VARIABLE:
            return SetVariableIcon;
        case constants.STEP_TYPE_COMPARISON:
            return DifferenceIcon;
        case constants.STEP_TYPE_RESTFUL_AUTHORIZATION:
            return HttpsIcon;
        case constants.STEP_TYPE_INCREMENT_DECREMENT_COUNTER:
            return IncrementDecrementIcon;
        case constants.STEP_TYPE_SPLIT_FILE:
            return CallSplitIcon;
        case constants.STEP_TYPE_COMBINE_FILES:
            return MergeTypeIcon;
        case constants.STEP_TYPE_RESTFUL_REQUEST:
            return CallMadeIcon;
        case constants.STEP_TYPE_CROSSWALK:
            return CrosswalkIcon;
        case constants.STEP_TYPE_EXECUTE_SCRIPT:
            return DataObjectIcon;
        default:
            return null;
    }
};

/**
 * Get the step types
 * @param {Object} classes
 * @returns {[{}]}
 */
const getStepTypes = (classes = {}) => {
    const iconStyle = { marginTop: '6px' },
        loopIconStyle = { fill: '#000', marginTop: '6px', height: '20px', width: '20px' },
        convertIconStyle = { color: '#000', width: '30px', height: '30px', position: 'relative', top: '2px' },
        restfulIconStyle = { ...convertIconStyle, width: '22px', height: '22px' },
        iconStyleEndConditional = { ...iconStyle, transform: 'rotate(180deg)' };

    const AppRequestIcon = getStepTypeIcon(constants.STEP_TYPE_APP_REQUEST),
        ConvertStepIcon = getStepTypeIcon(constants.STEP_TYPE_CONVERT),
        LoopStepTypeIcon = getStepTypeIcon(constants.STEP_TYPE_NAME_LOOP),
        FunctionStepTypeIcon = getStepTypeIcon(constants.STEP_TYPE_NAME_FUNCTION),
        ConditionStepTypeIcon = getStepTypeIcon(constants.STEP_TYPE_CONDITIONAL),
        StopWorkflowIcon = getStepTypeIcon(constants.STEP_TYPE_STOP_WORKFLOW),
        LogIcon = getStepTypeIcon(constants.STEP_TYPE_LOG),
        ContinueLoopIcon = getStepTypeIcon(constants.STEP_TYPE_CONTINUE_LOOP),
        StepStopLoopIcon = getStepTypeIcon(constants.STEP_TYPE_STOP_LOOP),
        StepDelayIcon = getStepTypeIcon(constants.STEP_TYPE_DELAY),
        SetVariableStepIcon = getStepTypeIcon(constants.STEP_TYPE_SET_VARIABLE),
        ComparisonStepIcon = getStepTypeIcon(constants.STEP_TYPE_COMPARISON),
        IncrementDecrementStepIcon = getStepTypeIcon(constants.STEP_TYPE_INCREMENT_DECREMENT_COUNTER),
        IntegrationStepIcon = getStepTypeIcon(constants.STEP_TYPE_RUN_INTEGRATION),
        SplitFileStepIcon = getStepTypeIcon(constants.STEP_TYPE_SPLIT_FILE),
        CombineFilesStepIcon = getStepTypeIcon(constants.STEP_TYPE_COMBINE_FILES),
        HttpsIcon = getStepTypeIcon(constants.STEP_TYPE_RESTFUL_AUTHORIZATION),
        CrosswalkIcon = getStepTypeIcon(constants.STEP_TYPE_CROSSWALK),
        ExecuteScriptIcon = getStepTypeIcon(constants.STEP_TYPE_EXECUTE_SCRIPT);

    return [
        {
            id: constants.STEP_TYPE_APP_REQUEST,
            name: constants.STEP_TYPE_NAME_APP_REQUEST,
            isEnable: true,
            description: 'Send or Receive data from a Vendor',
            icon: <AppRequestIcon className={classes.avatarimage} style={iconStyle} />,
        },
        {
            id: constants.STEP_TYPE_CONVERT,
            name: constants.STEP_TYPE_NAME_CONVERT,
            isEnable: true,
            description: 'Convert data using a prebuilt mapping',
            icon: <ConvertStepIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_RESTFUL_REQUEST,
            name: constants.STEP_TYPE_NAME_RESTFUL_REQUEST,
            isEnable: true,
            description: 'Make a call to a restful endpoint without having to build an app',
            icon: <CallMadeIcon color="#000" style={restfulIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_CONDITIONAL,
            name: constants.STEP_TYPE_NAME_CONDITIONAL,
            description: 'Branch workflow based on conditions',
            isEnable: true,
            icon: <ConditionStepTypeIcon fill="#000" className={classes.avatarimage} style={loopIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_COMPARISON,
            name: constants.STEP_TYPE_NAME_COMPARISON,
            description: 'Execute comparison evaluations on multiple files using a prebuilt comparison',
            isEnable: true,
            icon: <ComparisonStepIcon fill="#000" style={iconStyle} />,
        },
        {
            id: constants.STEP_TYPE_END_CONDITIONAL,
            name: constants.STEP_TYPE_NAME_END_CONDITIONAL,
            description: 'End and converge all conditional branches workflow into single line of execution',
            isEnable: true,
            isVisible: false,
            icon: <ConditionStepTypeIcon fill="#000" className={classes.avatarimage} style={iconStyleEndConditional} />,
        },
        {
            id: constants.STEP_TYPE_NAME_LOOP,
            name: constants.STEP_TYPE_NAME_LOOP,
            description: 'Iterate over a series of steps',
            isEnable: true,
            icon: <LoopStepTypeIcon fill="#000" className={classes.avatarimage} style={loopIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_CONTINUE_LOOP,
            name: constants.STEP_TYPE_NAME_CONTINUE_LOOP,
            isEnable: true,
            description: 'Move to the next loop iteration',
            icon: <ContinueLoopIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_STOP_LOOP,
            name: constants.STEP_TYPE_NAME_STOP_LOOP,
            isEnable: true,
            description: 'End the current loop process',
            icon: <StepStopLoopIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_NAME_FUNCTION,
            name: constants.STEP_TYPE_NAME_FUNCTION,
            description: '',
            isHidden: true,
            icon: <FunctionStepTypeIcon className={classes.avatarimage} style={iconStyle} />,
        },
        {
            id: constants.STEP_TYPE_SET_VARIABLE,
            name: constants.STEP_TYPE_NAME_SET_VARIABLE,
            isEnable: true,
            description: 'Modify integration variables',
            icon: <SetVariableStepIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_INCREMENT_DECREMENT_COUNTER,
            name: constants.STEP_TYPE_NAME_INCREMENT_DECREMENT_COUNTER,
            isEnable: true,
            description: 'Increment or decrement a counter',
            icon: <IncrementDecrementStepIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_RUN_INTEGRATION,
            name: constants.STEP_TYPE_NAME_INTEGRATION,
            isEnable: true,
            description: 'Run a different integration from this workflow',
            icon: <IntegrationStepIcon fill="#000" style={iconStyle} />,
        },
        {
            id: constants.STEP_TYPE_SPLIT_FILE,
            name: constants.STEP_TYPE_NAME_SPLIT_FILE,
            isEnable: true,
            description: 'Split a file into multiple files',
            icon: <SplitFileStepIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_COMBINE_FILES,
            name: constants.STEP_TYPE_NAME_COMBINE_FILES,
            isEnable: true,
            description: 'Combine multiple files into a single file',
            icon: <CombineFilesStepIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_RESTFUL_AUTHORIZATION,
            name: constants.STEP_TYPE_NAME_RESTFUL_AUTHORIZATION,
            description: 'Get an authorization token from an outside source.',
            isEnable: true,
            icon: <HttpsIcon fill="#000" style={iconStyle} />,
        },
        {
            id: constants.STEP_TYPE_CROSSWALK,
            name: constants.STEP_TYPE_NAME_CROSSWALK,
            description: 'Run a crosswalk step on a given value.',
            isEnable: true,
            icon: <CrosswalkIcon fill="#000" style={iconStyle} />,
        },
        {
            id: constants.STEP_TYPE_EXECUTE_SCRIPT,
            name: constants.STEP_TYPE_NAME_EXECUTE_SCRIPT,
            description: 'Execute a stored script on passed data.',
            isEnable: true,
            icon: <ExecuteScriptIcon fill="#000" style={iconStyle} />,
        },
        {
            id: 'line',
            isEnable: true,
            isHorizontalLine: true,
        },
        {
            id: constants.STEP_TYPE_LOG,
            name: constants.STEP_TYPE_NAME_LOG,
            isEnable: true,
            description: 'Capture details about the current workflow',
            icon: <LogIcon fill="#000" style={iconStyle} />,
        },
        {
            id: constants.STEP_TYPE_STOP_WORKFLOW,
            name: constants.STEP_TYPE_NAME_STOP_WORKFLOW,
            isEnable: true,
            description: 'End the current workflow process',
            icon: <StopWorkflowIcon fill="#000" style={convertIconStyle} />,
        },
        {
            id: constants.STEP_TYPE_DELAY,
            name: constants.STEP_TYPE_NAME_DELAY,
            isEnable: true,
            description: 'Wait specified number of milliseconds when executing the step',
            icon: <StepDelayIcon fill="#000" style={convertIconStyle} />,
        },
    ];
};

/**
 * Get all enabled step types
 * @return {Array<string>}
 */
const getEnabledStepTypes = () => getStepTypes().filter((step) => step.isEnable);

/**
 * Check whether the integration is disabled
 * @param {number} status Integration status
 * @returns {boolean} True if the integration is disabled. Otherwise false.
 */
const isIntegrationDisabled = (status) => status === constants.INTEGRATION_STATUS_DISABLED;

/**
 * Check whether the integration is active
 * @param {number} status Integration status
 * @returns {boolean} True if the integration is active. Otherwise false.
 */
const isIntegrationActive = (status) => status === constants.INTEGRATION_STATUS_ACTIVE;

/**
 * Check whether the integration is pending
 * @param {number} status Integration status
 * @returns {boolean} True if the integration is pending. Otherwise false.
 */
const isIntegrationPending = (status) => status === constants.INTEGRATION_STATUS_PENDING;

/**
 * Update integration steps index after a step is added/removed
 * @param {Array<object>} steps Integration steps
 * @return {Array<object>} Normalize steps indexes
 */
const updateIntegrationStepIndexes = (steps) => {
    const stepsDataTemp = _.cloneDeep(steps);
    stepsDataTemp.forEach((step, i) => {
        if (step?.stepType !== constants.STEP_TYPE_TRIGGER) {
            // stepsDataTemp[i].prevSteps = i === 0 ? [] : [i - 1];
            // stepsDataTemp[i].nextSteps = [i + 1];

            const inputMappings = stepsDataTemp[i]?.inputMappings;
            if (inputMappings && inputMappings?.join) {
                for (let inputIndex = 0; inputIndex < inputMappings.length; inputIndex++) {
                    stepsDataTemp[i].inputMappings[inputIndex].stepSourceId = stepsDataTemp[i].stepId;
                }
            }
        }
    });
    return stepsDataTemp;
};

/**
 * Filter integration steps by stepId
 * @param {Array<object>} steps
 * @param {Number} stepId
 * @return {object}
 */
const filterIntegrationStepByStepId = (steps, stepId) => steps.find((step) => step?.stepId === stepId) || {};

/**
 * Get integration step by stepId
 * @param {Number} stepId
 * @return {object}
 */
const getIntegrationStepByStepId = (stepId) => {
    const { currentLocalIntegrationSteps } = getIntegrationBuilderStates();
    return filterIntegrationStepByStepId(currentLocalIntegrationSteps, stepId);
};

/**
 * Get selected integration step data
 * @param {{stepId: number, steps: [object], stepType: string, stepData: object, selectedStepMetaData: object, topLevelLoopStepData: object, currentLocalIntegrationSteps: [object]}} args
 * @return {{stepId: number, stepData: object, stepIndex: number, topLevelLoopStepData: object}|{}}
 */
const getSelectedIntegrationStepData = ({
    stepData,
    steps: _steps,
    stepId: _stepId,
    stepType: _stepType,
    selectedStepMetaData,
    topLevelLoopStepData,
    currentLocalIntegrationSteps,
}) => {
    const stepId = selectedStepMetaData?.stepId || stepData?.stepId || _stepId,
        stepType =
            selectedStepMetaData?.stepType ||
            selectedStepMetaData?.stepData?.stepType ||
            stepData?.stepType ||
            _stepType;

    const isLoopStepType = stepType === constants.STEP_TYPE_LOOP,
        isStepContainedInLoopStep = stepData?.containerStepId !== undefined,
        isConditionalStepType = stepType === constants.STEP_TYPE_CONDITIONAL;

    let steps = _steps;
    if (!steps) {
        steps = currentLocalIntegrationSteps || getIntegrationBuilderStates().currentLocalIntegrationSteps || [];
    }

    if (!isConditionalStepType && (topLevelLoopStepData || isLoopStepType || isStepContainedInLoopStep)) {
        getMutableLoopStep({
            topLevelLoopStepData,
            loopStepId: stepId,
            callback: ({ step, containedSteps }) => {
                if (step.containerStepId) {
                    steps = containedSteps || [];
                }
            },
        });
    }

    return {
        ...filterIntegrationStepMetadataByStepId(steps, stepId),
        topLevelLoopStepData,
    };
};

/**
 * Filter integration steps by stepId
 * @param {Array<object>|null} steps. Default to current integration steps if null.
 * @param {Number} stepId
 * @return {{stepId: number, stepData: object, stepIndex: number}|{}}
 */
const filterIntegrationStepMetadataByStepId = (steps, stepId) => {
    let _steps = steps;
    if (!steps) {
        _steps = getIntegrationBuilderStates().currentLocalIntegrationSteps || [];
    }

    for (let i = 0; i < _steps.length; i++) {
        const stepObj = _steps[i];
        if (stepObj?.stepId === stepId) {
            return {
                stepId,
                stepData: stepObj,
                stepIndex: i,
            };
        }
    }
    return {};
};

/**
 * Get trigger step data. Trigger step is always the first step in the integration step list.
 * @param {Array<object>} steps Default to integration step list.
 * @return {Object}
 */
const getTriggerStepData = (steps = null) => {
    if (steps) {
        return steps[0];
    }
    const { currentLocalIntegrationSteps } = getIntegrationBuilderStates();
    return currentLocalIntegrationSteps[0] || {};
};

/**
 * Traverse conditional step list recursively and create a structure to render the step list correctly.
 * @param {Object} args
 * @param {Array<object>} args.renderList
 * @param {Array<object>} args.steps
 * @param {Bool} args.isConditionalStep
 * @param {Number} args.parentConditionalStepId
 * @param {Function} args.traverseNextSteps
 * @returns {Array<object>}
 */
function renderConditionalSteps({ renderList, steps, newSteps, isConditionalStep, traverseNextSteps }) {
    // Conditional steps
    if (renderList?.length > 0) {
        // Get all conditional list items next steps
        const conditionalItemNextSteps = isConditionalStep
            ? renderList?.map((conditionalStep) => conditionalStep?.nextSteps?.[0]) || []
            : [];

        for (let conditionalIndex = 0; conditionalIndex < renderList.length; conditionalIndex++) {
            const conditionalStep = renderList[conditionalIndex];

            const nextSteps =
                    typeof conditionalStep === 'number' ? [conditionalStep] : conditionalStep?.nextSteps || [],
                nextStepId = nextSteps?.[0];

            // Ensure conditional step is parsed correctly and any duplicate rendering,
            // if any, is prevented
            if (isConditionalStep && conditionalItemNextSteps?.[conditionalIndex] !== nextStepId) {
                continue;
            }

            if (!nextStepId) {
                continue;
            }

            const nestedNextStepData = filterIntegrationStepByStepId(steps, nextStepId),
                nextNestedNextStepId = nestedNextStepData?.nextSteps?.[0],
                isNextStepConditional = nestedNextStepData?.stepType === constants.STEP_TYPE_CONDITIONAL;

            const nextRenderList = isNextStepConditional
                ? nestedNextStepData?.renderList || nestedNextStepData?.conditionList || []
                : nestedNextStepData?.renderList || [];

            const newRenderList = [
                {
                    stepData: nestedNextStepData,

                    nextSteps: nextNestedNextStepId ? [nextNestedNextStepId] : [],
                    renderList: nextRenderList,
                },
            ];

            if (!renderList[conditionalIndex]?.renderList) {
                renderList[conditionalIndex].renderList = [];
            }

            renderList[conditionalIndex].renderList.push(...newRenderList);
            // renderList[conditionalIndex].nextSteps = [nextNestedNextStepId];

            const mainRenderList =
                isNextStepConditional && nextRenderList?.length
                    ? nextRenderList
                    : renderList[conditionalIndex].renderList;

            let isStepDataValid = true;
            if (
                nestedNextStepData?.containerStepId &&
                nestedNextStepData?.containerStepId === nestedNextStepData?.stepId
            ) {
                isStepDataValid = false;
            }

            if (
                isStepDataValid &&
                (isNextStepConditional ||
                    (nextNestedNextStepId && nextNestedNextStepId !== nestedNextStepData.conditionalContainerEndStepId))
            ) {
                renderConditionalSteps({
                    steps,
                    newSteps,
                    traverseNextSteps,
                    stepData: nestedNextStepData,
                    renderList: mainRenderList,
                    nestedStepType: nestedNextStepData?.stepType || '',
                });

                // Continue rendering with next step after end conditional step
                const conditionalStepId = nestedNextStepData?.stepId,
                    endConditionalStep = getEndConditionalStep({
                        steps,
                        conditionalStep: nestedNextStepData,
                        conditionalStepId: conditionalStepId,
                    });

                if (
                    endConditionalStep &&
                    endConditionalStep?.nextSteps?.[0] !== endConditionalStep?.conditionalContainerEndStepId
                ) {
                    endConditionalStep.nextSteps?.[0] &&
                        traverseNextSteps({
                            steps,
                            nextSteps: endConditionalStep.nextSteps,
                            lastStepId: conditionalStepId,
                        });
                }
            }
        }
    }
}

/**
 * Get end conditional step from conditional step
 * @param {{conditionalStepId: number, steps: object[], conditionalStep: object }}
 * @return {Object|null}
 */
const getEndConditionalStep = ({ conditionalStepId, conditionalStep, steps }) => {
    const endConditionalStep = conditionalStep?.conditionalEndStepId || conditionalStepId + 1,
        step = steps?.find((item) => item?.stepId === endConditionalStep) || null;

    return step && step?.stepType === constants.STEP_TYPE_END_CONDITIONAL ? step : null;
};

/**
 * Get last generated step ID
 * @return {Number}
 */
const getLastGeneratedStepId = () => getIntegrationBuilderState('lastGeneratedStepId');

/**
 * Add end conditional step to steps list
 * @param {{steps: object[], conditionalStepId: number, containerStepId: number|null, stepProps: object, highestStepRef: object|null}}
 * @return {object|null}
 */
const addEndConditionalStepToStepList = ({
    conditionalStepId,
    steps,
    containerStepId,
    stepProps = {},
    highestStepIdRef = null,
}) => {
    if (conditionalStepId && steps?.push) {
        let stepId = getLastGeneratedStepId();

        if (highestStepIdRef && highestStepIdRef.current > stepId) {
            stepId = highestStepIdRef.current;
        }

        stepId += 1;

        const stepData = {
            ...getDefaultStepObject(),
            stepId,
            stepType: constants.STEP_TYPE_END_CONDITIONAL,
            prevSteps: [conditionalStepId],
            containerStepId: containerStepId || null,
            ...stepProps,
        };

        steps.push(stepData);

        setIntegrationBuilderState('lastGeneratedStepId', stepId);
        return stepData;
    }
    return null;
};

/**
 * Traverse integration builder steps
 * @param {Object} args
 * @param {Array<object>} args.steps
 * @param {Number} args.firstStepId
 * @returns {Array<object>}
 */
function traverseSteps({ steps: _steps, firstStepId = null }) {
    const newSteps = [],
        integrationSteps = _.cloneDeep(_steps);

    const traverseNextSteps = ({ nextSteps, lastStepId, steps }) => {
        if (!nextSteps?.length) return;

        const nextStepId = nextSteps?.[0];
        if (nextStepId === undefined || nextStepId === lastStepId) return;

        const step = steps.find((step) => step?.stepId === nextStepId);

        if (!step) return;

        const isConditionalStep = step?.stepType === constants.STEP_TYPE_CONDITIONAL,
            topLevelStepData = { step, nestedSteps: [] };

        if (isConditionalStep) {
            newSteps.push(topLevelStepData);
            renderConditionalSteps({
                steps,
                renderList: topLevelStepData.step?.conditionList || [],
                parentStepData: topLevelStepData,
                isConditionalStep,
                traverseNextSteps,
            });

            // Continue rendering with next step after end conditional step
            const endConditionalStep = getEndConditionalStep({
                steps,
                conditionalStep: step,
                conditionalStepId: nextStepId,
            });
            if (
                endConditionalStep &&
                endConditionalStep?.nextSteps?.length !== endConditionalStep?.conditionalContainerEndStepId
            ) {
                traverseNextSteps({ steps, nextSteps: endConditionalStep.nextSteps, lastStepId: nextStepId });
            }
        } else if (step?.stepType === constants.STEP_TYPE_END_CONDITIONAL) {
            return;
        } else {
            newSteps.push(topLevelStepData);
            step.nextSteps?.length && traverseNextSteps({ steps, nextSteps: step.nextSteps, lastStepId: nextStepId });
        }
    };

    let nextStepId = firstStepId;
    if (!nextStepId) {
        const triggerStep = getTriggerStepData(integrationSteps);
        nextStepId = triggerStep?.stepId;
    }

    traverseNextSteps({
        nextSteps: [nextStepId],
        steps: integrationSteps,
    });

    return newSteps;
}

/**
 * Check whether the comparison step data is valid
 * @param {Object} stepData
 * @param {Array} errorList
 * @returns {Boolean} True if comparison step data is valid. Otherwise false.
 */
const isComparisonStepTypeValid = (stepData, errorList) => {
    let errorCounter = 0;

    if (stepData?.stepMode === constants.STEP_MODE_ADVANCED) {
        // must have fields with a comparison ID
        if (Array.isArray(stepData?.advancedOptions?.fields)) {
            const foundComparisonId = stepData?.advancedOptions?.fields.find(
                (element) => element['key'] === 'comparisonId',
            );

            if (!foundComparisonId?.value?.rawData && !foundComparisonId?.value?.sourceStepId) {
                addValidationErrorObject(ERROR_CATEGORY_COMPARISON, 'Comparison is required', stepData, errorList);

                errorCounter++;
            }
        } else {
            addValidationErrorObject(
                ERROR_CATEGORY_COMPARISON,
                'Comparison requires fields to be set',
                stepData,
                errorList,
            );

            errorCounter++;
        }

        // must have a request body
        if (!stepData?.advancedOptions?.body?.rawData && !stepData?.advancedOptions?.body?.sourceStepId) {
            addValidationErrorObject(ERROR_CATEGORY_COMPARISON, 'Request body is required', stepData, errorList);

            errorCounter++;
        }
    } else {
        if (!stepData?.comparisonReturnType?.length) {
            addValidationErrorObject(ERROR_CATEGORY_COMPARISON, 'Return type is required', stepData, errorList);

            errorCounter++;
        }
        if (!stepData?.comparisonReturnRecords?.length) {
            addValidationErrorObject(ERROR_CATEGORY_COMPARISON, 'Return records type is required', stepData, errorList);

            errorCounter++;
        }
        if (stepData?.comparisonType === 'dynamic') {
            if (stepData?.comparisonSourceFields === undefined) {
                addValidationErrorObject(
                    ERROR_CATEGORY_COMPARISON,
                    'Source field(s) are required',
                    stepData,
                    errorList,
                );

                errorCounter++;
            }
        } else {
            if (!stepData?.comparisonId?.length) {
                addValidationErrorObject(ERROR_CATEGORY_COMPARISON, 'Comparison is required', stepData, errorList);

                errorCounter++;
            }

            if ((stepData?.comparisonInputs || []).length === 0) {
                addValidationErrorObject(
                    ERROR_CATEGORY_COMPARISON,
                    'Comparison inputs are required',
                    stepData,
                    errorList,
                );

                errorCounter++;
            }

            for (const comparisonInput of stepData?.comparisonInputs || []) {
                if (!comparisonInput?.inputId) return false;
                if (
                    getDataTypeByDataTypeId(
                        comparisonInput?.temporaryValidationDataTypeForInput,
                    )?.category?.toLowerCase() === 'delimited' &&
                    isEmpty(comparisonInput?.sourceInputDelimiter)
                ) {
                    addValidationErrorObject(ERROR_CATEGORY_COMPARISON, 'Delimiter is required', stepData, errorList);

                    errorCounter++;
                }

                const isStepDataInput =
                    comparisonInput?.sourceStepId !== undefined && comparisonInput?.sourceDataTypeId?.length > 0;

                let inputValue = '';

                if (isStepDataInput) {
                    inputValue = comparisonInput?.sourceDisplayLabel || comparisonInput?.sourceDataTypeId || '';
                } else {
                    inputValue = comparisonInput?.rawData || comparisonInput.intelyFilePath;
                }

                if (!inputValue?.length) {
                    addValidationErrorObject(
                        ERROR_CATEGORY_COMPARISON,
                        'Source field(s) are required',
                        stepData,
                        errorList,
                    );

                    errorCounter++;
                }
            }
        }
    }

    return errorCounter === 0;
};

/**
 * Check whether the step trigger data is valid
 * @param {Object} stepData
 * @param {Array} errorList
 * @returns {Boolean} True if step trigger data is valid. Otherwise false.
 */
const isStepTypeTriggerDataValid = (stepData, errorList) => {
    switch (stepData.triggerType) {
        case constants.TRIGGER_TYPE_SCHEDULER:
            if (hasValidTriggerSchedulerValues(stepData)) {
                return true;
            } else {
                addValidationErrorObject(
                    ERROR_CATEGORY_TRIGGER,
                    'Trigger has invalid scheduler values',
                    stepData,
                    errorList,
                );
            }
            break;
        case constants.TRIGGER_TYPE_MANUAL:
            return true;
        case constants.TRIGGER_API_CALL:
            if (stepData?.triggerDataTypeId?.length > 0) {
                return true;
            } else {
                addValidationErrorObject(ERROR_CATEGORY_TRIGGER, 'Trigger data type is required', stepData, errorList);
            }
            break;
        default:
            return false;
    }

    return false;
};

/**
 * Validate app request step
 * @param {{stepData: object, errorList: Array}}
 * @return {boolean}
 */
const isAppRequestStepTypeValid = ({ stepData, errorList }) => {
    const requestBodyValueType = stepData?.requestBodyValueType || '';
    let errorCount = 0;

    if (!stepData?.myAppApplicationId || !stepData?.myAppInterfaceId) {
        addValidationErrorObject(ERROR_CATEGORY_APP_REQUEST, 'App instance is required', stepData, errorList);

        errorCount++;
    }
    if (!stepData?.myAppResourceId) {
        addValidationErrorObject(ERROR_CATEGORY_APP_REQUEST, 'Resource is required', stepData, errorList);

        errorCount++;
    }
    if (!stepData.myAppActionId) {
        addValidationErrorObject(ERROR_CATEGORY_APP_REQUEST, 'Action is required', stepData, errorList);

        errorCount++;
    }

    if (!requestBodyValueType) {
        addValidationErrorObject(ERROR_CATEGORY_APP_REQUEST, 'Request body required', stepData, errorList);

        errorCount++;
    }

    switch (requestBodyValueType) {
        case constants.STEP_REQUEST_BODY_VALUE_TYPE_STEP_OUTPUT:
            if (!stepData?.requestBodySourceDisplayLabel) {
                addValidationErrorObject(
                    ERROR_CATEGORY_APP_REQUEST,
                    'Request body requires a value',
                    stepData,
                    errorList,
                );

                errorCount++;
            }
            break;
        case constants.STEP_REQUEST_BODY_VALUE_TYPE_TEXT:
            if (!stepData?.requestBodyRawText) {
                addValidationErrorObject(
                    ERROR_CATEGORY_APP_REQUEST,
                    'Request body requires a value',
                    stepData,
                    errorList,
                );

                errorCount++;
            }
            break;
        default:
            break;
    }

    if (!isStepParametersValid(stepData)) {
        addValidationErrorObject(ERROR_CATEGORY_APP_REQUEST, 'Valid parameters are required', stepData, errorList);

        errorCount++;
    }

    return errorCount === 0;
};

/**
 *
 * @param stepData
 * @param selectedStepMetadata
 * @param errorList
 * @returns {boolean}
 */
const isConvertStepValid = ({ stepData, selectedStepMetadata, errorList }) => {
    let errorCount = 0;

    if (!stepData?.mappingId) {
        addValidationErrorObject(ERROR_CATEGORY_CONVERT, 'Mapping is required', stepData, errorList);

        errorCount++;
    }

    errorCount += isMappingComplete(selectedStepMetadata, stepData, ERROR_CATEGORY_CONVERT, errorList);

    return errorCount === 0;
};

const isMappingComplete = (selectedStepMetadata, step, errorConstant, errorList) => {
    let errorCount = 0;

    const mappings = getMappingsList(),
        currentStepData = selectedStepMetadata?.stepData || {},
        selectedInputMappings = currentStepData?.inputMappings || [];
    const getSelectedMappingInput = (inputId) =>
        selectedInputMappings.find((input) => input?.inputId === inputId) || {};

    const selectedMapping = mappings.find((mapping) => mapping?._id === step?.mappingId),
        inputMappingsList = selectedMapping?.inputs || [];

    for (let i = 0; i < inputMappingsList.length; i++) {
        const input = inputMappingsList[i];

        if (input?.isRequired) {
            const inputMapping = selectedInputMappings?.[i] || {},
                inputData = getSelectedMappingInput(input?._id);

            if (
                !(inputMapping?.inputId || inputData?.inputId) ||
                !(inputMapping?.sourceStepId || inputData?.sourceStepId) ||
                !(inputMapping?.sourceDataTypeId || inputData?.sourceDataTypeId)
            ) {
                errorCount++;
                addValidationErrorObject(errorConstant, 'Required input is empty', step, errorList);
            }
        }
    }

    return errorCount;
};

/**
 * Check whether the restful request step parameters are valid
 * @param {Array<object>} paramsData
 * @returns {boolean}
 */
const isRestfulRequestStepParamsValid = (paramsData) => {
    const bodyFieldsLen = paramsData?.length || 0,
        lastBodyFieldIndex = bodyFieldsLen - 1;

    for (let i = 0; i < bodyFieldsLen; i++) {
        const item = paramsData[i],
            key = item?.name,
            value = item?.value || item?.sourceDisplayLabel;

        if (i === lastBodyFieldIndex) {
            if (!key && value) return false;
            if (key && !value) return false;
        } else {
            if (!key || !value) return false;
        }
    }
    return true;
};

/**
 * Check whether the given content type supports raw input value
 * @param {string} contentType
 * @returns {boolean}
 */
const isContentTypeRawInputValue = (contentType) => {
    const textFieldInputs = ['text/plain', 'text/html', 'application/json', 'application/xml', 'application/fhir+json'];
    return textFieldInputs.includes(contentType);
};

/**
 * Validate app request step
 * @param {{stepData: object, selectedStepMetadata: object }}
 * @return {boolean}
 */
const isRestfulRequestStepTypeValid = ({ stepData, errorList }) => {
    let errorCount = 0;

    if (!stepData?.restfulRequestStepMethod) {
        addValidationErrorObject(ERROR_CATEGORY_RESTFUL_REQUEST, 'Method is required', stepData, errorList);

        errorCount++;
    }

    if (!stepData?.restfulRequestStepUrl || !isUrlValid(stepData.restfulRequestStepUrl)) {
        addValidationErrorObject(ERROR_CATEGORY_RESTFUL_REQUEST, 'Valid URL is required', stepData, errorList);

        errorCount++;
    }

    if (stepData?.restfulRequestStepUrl?.indexOf('https://') !== 0) {
        addValidationErrorObject(ERROR_CATEGORY_RESTFUL_REQUEST, 'URL must be HTTPS connection', stepData, errorList);

        errorCount++;
    }

    if (!stepData?.restfulRequestStepReturnDataType) {
        addValidationErrorObject(ERROR_CATEGORY_RESTFUL_REQUEST, 'Return Data Type is required', stepData, errorList);

        errorCount++;
    }

    if (stepData?.restfulRequestStepBodyContentType) {
        if (
            isContentTypeRawInputValue(stepData.restfulRequestStepBodyContentType) &&
            !stepData?.restfulRequestStepBodyRawValue?.trim()
        ) {
            addValidationErrorObject(ERROR_CATEGORY_RESTFUL_REQUEST, 'Content body is required', stepData, errorList);

            errorCount++;
        }

        if (!isRestfulRequestStepParamsValid(stepData?.restfulRequestStepBody)) {
            addValidationErrorObject(
                ERROR_CATEGORY_RESTFUL_REQUEST,
                'A valid parameter list is required',
                stepData,
                errorList,
            );

            errorCount++;
        }
    }

    if (!isRestfulRequestStepParamsValid(stepData?.restfulRequestStepHeaders)) {
        addValidationErrorObject(
            ERROR_CATEGORY_RESTFUL_REQUEST,
            'Header section requires valid key-value pairs',
            stepData,
            errorList,
        );

        errorCount++;
    }
    if (!isRestfulRequestStepParamsValid(stepData?.restfulRequestStepQueryParams)) {
        addValidationErrorObject(
            ERROR_CATEGORY_RESTFUL_REQUEST,
            'Query parameter section requires valid key-value pairs',
            stepData,
            errorList,
        );

        errorCount++;
    }

    return errorCount === 0;
};

/**
 * Validates crosswalk step type
 * @param stepData
 * @param errorList
 * @returns {boolean}
 */
const isCrosswalkStepTypeValid = ({ stepData, errorList }) => {
    const crosswalkData = stepData?.crosswalkData || {};
    let hasValidFields = true;

    if (Array.isArray(crosswalkData?.valuesToCrosswalk) && crosswalkData.valuesToCrosswalk.length > 0) {
        for (const data of crosswalkData.valuesToCrosswalk) {
            if (!data?.rawData && !data?.sourceDataTypeId) {
                hasValidFields = false;
            }
        }
    } else {
        hasValidFields = false;
    }

    if (!crosswalkData?.crosswalkId) {
        addValidationErrorObject(ERROR_CATEGORY_CROSSWALK, 'A crosswalk is required', stepData, errorList);
    }

    if (!hasValidFields) {
        addValidationErrorObject(ERROR_CATEGORY_CROSSWALK, 'Crosswalk has invalid field(s)', stepData, errorList);
    }

    return !!(crosswalkData?.crosswalkId && hasValidFields);
};

/**
 * @param {IntegrationStep} stepData
 * @param {Array} errorList
 * @returns {boolean}
 */
const isExecuteScriptStepTypeValid = ({ stepData, errorList }) => {
    let hasValidFields = true;

    if (!stepData?.executeScriptId) {
        hasValidFields = false;
        addValidationErrorObject(ERROR_CATEGORY_EXECUTE_SCRIPT, 'Script to execute is required', stepData, errorList);
    }

    if (!stepData?.executeScriptParameters || !isValidExecuteScriptParameterSet(stepData.executeScriptParameters)) {
        hasValidFields = false;
        addValidationErrorObject(
            ERROR_CATEGORY_EXECUTE_SCRIPT,
            'Complete parameters are required',
            stepData,
            errorList,
        );
    }

    return hasValidFields;
};

/**
 * @param {IntegrationExecuteScriptStepParameter[]} scriptParameters
 * @returns {boolean}
 */
const isValidExecuteScriptParameterSet = (scriptParameters) => {
    if (Array.isArray(scriptParameters)) {
        let validParameters = 0;
        const totalParameters = scriptParameters.length;

        for (const parameterData of scriptParameters) {
            if (parameterData.parameterId && (parameterData.rawData || parameterData.sourceDataTypeId)) {
                validParameters++;
            }
        }

        return validParameters === totalParameters;
    }

    return false;
};

/**
 * Verify step parameter data
 * @param {Object} stepData
 * @return {Boolean} True if step parameter data is valid. Otherwise false.
 */
const isStepParametersValid = (stepData) => {
    if (stepData?.parameters?.length > 0) {
        let numberOfValidParameters = 0;
        for (const parameter of stepData.parameters) {
            if (
                (parameter.name && (parameter.value || parameter?.sourceDisplayLabel)) ||
                (!parameter.name && !parameter.value && !parameter?.sourceDisplayLabel)
            ) {
                numberOfValidParameters++;
            } else {
                break;
            }
        }

        return numberOfValidParameters === stepData.parameters.length;
    }
    return true;
};

/**
 * Handle set step validation error state
 */
const handleSetStepValidationErrorState = ({ step, conditionalStepValidation }) => {
    const stepElem = step && document && document.querySelector(`.renderStepTypeItem-${step.stepId}`),
        states = {
            hasStepValidationError: false,
            isSubmittingIntegrationBuilderForm: true,
            conditionalStepValidation: conditionalStepValidation || {},
        };

    if (stepElem) {
        stepElem.click();
        stepElem.scrollIntoView();

        // Scroll to the field error text in step drawer
        // setTimeout(() => {
        //     document.querySelector('.integrationBuilderStepFieldsScrollBar .fieldErrorText')?.scrollIntoView();
        // }, 100);

        states.hasStepValidationError = step.stepId;
    }

    setIntegrationBuilderStates(states);
};

/**
 * Clear conditional step condition fields error state
 * @param {{ conditionIndex: number, stepId: number }}
 * @returns {object}
 */
const clearConditionalIndexValidationError =
    ({ stepId, conditionIndex }) =>
    () => {
        const conditionalIndexErrorId = `${stepId}-${conditionIndex}`,
            conditionalStepValidation = getIntegrationBuilderState('conditionalStepValidation', {}),
            newConditionalStepValidation = { ...conditionalStepValidation };

        delete newConditionalStepValidation[conditionalIndexErrorId];
        return newConditionalStepValidation;
    };

/**
 * Validates if variable field data is valid
 * @param {Object} variableFieldData
 * @returns {boolean}
 */
const isValidVariableFieldData = (variableFieldData) => {
    const isVariableReference = !isNaN(parseInt(variableFieldData?.sourceStepId));

    if (isVariableReference) {
        return (
            !!variableFieldData?.sourceDataTypeId &&
            Array.isArray(variableFieldData.sourceInputPath) &&
            variableFieldData.sourceInputPath.length > 0
        );
    } else {
        return !!variableFieldData?.value?.length;
    }
};

/**
 * @typedef IntegrationErrorObject
 * @property {number} category
 * @property {string} errorMessage
 * @property {Object} step
 */

/**
 * @typedef IntegrationErrorTraverseValidationReturn
 * @property {boolean} isValid
 * @property {null|Object} topLevelLoopStepData
 */

/**
 * Traverse integration builder steps for validation
 * @param {Object} args
 * @param {Array<object>} args.steps
 * @param {Number} args.firstStepId
 * @param {Number} args.invalidStepValidationCounterRef
 * @param {IntegrationErrorObject[]} errorList
 * @returns {Promise<IntegrationErrorTraverseValidationReturn>} True if steps are valid. False otherwise.
 */
const traverseStepsForValidation = async (
    { steps, firstStepId = null, invalidStepValidationCounterRef },
    errorList = [],
) => {
    let step = {},
        topLevelLoopStepData = null;

    const selectedStepMetadata = getIntegrationBuilderState('selectedStepMetadata', {});

    const walkConditionalExpressions = ({
        expressions,
        conditionalIndex,
        expressionIndexes,
        isIfConditionalType,
        isCaseConditionalType,
        targetConditionalStep,
    }) => {
        expressions?.map((expression, expressionIndex) => {
            const groupExpressionList = expression.expressionList || [],
                hasNestedExpression = groupExpressionList?.length > 0,
                isGroupingExpression = expression.expressionType === 'grouping',
                // isExpressionGroup = isGroupingExpression,
                newExpressionIndexes = expressionIndexes.concat(expressionIndex),
                conditionalStepId = targetConditionalStep?.stepId,
                isRightOperandRequired =
                    expression?.comparisonOperator !== constants.INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EMPTY &&
                    expression?.comparisonOperator !==
                        constants.INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EMPTY &&
                    expression?.comparisonOperator !==
                        constants.INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_EXISTS &&
                    expression?.comparisonOperator !==
                        constants.INTEGRATION_STEP_CONDITION_COMPARISON_OPERATOR_NOT_EXISTS;

            if (isIfConditionalType) {
                let isExpressionValid = true;

                if (!isGroupingExpression) {
                    if (
                        !(expression?.leftOperandValue || expression?.leftOperandSourceDataTypeDisplayLabel) ||
                        !expression?.comparisonOperator ||
                        (isRightOperandRequired &&
                            !(expression?.rightOperandValue || expression?.rightOperandSourceDataTypeDisplayLabel))
                    ) {
                        isExpressionValid = false;
                    }
                }

                // if ((hasNestedExpression || expressionIndex > 0) && !expression?.expressionOperator) {
                if (expressionIndex > 0 && !expression?.expressionOperator) {
                    isExpressionValid = false;
                }

                if (!isExpressionValid) {
                    ++invalidStepValidationCounterRef.current;

                    addValidationErrorObject(
                        ERROR_CATEGORY_CONDITIONAL,
                        'Condition expression is invalid.',
                        targetConditionalStep,
                        errorList,
                        topLevelLoopStepData,
                        {
                            [`${conditionalStepId}-${conditionalIndex}`]: {},
                        },
                    );
                }
            } else if (isCaseConditionalType) {
                if (
                    !(expression?.rightOperandValue || expression?.rightOperandSourceDataTypeDisplayLabel) ||
                    !targetConditionalStep?.conditionSourceDataTypeDisplayLabelForComparison
                ) {
                    ++invalidStepValidationCounterRef.current;
                    addValidationErrorObject(
                        ERROR_CATEGORY_CONDITIONAL,
                        'At least one step is required for the conditional',
                        targetConditionalStep,
                        errorList,
                        topLevelLoopStepData,
                        {
                            [`${conditionalStepId}-${conditionalIndex}`]: {},
                        },
                    );
                }
            }

            if (hasNestedExpression) {
                walkConditionalExpressions({
                    conditionalIndex,
                    isIfConditionalType,
                    isCaseConditionalType,
                    targetConditionalStep,
                    expressions: groupExpressionList,
                    expressionIndexes: newExpressionIndexes,
                });
            }
        });
    };

    /**
     * @param {Object} variableFieldData
     * @returns {boolean}
     */
    const isValidVariableFieldData = (variableFieldData) => {
        const isVariableReference = !isNaN(parseInt(variableFieldData.sourceStepId));

        if (isVariableReference) {
            return (
                variableFieldData?.sourceDataTypeId?.length && Array.isArray(variableFieldData.sourceInputPath)
                // && variableFieldData.sourceInputPath.length > 0
            );
        } else {
            return !!variableFieldData?.value?.length;
        }
    };

    const validateModifyVariableFieldsBasedOnActionType = ({ actionType, ...variableFieldData }, step, errorList) => {
        let errorCount = 0;
        switch (actionType) {
            case constants.MODIFY_VARIABLE_TYPE_SET:
                if (isValidVariableFieldData(variableFieldData)) {
                    return true;
                } else {
                    addValidationErrorObject(
                        ERROR_CATEGORY_VARIABLE,
                        'Valid variable field data is required',
                        step,
                        errorList,
                        topLevelLoopStepData,
                    );

                    errorCount++;
                }
                break;
            case constants.MODIFY_VARIABLE_TYPE_PUSH:
                if (!isValidVariableFieldData(variableFieldData)) {
                    addValidationErrorObject(
                        ERROR_CATEGORY_VARIABLE,
                        'Valid variable field data is required',
                        step,
                        errorList,
                        topLevelLoopStepData,
                    );

                    errorCount++;
                }

                if (!variableFieldData.direction) {
                    addValidationErrorObject(
                        ERROR_CATEGORY_VARIABLE,
                        'Direction is required for this variable action',
                        step,
                        errorList,
                        topLevelLoopStepData,
                    );

                    errorCount++;
                }
                break;
            case constants.MODIFY_VARIABLE_TYPE_POP:
                if (!variableFieldData.direction) {
                    addValidationErrorObject(
                        ERROR_CATEGORY_VARIABLE,
                        'Direction is required for this variable action',
                        step,
                        errorList,
                        topLevelLoopStepData,
                    );

                    errorCount++;
                }
                break;
            case constants.MODIFY_VARIABLE_TYPE_CONCAT:
                if (!variableFieldData.direction) {
                    addValidationErrorObject(
                        ERROR_CATEGORY_VARIABLE,
                        'Direction is required for this variable action',
                        step,
                        errorList,
                        topLevelLoopStepData,
                    );

                    errorCount++;
                }

                if (
                    !Array.isArray(variableFieldData?.concatSourceVariables) ||
                    variableFieldData.concatSourceVariables.length === 0
                ) {
                    addValidationErrorObject(
                        ERROR_CATEGORY_VARIABLE,
                        'At least one value is required for concatenating variable',
                        step,
                        errorList,
                        topLevelLoopStepData,
                    );

                    errorCount++;
                }
                break;
            default:
                return false;
        }

        return errorCount === 0;
    };

    /**
     * @param nextSteps
     * @param previousStepId
     * @param errorList
     * @returns {Promise<{sourceDataTypeDisplayLabelForLoop}|boolean>}
     */
    const traverseNextSteps = async ({ nextSteps, previousStepId, errorList }) => {
        if (nextSteps) {
            const nextStepId = nextSteps?.[0];
            if (nextStepId === previousStepId) return;

            step = steps.find((step) => step?.stepId === nextStepId);

            if (step) {
                const isConditionalStep = step?.stepType === constants.STEP_TYPE_CONDITIONAL,
                    isLoopStep = step?.stepType === constants.STEP_TYPE_LOOP;

                if (isConditionalStep) {
                    const conditionalList = step?.conditionList || [];

                    // Error if conditional step list is empty
                    if (!conditionalList?.length || !step?.conditionalType) {
                        ++invalidStepValidationCounterRef.current;
                        addValidationErrorObject(
                            ERROR_CATEGORY_CONDITIONAL,
                            'Conditional requires at least one step',
                            step,
                            errorList,
                            topLevelLoopStepData,
                        );
                    }

                    const targetConditionalStep = step,
                        conditionalType = step?.conditionalType || '',
                        isCaseConditionalType = conditionalType === constants.CONDITIONAL_TYPE_CASE,
                        isIfConditionalType = conditionalType === constants.CONDITIONAL_TYPE_IF;

                    let conditionalIndex = 0;
                    for (const conditionalStep of conditionalList) {
                        // Error if no next step is set
                        if (!conditionalStep?.nextSteps?.[0] || !conditionalStep?.name) {
                            ++invalidStepValidationCounterRef.current;
                            addValidationErrorObject(
                                ERROR_CATEGORY_CONDITIONAL,
                                'Next step is required',
                                targetConditionalStep,
                                errorList,
                                topLevelLoopStepData,
                            );
                        }

                        // Validate conditional expressions
                        walkConditionalExpressions({
                            conditionalIndex,
                            isIfConditionalType,
                            isCaseConditionalType,
                            targetConditionalStep,
                            expressions: conditionalStep?.expressionList || [],
                            expressionIndexes: [],
                        });

                        await traverseNextSteps({ nextSteps: conditionalStep.nextSteps, errorList });

                        ++conditionalIndex;
                    }
                } else if (isLoopStep) {
                    const containedSteps = step?.containedSteps,
                        containedStepsFirstStep = containedSteps?.[0];

                    if (!topLevelLoopStepData) {
                        topLevelLoopStepData = step;
                    }

                    const errors = [];

                    // make sure we have at least one contained step
                    if (containedSteps?.length < 1) {
                        addValidationErrorObject(
                            ERROR_CATEGORY_LOOP,
                            'A loop step must have at least one contained step',
                            step,
                            errorList,
                            topLevelLoopStepData,
                        );
                        errors.push(`A loop step must have at least one contained step. [${step?.stepId}]`);
                    }

                    // error handling mode required
                    if (!step.onLoopError) {
                        addValidationErrorObject(
                            ERROR_CATEGORY_LOOP,
                            'A loop step must have an on error handling method defined',
                            step,
                            errorList,
                            topLevelLoopStepData,
                        );
                        errors.push(`A loop step must have an on error handling method defined. [${step?.stepId}]`);
                    }

                    if (step?.runIterationsInParallel === true) {
                        if (step?.onLoopError === 'break') {
                            addValidationErrorObject(
                                ERROR_CATEGORY_LOOP,
                                'A loop step which runs in parallel may not be set to "break" on error',
                                step,
                                errorList,
                                topLevelLoopStepData,
                            );
                            errors.push(
                                `A loop step which runs in parallel may not be set to "break" on error. [${step?.stepId}]`,
                            );
                        }

                        if (!isListOfContainedStepsValidForParallelLoopStep(containedSteps)) {
                            addValidationErrorObject(
                                ERROR_CATEGORY_LOOP,
                                'Loop step contains one or more steps which are not compatible with running in parallel',
                                step,
                                errorList,
                                topLevelLoopStepData,
                            );
                            errors.push(
                                `Loop step contains one or more steps which are not compatible with running in parallel. [${step?.stepId}]`,
                            );
                        }
                    }

                    if (!step?.loopType) {
                        addValidationErrorObject(ERROR_CATEGORY_LOOP, 'A loop type is required', step, errorList);
                        errors.push(`A loop type is required. [${step?.stepId}]`);
                    } else if (
                        step?.loopType === constants.STEP_LOOP_TYPE_WHILE ||
                        step?.loopType === constants.STEP_LOOP_TYPE_DO_WHILE
                    ) {
                        if (!step?.loopWhileExpressions || step?.loopWhileExpressions?.length < 1) {
                            addValidationErrorObject(
                                ERROR_CATEGORY_LOOP,
                                'While loops require at least one expression',
                                step,
                                errorList,
                                topLevelLoopStepData,
                            );
                            errors.push(`While loops require at least one expression. [${step?.stepId}]`);
                        }
                    } else {
                        if (step.loopInputType === constants.STEP_LOOP_INPUT_TYPE_MULTI_RECORD_FILE) {
                            // multi-record loops need to have a file path string or a source step to pull the path from
                            if (
                                !step?.loopInputIntelyFilePath &&
                                !(step?.loopInputIntelyFilePathSourceStepId && step?.loopInputIntelyFilePathDataTypeId)
                            ) {
                                addValidationErrorObject(
                                    ERROR_CATEGORY_LOOP,
                                    'For each loop on multi-record files must have a direct storage file path or ' +
                                        'the source step and data type to pull that path from',
                                    step,
                                    errorList,
                                    topLevelLoopStepData,
                                );
                                errors.push(
                                    `For each loop on multi-record files must have a direct storage file path or ` +
                                        `the source step and data type to pull that path from. [${step?.stepId}]`,
                                );
                            }

                            // multi-record loops must have a record data type
                            if (!step?.loopInputRecordDataTypeId) {
                                addValidationErrorObject(
                                    ERROR_CATEGORY_LOOP,
                                    'For each loop on multi-record files must have a file path and data type selected',
                                    step,
                                    errorList,
                                    topLevelLoopStepData,
                                );
                                errors.push(
                                    `For each loop on multi-record files must have a file path and data type selected. [${step?.stepId}]`,
                                );
                            }
                        } else {
                            // any non-multi record file loop needs to have a source step label to pull from
                            if (!step?.sourceDataTypeDisplayLabelForLoop) {
                                addValidationErrorObject(
                                    ERROR_CATEGORY_LOOP,
                                    'For each loop on previous step value must have a value chosen',
                                    step,
                                    errorList,
                                    topLevelLoopStepData,
                                );
                                errors.push(
                                    `For each loop on previous step value must have a value chosen. [${step?.stepId}]`,
                                );
                            }
                        }
                    }

                    if (errors.length > 0) {
                        ++invalidStepValidationCounterRef.current;

                        envUtils.logError(errors);
                    }

                    const validate = await traverseStepsForValidation(
                        {
                            steps: containedSteps,
                            firstStepId: containedStepsFirstStep?.stepId,
                            invalidStepValidationCounterRef,
                        },
                        errorList,
                    );

                    // Also call the next step after loop
                    if (!(await traverseNextSteps({ nextSteps: step.nextSteps, errorList }))) {
                        return step;
                    }

                    return validate?.isValid;
                } else {
                    // Validate the step before moving to next step
                    switch (step?.stepType) {
                        case constants.STEP_TYPE_CONVERT:
                            if (!isConvertStepValid({ stepData: step, selectedStepMetadata, errorList })) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_RESTFUL_REQUEST:
                            if (!isRestfulRequestStepTypeValid({ stepData: step, errorList })) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_RESTFUL_AUTHORIZATION:
                            if (!isRestfulAuthorizationStepTypeValid({ stepData: step, errorList })) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_CROSSWALK:
                            if (!isCrosswalkStepTypeValid({ stepData: step, errorList })) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_EXECUTE_SCRIPT:
                            if (!isExecuteScriptStepTypeValid({ stepData: step, errorList })) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_APP_REQUEST:
                            if (!isAppRequestStepTypeValid({ stepData: step, errorList })) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_TRIGGER:
                            if (!isStepTypeTriggerDataValid(step, errorList)) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_STOP_WORKFLOW:
                            if (!step?.exitResultType) {
                                ++invalidStepValidationCounterRef.current;
                                addValidationErrorObject(
                                    ERROR_CATEGORY_STOP_WORKFLOW,
                                    'Exit result type is required',
                                    step,
                                    errorList,
                                );
                            }
                            break;

                        case constants.STEP_TYPE_LOG:
                            if (!step?.logType) {
                                ++invalidStepValidationCounterRef.current;
                                addValidationErrorObject(ERROR_CATEGORY_LOG, 'Log type is required', step, errorList);
                            }
                            break;

                        case constants.STEP_TYPE_COMPARISON:
                            if (!isComparisonStepTypeValid(step, errorList)) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;

                        case constants.STEP_TYPE_INCREMENT_DECREMENT_COUNTER: {
                            const counters = step?.counters || [];
                            let hasError = false;

                            for (const counter of counters) {
                                if (isNaN(parseInt(counter.value))) {
                                    addValidationErrorObject(
                                        ERROR_CATEGORY_INCREMENT_DECREMENT_COUNTER,
                                        'Counter value is required',
                                        step,
                                        errorList,
                                    );
                                    hasError = true;
                                } else if (!counter.variation.length) {
                                    addValidationErrorObject(
                                        ERROR_CATEGORY_INCREMENT_DECREMENT_COUNTER,
                                        'Counter variation is required',
                                        step,
                                        errorList,
                                    );
                                    hasError = true;
                                }
                            }

                            if (hasError) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;
                        }

                        case constants.STEP_TYPE_SPLIT_FILE: {
                            const {
                                relativeFilePath,
                                typeOfFileSplit,
                                byteSizePerFile,
                                delimiter,
                                recordCountPerFile,
                            } = step;

                            const hasSourceStepId = relativeFilePath?.sourceStepId,
                                hasSourceDataTypeId = relativeFilePath?.sourceDataTypeId,
                                hasInputPath = Array.isArray(relativeFilePath?.sourceInputPath);
                            let hasError = false;

                            if (hasSourceStepId) {
                                if (!hasSourceDataTypeId || !hasInputPath) {
                                    addValidationErrorObject(
                                        ERROR_CATEGORY_SPLIT_FILE,
                                        'Relative file path is required',
                                        step,
                                        errorList,
                                    );
                                    hasError = true;
                                }
                            } else {
                                if (!relativeFilePath?.path?.length) {
                                    addValidationErrorObject(
                                        ERROR_CATEGORY_SPLIT_FILE,
                                        'Relative file path is required',
                                        step,
                                        errorList,
                                    );
                                    hasError = true;
                                }
                            }

                            if (relativeFilePath?.sourceStepId)
                                if (!step?.typeOfFileSplit?.length) {
                                    addValidationErrorObject(
                                        ERROR_CATEGORY_SPLIT_FILE,
                                        'Type of file split is required',
                                        step,
                                        errorList,
                                    );
                                    hasError = true;
                                } else {
                                    if (
                                        typeOfFileSplit === 'binary' &&
                                        (byteSizePerFile === null ||
                                            byteSizePerFile === undefined ||
                                            !`${byteSizePerFile}`.length)
                                    ) {
                                        addValidationErrorObject(
                                            ERROR_CATEGORY_SPLIT_FILE,
                                            'Binary file requires bytes size per file',
                                            step,
                                            errorList,
                                        );
                                        hasError = true;
                                    } else if (typeOfFileSplit === 'record-based') {
                                        if (delimiter?.length === 0 || delimiter === null || delimiter === undefined) {
                                            addValidationErrorObject(
                                                ERROR_CATEGORY_SPLIT_FILE,
                                                'Record-based split requires a delimiter',
                                                step,
                                                errorList,
                                            );
                                            hasError = true;
                                        }

                                        if (
                                            recordCountPerFile === null ||
                                            recordCountPerFile === undefined ||
                                            `${recordCountPerFile}`.length === 0
                                        ) {
                                            addValidationErrorObject(
                                                ERROR_CATEGORY_SPLIT_FILE,
                                                'Record-based split requires a record count per file',
                                                step,
                                                errorList,
                                            );
                                            hasError = true;
                                        }
                                    }
                                }

                            if (hasError) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;
                        }

                        case constants.STEP_TYPE_COMBINE_FILES: {
                            const { typeOfFileCombine, sourceFilePaths, fileCombineDelimiter } = step;
                            let hasError = false;

                            if (!sourceFilePaths?.length) {
                                addValidationErrorObject(
                                    ERROR_CATEGORY_COMBINE_FILES,
                                    'Input file path is required',
                                    step,
                                    errorList,
                                );
                                hasError = true;
                            } else {
                                for (const sourceFilePath of sourceFilePaths) {
                                    if (sourceFilePath?.sourceStepId) {
                                        if (
                                            !sourceFilePath?.sourceDataTypeId?.length ||
                                            !Array.isArray(sourceFilePath?.sourceInputPath)
                                        ) {
                                            addValidationErrorObject(
                                                ERROR_CATEGORY_COMBINE_FILES,
                                                'Input file path is required',
                                                step,
                                                errorList,
                                            );
                                            hasError = true;
                                            break;
                                        }
                                    } else {
                                        if (!sourceFilePath?.path?.length) {
                                            addValidationErrorObject(
                                                ERROR_CATEGORY_COMBINE_FILES,
                                                'Input file path is required',
                                                step,
                                                errorList,
                                            );
                                            hasError = true;
                                            break;
                                        }
                                    }
                                }
                            }

                            if (
                                !typeOfFileCombine?.length ||
                                (typeOfFileCombine === 'record-based' && !fileCombineDelimiter?.length)
                            ) {
                                ++invalidStepValidationCounterRef.current;
                                addValidationErrorObject(
                                    ERROR_CATEGORY_COMBINE_FILES,
                                    'Record-based combine requires a delimiter',
                                    step,
                                    errorList,
                                );
                            }

                            if (hasError) {
                                ++invalidStepValidationCounterRef.current;
                            }
                            break;
                        }

                        case constants.STEP_TYPE_SET_VARIABLE:
                            if (step.variables) {
                                step.variables.forEach((variable) => {
                                    if (!variable.actionType) {
                                        ++invalidStepValidationCounterRef.current;
                                        addValidationErrorObject(
                                            ERROR_CATEGORY_VARIABLE,
                                            'Action type is required',
                                            step,
                                            errorList,
                                        );
                                    }

                                    // Validate each action type's fields
                                    const isValid = validateModifyVariableFieldsBasedOnActionType(
                                        variable,
                                        step,
                                        errorList,
                                    );

                                    if (!isValid) {
                                        ++invalidStepValidationCounterRef.current;
                                    }
                                });
                            } else {
                                ++invalidStepValidationCounterRef.current;
                                addValidationErrorObject(
                                    ERROR_CATEGORY_VARIABLE,
                                    'At least one variable is required',
                                    step,
                                    errorList,
                                );
                            }
                            break;

                        case constants.STEP_TYPE_RUN_INTEGRATION:
                            if (!step.integrationIdToRun) {
                                ++invalidStepValidationCounterRef.current;
                                addValidationErrorObject(
                                    ERROR_CATEGORY_RUN_INTEGRATION,
                                    'Integration is required',
                                    step,
                                    errorList,
                                );
                            } else {
                                const integrationData = await getIntegrationById(step.integrationIdToRun);

                                if (Array.isArray(integrationData?.steps)) {
                                    const triggerStep = integrationData.steps.find(
                                        (stepData) => stepData.stepType === constants.STEP_TYPE_TRIGGER,
                                    );

                                    if (triggerStep?.triggerType === constants.TRIGGER_API_CALL) {
                                        switch (step?.integrationDataValueType) {
                                            case 'none':
                                                // NO need to check secondary values.
                                                break;
                                            case 'mapping':
                                                if (!step?.mappingId) {
                                                    ++invalidStepValidationCounterRef.current;
                                                    addValidationErrorObject(
                                                        ERROR_CATEGORY_RUN_INTEGRATION,
                                                        'Integration using a mapping requires an assignment',
                                                        step,
                                                        errorList,
                                                    );
                                                } else {
                                                    const errorCount = isMappingComplete(
                                                        selectedStepMetadata,
                                                        step,
                                                        ERROR_CATEGORY_RUN_INTEGRATION,
                                                        errorList,
                                                    );

                                                    if (errorCount > 0) {
                                                        invalidStepValidationCounterRef += errorCount;
                                                    }
                                                }
                                                break;
                                            case 'text':
                                                if (
                                                    typeof step?.integrationDataRawText !== 'string' ||
                                                    step.integrationDataRawText.length === 0
                                                ) {
                                                    ++invalidStepValidationCounterRef.current;
                                                    addValidationErrorObject(
                                                        ERROR_CATEGORY_RUN_INTEGRATION,
                                                        'Integration using text requires a value',
                                                        step,
                                                        errorList,
                                                    );
                                                }
                                                break;
                                            case 'stepOutput':
                                                if (
                                                    !step?.integrationDataTypeId ||
                                                    !step?.integrationDataSourceDisplayLabel
                                                ) {
                                                    ++invalidStepValidationCounterRef.current;
                                                    addValidationErrorObject(
                                                        ERROR_CATEGORY_RUN_INTEGRATION,
                                                        'Integration using a step output requires an assignment',
                                                        step,
                                                        errorList,
                                                    );
                                                }
                                                break;
                                            default:
                                                ++invalidStepValidationCounterRef.current;
                                                addValidationErrorObject(
                                                    ERROR_CATEGORY_RUN_INTEGRATION,
                                                    'Integration requires value type for trigger data',
                                                    step,
                                                    errorList,
                                                );
                                                break;
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            // Nothing
                            break;
                    }

                    await traverseNextSteps({ nextSteps: step.nextSteps, previousStepId: nextStepId, errorList });
                }
            }
        }

        return invalidStepValidationCounterRef.current === 0;
    };

    let nextStepId = firstStepId;
    if (!nextStepId) {
        const triggerStep = getTriggerStepData(steps);
        nextStepId = triggerStep?.stepId;
    }

    const targetStep = await traverseNextSteps({ nextSteps: [nextStepId], errorList }),
        isValid = invalidStepValidationCounterRef.current === 0;

    if (!step && typeof targetStep === 'object' && targetStep?.stepId) {
        step = targetStep;
    }

    setIntegrationBuilderState('currentValidationErrors', errorList);

    return {
        step,
        isValid,
        topLevelLoopStepData,
    };
};

/**
 * Evaluates whether a loops steps contain invalid steps for parallelization
 * @param {Object} containedSteps
 * @returns {boolean}
 */
const isListOfContainedStepsValidForParallelLoopStep = (containedSteps) => {
    for (const step of containedSteps) {
        switch (step?.stepType) {
            case constants.STEP_TYPE_CONTINUE_LOOP:
            case constants.STEP_TYPE_STOP_LOOP:
            case constants.STEP_TYPE_INCREMENT_DECREMENT_COUNTER:
            case constants.STEP_TYPE_STOP_WORKFLOW:
                return false;
            case constants.STEP_TYPE_LOOP:
                if (step?.runIterationsInParallel === true) {
                    return false;
                }
                break;
            default:
                break;
        }
    }

    return true;
};

/**
 * Get steps without data type support
 * @returns {string[]}
 */
const getStepsWithoutDataTypeSupport = () => [
    constants.STEP_TYPE_LOG,
    constants.STEP_TYPE_DELAY,
    constants.STEP_TYPE_STOP_LOOP,
    constants.STEP_TYPE_SET_VARIABLE,
    constants.STEP_TYPE_CONTINUE_LOOP,
    constants.STEP_TYPE_STOP_WORKFLOW,
    constants.STEP_TYPE_END_CONDITIONAL,
    // constants.STEP_TYPE_CONDITIONAL,
];

/**
 * Get mutable integration step by step id
 * @param {object} filterArgs
 * @param {number} filterArgs.stepId
 * @param {Array<object>} filterArgs.steps Default to current integration steps.
 * @param {({steps: Array<object>, step: object, index: number})} callback
 * @return {Array<object>} Mutable steps
 */
const getMutableIntegrationStepByStepId = ({ stepId, steps }, callback) => {
    let currentSteps = steps;
    if (!currentSteps) {
        currentSteps = getIntegrationBuilderState('currentLocalIntegrationSteps');
    }

    const _steps = _.cloneDeep(currentSteps);

    for (let i = 0; i < _steps.length; i++) {
        const step = _steps[i];
        if (step?.stepId === stepId) {
            callback({ steps: _steps, step, index: i });
            break;
        }
    }

    // return _.cloneDeep(steps);
    return _steps;
};

/**
 * Get mutable steps
 * @param {object} args
 * @param {Array<{stepId: number, callback: ({steps: Array<object>, step: object, index: number})}>} args.mutations
 * @param {Array<object>} args.steps Default to current integration steps.
 * @return {Array<object>} Mutated integration steps.
 */
const getMutableIntegrationSteps = ({ mutations, steps }) => {
    let currentSteps = steps;
    if (!currentSteps) {
        currentSteps = getIntegrationBuilderState('currentLocalIntegrationSteps');
    }

    const _steps = _.cloneDeep(currentSteps);

    for (let i = 0; i < _steps.length; i++) {
        const step = _steps[i];

        mutations.forEach((mutation) => {
            if (mutation?.stepId === step?.stepId) {
                mutation?.callback({ steps: _steps, step, index: i });
            }
        });
    }

    return _steps;
};

/***
 * Mutate condition step and add last 'else' condition to conditional list
 * @param {{step: object }}
 */
const addLastElseConditionToConditionList = ({ step }) => {
    const conditionList = step?.conditionList;
    if (!conditionList) {
        return;
    }

    // const elseStep = step.conditionList.find(item => item.name?.toLowerCase() === constants.STEP_ELSE_CONDITIONAL_NAME.toLowerCase());
    let elseStep = null;
    const defaultElseConditionName = constants.STEP_ELSE_CONDITIONAL_NAME.toLowerCase();

    for (let i = 0; i < step.conditionList.length; i++) {
        if (step.conditionList?.[i]?.name?.toLowerCase() === defaultElseConditionName) {
            elseStep = step.conditionList[i];
            step.conditionList.splice(i, 1);
            break;
        }
    }

    step.conditionList.push(
        elseStep
            ? elseStep
            : {
                  name: constants.STEP_ELSE_CONDITIONAL_NAME,
                  nextSteps: [],
                  expressionList: [],
              },
    );
};

/**
 * Filter expression object with indexes
 * @param {Object} args
 * @param {object} args.step
 * @param {function} args.callback
 * @param {number} args.conditionIndex
 * @param {Array<number>} args.expressionIndexes
 */
const filterConditionalStepExpression = ({ expressionIndexes, step, conditionIndex, callback }) => {
    const loopExpression = ({ expressionIndexes, expressions }) => {
        const index = expressionIndexes.shift(),
            expression = expressions[index] || {};

        if (index !== undefined && expressionIndexes.length && expression?.expressionList?.length) {
            loopExpression({ expressionIndexes, expressions: expression?.expressionList });
        } else {
            callback && callback({ expression, expressions, index });
        }
    };

    let expressionList;
    if (step.stepType === constants.STEP_TYPE_LOOP) {
        expressionList = step.loopWhileExpressions;
    } else {
        expressionList = step.conditionList?.[conditionIndex]?.expressionList;
    }

    loopExpression({ expressionIndexes, expressions: expressionList });
};

/**
 * Filter loop step by container step ID
 * @param {{ topLevelLoopStepData: object, loopStepId: number, callback: ({ step: object, containedSteps: Array<object>, parentLoopStep: object })}}
 * @return {object} Mutable loop step object by callback function
 */
const getMutableLoopStep = ({ topLevelLoopStepData, loopStepId, callback }) => {
    const traverseLoopStep = ({ step, containedSteps, parentLoopStep = null }) => {
        const nestedContainedSteps = step?.containedSteps || [];
        if (step && nestedContainedSteps.length && step?.stepId !== loopStepId) {
            nestedContainedSteps?.forEach((containedStep) => {
                traverseLoopStep({
                    step: containedStep,
                    containedSteps: nestedContainedSteps,
                    parentLoopStep: step,
                });
            });
        } else if (step?.stepId === loopStepId) {
            callback && callback({ step, containedSteps, parentLoopStep });
        }
    };

    const loopStepData = _.cloneDeep(topLevelLoopStepData);
    traverseLoopStep({
        step: loopStepData,
        parentLoopStep: loopStepData,
        containedSteps: loopStepData?.containedSteps || [],
    });

    return loopStepData;
};

/**
 * Mutable integration step down to any nested level
 * @param {{ stepId: number, steps: Array<object>, topLevelLoopStepData: object, callback: ({step: object, index: number, steps: Array<object>, topLevelLoopStepData: object})}}
 * @return {{steps: Array<object>, topLevelLoopStepData: object|undefined}} Mutable steps
 */
const getMutableIntegrationStepDeep = ({ topLevelLoopStepData, callback, stepId, steps }) => {
    if (!callback) {
        return { steps, topLevelLoopStepData };
    }

    if (topLevelLoopStepData && topLevelLoopStepData?.stepType === constants.STEP_TYPE_LOOP) {
        let wasTargetLoopStepFound = false;

        const newTopLevelStepData = getMutableLoopStep({
            topLevelLoopStepData,
            loopStepId: stepId,
            callback: ({ step, containedSteps }) => {
                wasTargetLoopStepFound = true;

                // Get the step index
                const stepIndex = containedSteps?.findIndex((elem) => elem.stepId === step?.stepId);

                callback({
                    step,
                    steps: containedSteps,
                    index: stepIndex,
                    topLevelLoopStepData,
                });
                // callback({
                //     step: containedSteps[stepIndex],
                //     steps: containedSteps,
                //     index: stepIndex,
                //     topLevelLoopStepData,
                // });
            },
        });

        // Update the top level loop step data automatically
        if (wasTargetLoopStepFound) {
            const mutatedSteps = getMutableIntegrationStepByStepId(
                {
                    steps,
                    stepId: newTopLevelStepData?.stepId,
                },
                ({ steps, index }) => {
                    steps[index] = newTopLevelStepData;
                },
            );

            return {
                steps: _.cloneDeep(mutatedSteps),
                topLevelLoopStepData: newTopLevelStepData,
            };
        }
    }

    const mutatedSteps = getMutableIntegrationStepByStepId(
        {
            steps,
            stepId,
        },
        (args) => {
            callback(args);
        },
    );

    return { steps: _.cloneDeep(mutatedSteps), topLevelLoopStepData };
};

/**
 * Get a copy of a step definition for the provided list of steps.
 * @param stepId
 * @param [listOfSteps] If not provided, current integration store state of currentLocalIntegrationSteps will be used
 * @returns {null|{}}
 */
const getCopyOfIntegrationStepById = (stepId, listOfSteps = null) => {
    const stepsToSearch =
        listOfSteps === null ? getIntegrationBuilderState('currentLocalIntegrationSteps') : listOfSteps;

    for (const step of stepsToSearch) {
        if (step?.stepId === stepId) {
            return _.cloneDeep(step);
        } else if (Array.isArray(step.containedSteps) && step.containedSteps.length > 0) {
            const result = getCopyOfIntegrationStepById(stepId, step.containedSteps);
            if (result !== null) {
                return result;
            }
        }
    }

    return null;
};

/**
 * Get conditional step related nextSteps IDs
 * @param {Object} args
 * @param {object} args.stepData conditional step data
 * @param {Array<object>} args.steps integration steps
 * @return {Array<number>} Conditional step related next steps IDs
 */
const getConditionalStepRelatedNextStepsIds = ({ stepData, steps }) => {
    const newStepIds = [];
    let currentSteps = steps;

    if (!currentSteps) {
        currentSteps = getIntegrationBuilderState('currentLocalIntegrationSteps');
    }

    const traverseNextSteps = ({ nextSteps }) => {
        if (nextSteps) {
            const nextStepId = nextSteps?.[0],
                step = currentSteps.find((step) => step?.stepId === nextStepId);

            if (step) {
                const isConditionalStep = step?.stepType === constants.STEP_TYPE_CONDITIONAL,
                    isEndConditionalStep = step?.stepType === constants.STEP_TYPE_END_CONDITIONAL;

                if (isConditionalStep) {
                    newStepIds.push(step.stepId);

                    step?.conditionList?.forEach((condition) => {
                        traverseNextSteps({ nextSteps: condition?.nextSteps });
                    });
                } else if (!isEndConditionalStep) {
                    newStepIds.push(step.stepId);
                    traverseNextSteps({ nextSteps: step.nextSteps });
                }
            }
        }
    };

    traverseNextSteps({ nextSteps: [stepData?.stepId] });

    return newStepIds;
};

/**
 * Get current step data
 * @param {{step: object, mappings: Array<object>, isStepParameterDropdownLabel: boolean}}
 * @returns {string} Step rendering data
 */
const getStepLabel = ({ step, mappings = [], isStepParameterDropdownLabel = false }) => {
    const isTriggerStepType = step.stepType === constants.STEP_TYPE_TRIGGER,
        stepTypes = getStepTypes();

    const stepInfoData = isTriggerStepType
        ? { name: constants.STEP_TYPE_NAME_TRIGGER }
        : stepTypes.find((item) => item.id === step.stepType);

    let stepLabel = isTriggerStepType ? step?.triggerType : stepInfoData?.description || '[Label]',
        stepName = stepInfoData.name;

    if (step?.triggerType === constants.TRIGGER_API_CALL) {
        stepLabel = constants.TRIGGER_API_CALL_LABEL;
    } else if (constants.STEP_TYPE_CONVERT === step.stepType) {
        const mapping = mappings.find((mapping) => mapping?._id === step?.mappingId) || {};
        stepLabel = mapping?.displayName || mapping?.name || '[add mapping]';
    } else if (constants.STEP_TYPE_RESTFUL_REQUEST === step.stepType) {
        const requestUrl = step?.restfulRequestStepUrl || '[method]',
            requestMethod = step?.restfulRequestStepMethod || '[URL]';

        stepLabel = `${requestMethod} - ${requestUrl}`;
        if (!isStepParameterDropdownLabel) stepName = '';
    } else if (constants.STEP_TYPE_APP_REQUEST === step.stepType) {
        const appInstances = getIntegrationBuilderState('appInstances'),
            selectedAppInstanceData = step?.myAppInstanceId
                ? appInstances.find((v) => v._id === step?.myAppInstanceId)
                : {};

        const resourceLabel = step?.resource?.label || (step?.resource?.split ? step.resource : null),
            actionLabel = step?.action?.label || (step.action?.split ? step.action : null);

        // let resourceLabelStr = resourceLabel ? `[${resourceLabel}]` : `[${'add resource'}]`,
        //     actionLabelStr = actionLabel ? `[${actionLabel}]` : `[${'and action'}]`;

        const resourceLabelStr = resourceLabel ? `${resourceLabel}` : `add resource`,
            actionLabelStr = actionLabel ? `${actionLabel}` : `and action`,
            appInstanceName = selectedAppInstanceData?.appName
                ? `${isStepParameterDropdownLabel ? ' - ' : ''}${selectedAppInstanceData?.appName} - `
                : '';

        stepLabel = appInstanceName + `${resourceLabelStr} - ${actionLabelStr}`;

        if (!isStepParameterDropdownLabel) stepName = '';
    }

    return `${stepName} ${stepLabel}`.trim();
};

/**
 * Get all conditional step branches previous steps
 * @param {Object} args
 * @param {object} args.step
 * @param {object[]} args.steps
 * @param {object} args.selectedStepMetadata
 * @param {object} args.topLevelLoopStepData
 * @param {React.MutableRefObject} args.stepCounterListRef
 * @return {object[]}
 */
const getPreviousStepsInRelatedBranches = ({
    step: _step,
    steps: _steps,
    stepCounterListRef,
    topLevelLoopStepData,
    selectedStepMetadata,
}) => {
    let previousSteps = [];
    const processedStepObj = {};

    if (!_step?.stepId) {
        return previousSteps;
    }

    let steps = _steps;
    const stepsWithoutDataTypeSupport = getStepsWithoutDataTypeSupport();

    const populatePreviousSteps = (data) => {
        const stepType = data?.step?.stepType;
        if (!stepType || data?.step?.stepId === selectedStepMetadata?.stepId) {
            return;
        }

        // Ensure step number is below selected step
        if (
            selectedStepMetadata?.stepData?.containerStepId &&
            `${stepCounterListRef.current?.[data?.step?.stepId]}`.length < 6 &&
            stepCounterListRef.current?.[data?.step?.stepId] >
                stepCounterListRef.current?.[selectedStepMetadata?.stepData?.stepId]
        ) {
            return;
        }

        // Don't add step that have same containerStepId as selected step in loop
        // Don't add step already in {previousSteps} list
        if (
            (selectedStepMetadata?.stepData?.containerStepId &&
                selectedStepMetadata?.stepData?.containerStepId === data?.step?.containerStepId &&
                stepType === constants.STEP_TYPE_LOOP) ||
            processedStepObj?.[data?.step?.stepId] ||
            stepType === constants.STEP_TYPE_CONDITIONAL ||
            stepsWithoutDataTypeSupport.includes(stepType)
        ) {
            return;
        }

        previousSteps = [data].concat(previousSteps);
    };

    if (!_steps) {
        steps = getIntegrationBuilderState('currentLocalIntegrationSteps');
    }

    /**
     * Get last step ID in loop step
     * @param {Number} stepId
     * @returns {Number}
     */
    const getLastStepIdInLoopStep = (stepId) => {
        const stepElem = document?.querySelector(`.renderStepTypeItem-${stepId}`),
            renderLoopStepWrapper = stepElem ? stepElem?.closest('.renderLoopStepWrapper') : null;

        if (!renderLoopStepWrapper) {
            return;
        }

        const loopStepElements = renderLoopStepWrapper.querySelectorAll('.renderStepTypeItem');
        if (!loopStepElements || !loopStepElements?.length) {
            return;
        }

        const lastLoopStepNodeElem = loopStepElements.item(loopStepElements.length - 1);

        return parseInt(lastLoopStepNodeElem?.getAttribute('data-step-id'));
    };

    const traversePreviousStep = ({ stepId, integrationSteps, containerStepId }) => {
        const step = integrationSteps?.find((integrationStep) => integrationStep?.stepId === stepId) || {},
            stepType = step?.stepType;

        if (!containerStepId && !stepId) {
            return previousSteps;
        }

        let loopStep, loopContainedSteps;

        if (stepType === constants.STEP_TYPE_LOOP) {
            getMutableLoopStep({
                topLevelLoopStepData,
                loopStepId: stepId,
                callback: ({ step }) => {
                    loopStep = step;
                    loopContainedSteps = step.containedSteps;
                },
            });

            if (!loopStep) {
                return;
            }

            populatePreviousSteps({
                step: loopStep,
            });

            processedStepObj[loopStep.stepId] = 1;

            const firstStepInLoopStepId = loopContainedSteps?.[0]?.stepId;
            if (!firstStepInLoopStepId) {
                return;
            }

            let lastLoopStepNodeStepId;

            /**
             * Get step in loop starting from last step
             */

            if (loopContainedSteps.length === 1) {
                lastLoopStepNodeStepId = firstStepInLoopStepId;
            } else {
                if (selectedStepMetadata?.stepData.containerStepId === loopStep?.stepId) {
                    lastLoopStepNodeStepId = selectedStepMetadata?.stepData?.stepId;
                } else {
                    lastLoopStepNodeStepId = getLastStepIdInLoopStep(firstStepInLoopStepId);
                }
            }

            if (!lastLoopStepNodeStepId) {
                return;
            }

            traversePreviousStep({
                stepId: lastLoopStepNodeStepId, // last step in loop
                integrationSteps: loopContainedSteps,
                containerStepId: undefined,
            });
        } else if (containerStepId && !stepType) {
            getMutableLoopStep({
                topLevelLoopStepData,
                loopStepId: containerStepId,
                callback: ({ step }) => {
                    loopStep = step;
                },
            });

            if (!loopStep) {
                return;
            }

            // Use previous step ID of loop step as {stepId} is not given
            traversePreviousStep({
                stepId: stepId || loopStep.prevSteps?.[0],
                integrationSteps: loopStep.containedSteps,
                containerStepId: loopStep.containerStepId,
            });

            populatePreviousSteps({
                step: loopStep,
            });

            processedStepObj[loopStep.stepId] = 1;
        } else {
            populatePreviousSteps({
                step,
            });

            processedStepObj[step?.stepId] = 1;

            traversePreviousStep({
                integrationSteps,
                stepId: step.prevSteps?.[0],
                containerStepId: step.containerStepId,
            });
        }
    };

    traversePreviousStep({
        stepId: _step.prevSteps?.[0] || _step.containerStepId,
        integrationSteps: steps,
        containerStepId: _step.containerStepId,
    });

    // Ensure all top level fields are added before first conditional step
    if (!previousSteps?.find((previousStep) => previousStep.step?.stepType === constants.STEP_TYPE_TRIGGER)) {
        const topLevelSteps = [];
        for (const step of steps) {
            if (
                processedStepObj?.[step?.stepId] ||
                stepsWithoutDataTypeSupport.includes(step?.stepType) ||
                step?.stepType === constants.STEP_TYPE_CONDITIONAL
            ) {
                continue;
            }

            // if (
            //     selectedStepMetadata?.stepData?.containerStepId &&
            //     stepCounterListRef.current?.[step?.stepId] >
            //         stepCounterListRef.current?.[selectedStepMetadata?.stepData?.containerStepId]
            // ) {
            //     break;
            // }

            topLevelSteps.push({ step });

            if (step?.stepType === constants.STEP_TYPE_LOOP) break;
        }

        previousSteps = topLevelSteps.concat(previousSteps);
    }

    // Normalize counter
    // return previousSteps.map((previousStep, i) => ({ step: previousStep.step, counter: i + 1 }));
    return previousSteps
        .map((previousStep) => ({ step: previousStep.step, counter: previousStep?.step?.stepId }))
        .sort((a, b) => (a?.counter < b?.counter ? -1 : 0));
};

/**
 * @typedef {{}} SelectedFieldObject
 * @property {number} [cardinalityMax]
 * @property {number} [cardinalityMin]
 * @property {string} [name]
 * @property {string} [description]
 * @property {string} [displayName]
 * @property {string} [dataTypeId]
 * @property {[]} [fields]
 * @property {number} [occurrenceIndex]
 * @property {{}|null} dataType
 * @property {SelectedFieldObject[]} parent
 */

/**
 * @typedef {SelectedFieldObject} SelectedFieldObjectWithOccurrences
 * @property {[{dataTypeFieldId, occurrenceIndex}]} [inputPathWithSelectedOccurrences] an array of input path elements with each occurrence index specified for array fields
 */

/**
 * Generates selectedField object for integration step parameter modal selectedField state value
 * @param {String} parentDataTypeId
 * @param {[{dataTypeFieldId, occurrenceIndex}]} fieldInputPath
 * @param {String|null} dataTypeIdForRosettaConvertReturnData
 * @returns {SelectedFieldObject}
 */
const generateSelectedFieldObject = (
    parentDataTypeId,
    fieldInputPath = [],
    dataTypeIdForRosettaConvertReturnData = null,
) => {
    if (parentDataTypeId?.length) {
        let currentParentDataType = getDataTypeCloneByDataTypeId(parentDataTypeId);
        let currentField, currentFieldDataType;

        // make a fake "field" schema for the root node
        const rootDataTypeFieldObject = {
            _id: null,
            name: currentParentDataType.name,
            displayName: currentParentDataType.displayName,
            fields: currentParentDataType.fields,
            description: currentParentDataType.description,
            cardinalityMin: 1,
            cardinalityMax: 1,
            occurrenceIndex: 1,
            dataTypeId: currentParentDataType._id,
            // extra props
            dataType: currentParentDataType,
        };

        if (fieldInputPath.length > 0) {
            const selectedFieldId = fieldInputPath.at(-1).dataTypeFieldId;

            const isRootParentConvertReturn =
                parentDataTypeId === constants.ROSETTA_DATA_TYPE_MAPPING_OUTPUT_DATA_TYPE_ID;

            const parentPath = [rootDataTypeFieldObject];

            // take off the first "root" element from the path
            const fieldsToIterateOver = fieldInputPath.slice(0, -1);

            /*
                Build a parent path array containing all parent fields and their data types
                 */
            if (fieldsToIterateOver.length > 0) {
                fieldsToIterateOver?.forEach(({ dataTypeFieldId, occurrenceIndex }) => {
                    /*
                        for the convert or mapping data type return, the "Data" property is substituted for the data type
                        of the output for the mapping. That needs to be provided to this function via the dataTypeIdForRosettaConvertReturnData
                        parameter.
                         */
                    if (
                        isRootParentConvertReturn &&
                        dataTypeFieldId === constants.ROSETTA_DATA_TYPE_MAPPING_OUTPUT_DATA_FIELD_ID
                    ) {
                        currentFieldDataType = getDataTypeCloneByDataTypeId(dataTypeIdForRosettaConvertReturnData);

                        const tempConvertReturnDataType = getDataTypeCloneByDataTypeId(
                            constants.ROSETTA_DATA_TYPE_MAPPING_OUTPUT_DATA_TYPE_ID,
                        );
                        currentField = tempConvertReturnDataType?.fields?.find(
                            (field) => field._id === dataTypeFieldId,
                        );
                        // currentField._id = dataTypeFieldId;
                    } else if (currentParentDataType?.fields?.length > 0) {
                        currentField = currentParentDataType.fields.find((field) => field._id === dataTypeFieldId);
                        currentFieldDataType = getDataTypeCloneByDataTypeId(currentField.dataTypeId);
                    } else if (currentField?.fields?.length > 0) {
                        currentField = currentField.fields.find((field) => field._id === dataTypeFieldId);
                        currentFieldDataType = getDataTypeCloneByDataTypeId(currentField.dataTypeId);
                    } else {
                        // the dataTypeId prop gets set for matched elements of the dataType.fields array
                        const foundParentDataType = getDataTypeCloneByDataTypeId(currentParentDataType._id);
                        const foundFieldWithinParent = foundParentDataType?.fields?.find(
                            (field) => field._id === dataTypeFieldId,
                        );

                        if (foundFieldWithinParent) {
                            currentField = { ...foundFieldWithinParent };
                            currentFieldDataType = getDataTypeCloneByDataTypeId(foundFieldWithinParent._id);
                        } else {
                            currentField = {};
                            currentFieldDataType = null;
                        }
                    }

                    parentPath.push({
                        ...currentField,
                        occurrenceIndex: isNaN(parseInt(occurrenceIndex))
                            ? occurrenceIndex
                            : parseInt(occurrenceIndex) + 1,
                        dataType: currentFieldDataType,
                    });

                    currentParentDataType = currentFieldDataType;
                });
            }

            let outputField, outputFieldDataType;
            if (
                fieldInputPath.length === 1 &&
                isRootParentConvertReturn &&
                fieldInputPath[0]?.dataTypeFieldId === constants.ROSETTA_DATA_TYPE_MAPPING_OUTPUT_DATA_FIELD_ID
            ) {
                outputFieldDataType = getDataTypeCloneByDataTypeId(dataTypeIdForRosettaConvertReturnData);

                const tempConvertReturnDataType = getDataTypeCloneByDataTypeId(
                    constants.ROSETTA_DATA_TYPE_MAPPING_OUTPUT_DATA_TYPE_ID,
                );

                outputField = tempConvertReturnDataType?.fields?.find(
                    (field) => field._id === fieldInputPath[0]?.dataTypeFieldId,
                );
            } else if (
                currentParentDataType?._id === constants.DATA_TYPE_ID_OBJECT &&
                currentParentDataType?.fields?.length > 0
            ) {
                outputField = currentParentDataType.fields.find((field) => field._id === selectedFieldId);
                outputFieldDataType = getDataTypeCloneByDataTypeId(outputField.dataTypeId);
            } else if (currentField?.dataTypeId === constants.DATA_TYPE_ID_OBJECT && currentField?.fields?.length > 0) {
                outputField = currentField.fields.find((field) => field._id === selectedFieldId);
                outputFieldDataType = getDataTypeCloneByDataTypeId(outputField.dataTypeId);
            } else {
                const foundField = currentParentDataType?.fields?.find((field) => field._id === selectedFieldId);

                if (foundField) {
                    outputField = { ...foundField };
                    outputFieldDataType = getDataTypeCloneByDataTypeId(foundField.dataTypeId);
                } else {
                    outputField = null;
                    outputFieldDataType = null;
                }
            }

            const output = {
                ...outputField,
                occurrenceIndex: isNaN(parseInt(fieldInputPath?.at(-1).occurrenceIndex))
                    ? fieldInputPath?.at(-1)?.occurrenceIndex
                    : parseInt(fieldInputPath?.at(-1)?.occurrenceIndex) + 1,
                // optional
                parent: parentPath,
                dataType: outputFieldDataType,
            };

            return output;
        } else {
            // root data selected
            return {
                ...rootDataTypeFieldObject,
                parent: [],
            };
        }
    }

    return {
        dataType: null,
        parent: [],
    };
};

/**
 *
 * @param {Number} headingNumber
 * @returns {{output: string, valueStyle: {}}|{output: string, valueStyle: {color: string}}}
 */
const getIntegrationLogOutputHeadingStyleObject = (headingNumber) => {
    switch (headingNumber) {
        case 1:
            return {
                output: '=================================================',
                valueStyle: { color: '#e81dd7' },
            };
        case 2:
            return {
                output: '*************************************************',
                valueStyle: { color: '#23b948' },
            };
        case 3:
            return {
                output: '+++++++++++++++++++++++++++++++++++++++++++++++++',
                valueStyle: { color: '#8c4bff' },
            };
        case 4:
            return {
                output: '-------------------------------------------------',
                valueStyle: { color: '#29c9d3' },
            };
        case 5:
            return {
                output: '###',
                valueStyle: { color: '#d7af2f' },
            };
        case 6:
            return {
                output: '@@@',
                valueStyle: { color: '#da842a' },
            };
        default:
            return {
                output: '',
                valueStyle: {},
            };
    }
};

/**
 * Active log output step styling
 * @return {Object}
 */
const getLogOutputParentWrapperStyles = () => {
    return {
        overflow: 'hidden',
        '& .is-log-entry-active': {
            border: '2px dashed #d8e6ff',
            borderRadius: '5px',
        },
    };
};

/**
 * Get logout output entry step ID. Uses `entryObj.message` if `entryObj.stepId` is undefined.
 * @param {object|string} entryObj
 * @return {string}
 */
const getLogOutputEntryObjectStepId = (entryObj) => {
    if (!entryObj) return '';
    let stepId = entryObj?.stepId;
    if (!stepId) {
        stepId = md5(entryObj?.message || (typeof entryObj === 'string' ? entryObj : '') || '')?.toString();
    }
    return stepId;
};

/**
 * Highlight integration test data output
 * @param {Object[]} logEntries
 * @returns {JSX.Element}
 */
const highlightIntegrationTestOutput = (logEntries) => {
    let valueStyle = {},
        stringOutput = '';
    const stepOccurrenceList = {};

    const logOutputItemStyle = { wordWrap: 'break-word' },
        logEntryWrapperStyle = { width: '100%' };

    const processLogEntry = (data, iterations = []) => {
        const headingPrefix = [],
            headingSuffix = [],
            processedLogEntry = {};

        if (data.heading !== null) {
            const headingStyle = getIntegrationLogOutputHeadingStyleObject(data.heading);

            stringOutput = headingStyle.output;
            valueStyle = headingStyle.valueStyle;

            if (stringOutput) {
                headingPrefix.push(<StringOutput key="test-data-heading-prefix-1" data={data} />);
                headingSuffix.push(<StringOutput key="test-data-heading-prefix-2" data={data} />);
            }
        } else {
            valueStyle = { color: '#ffffff' };
        }

        const objectDataToDump = [];
        if (Array.isArray(data.objects) && data.objects.length > 0) {
            let i = 0;
            for (const objectToDump of data.objects) {
                let valueToOutput;
                if (typeof objectToDump === 'string' || objectToDump === null) {
                    valueToOutput = objectToDump;
                } else if (!Object.keys(objectToDump).length) {
                    valueToOutput = '{}';
                } else {
                    valueToOutput = <ToggleObjectData data={objectToDump} />;
                }

                const stepElemId = objectToDump?.message ? getLogOutputEntryObjectStepId(objectDataToDump) : '',
                    elemClassName = objectToDump?.message ? `testDataItem ${stepElemId}` : 'testDataItem';

                objectDataToDump.push(
                    <div key={`test-data-objects-${i}`} className={elemClassName} style={logOutputItemStyle}>
                        {/*<span style={timeStyle}>{data.timestamp}</span>*/}
                        {/*<span style={{ paddingRight: '5px' }}>:</span>*/}
                        <span style={valueStyle}>{valueToOutput}</span>
                    </div>,
                );
                i++;
            }
        }

        const logEntriesOutput = [];
        if (Array.isArray(data.logEntries) && data.logEntries.length > 0) {
            if (data?.stepId) {
                if (!stepOccurrenceList[data.stepId]) {
                    stepOccurrenceList[data.stepId] = 1;
                } else {
                    stepOccurrenceList[data.stepId]++;
                }
            }
            let i = 0;
            for (const entry of data.logEntries) {
                // console.log('entry: ', entry);
                const containerLoopOccurrenceIndex = entry?.containerLoopOccurrenceIndex,
                    hasOccurrence = containerLoopOccurrenceIndex !== null && containerLoopOccurrenceIndex !== undefined;

                let logEntryClassName = `log-entry-step-${getLogOutputEntryObjectStepId(entry)}`;
                if (hasOccurrence) {
                    logEntryClassName += ` log-entry-step-occurrence-${containerLoopOccurrenceIndex}`;
                }
                logEntryClassName += ` log-item-scroll-target-${data.stepId}-${stepOccurrenceList[data.stepId]}`;

                logEntriesOutput.push(
                    <div className={logEntryClassName} key={i} style={logEntryWrapperStyle}>
                        {processLogEntry(
                            entry,
                            hasOccurrence ? iterations.concat(containerLoopOccurrenceIndex) : iterations,
                        )}
                    </div>,
                );
                i++;
            }
        }

        let logItemWrapperClassName = `log-item-wrapper`;
        const logEntryId =
                data?.stepId +
                // '-' +
                (iterations?.length ? '-' : '') +
                iterations.join('-'),
            hasOccurrence =
                data?.containerLoopOccurrenceIndex !== null && data?.containerLoopOccurrenceIndex !== undefined;

        if (!processedLogEntry?.[logEntryId] && data?.heading && hasOccurrence) {
            logItemWrapperClassName += ` mainTestDataItem-${logEntryId}`;
            processedLogEntry[logEntryId] = 1;
        }

        let logItemStyles = {};
        switch (data.type) {
            case 'error':
                logItemStyles = {
                    background: '#d15858',
                    borderRadius: '3px',
                };
                break;
            case 'warning':
                logItemStyles = {
                    background: '#d8a266',
                    borderRadius: '3px',
                };
                break;
            case 'notice':
                logItemStyles = {
                    background: '#5ca9d5',
                    borderRadius: '3px',
                };
                break;
            default:
                break;
        }

        return (
            <div className={logItemWrapperClassName} style={logItemStyles}>
                {headingPrefix}
                <StringOutput data={data} message={data.message} />
                {objectDataToDump}
                {headingSuffix}
                {logEntriesOutput}
            </div>
        );
    };

    if (Array.isArray(logEntries)) {
        return logEntries.map((data, index) => (
            <Fragment key={index}>
                {processLogEntry(data, data?.containerLoopOccurrenceIndex ? [data.containerLoopOccurrenceIndex] : [])}
            </Fragment>
        ));
    } else {
        return <Fragment></Fragment>;
    }
};

/**
 * Recursive function to parse the integration log output and return a text value.
 *
 * @param {{}} object
 * @returns {string}
 */
export const getIntegrationExecutionLogTextForObject = (object) => {
    let output = '',
        logHeadingText = '';

    const timestampString = dayjs(object.timestamp).format(`YYYY-MM-DD HH:mm:ss`);

    if (object.heading) {
        const headingStyle = getIntegrationLogOutputHeadingStyleObject(object.heading);
        logHeadingText = headingStyle.output;
    }

    if (logHeadingText) {
        output += timestampString + '  ' + logHeadingText + '\r\n';
    }

    output += timestampString + '  ' + object.message + '\r\n';

    if (logHeadingText) {
        output += timestampString + '  ' + logHeadingText + '\r\n';
    }

    if (object.objects) {
        for (const objectToLog of object.objects) {
            output += JSON.stringify(objectToLog, null, 4) + '\r\n';
        }
    }

    for (const embeddedLogObjects of object.logEntries) {
        output += getIntegrationExecutionLogTextForObject(embeddedLogObjects);
    }

    return output;
};

/**
 * Copy integration test data to clipboard
 * @param {[]} logContent
 * @return {Function}
 */
export const handleCopyLog = (logContent) => () => {
    let textToCopy = '';

    for (const logObject of logContent) {
        textToCopy += getIntegrationExecutionLogTextForObject(logObject);
    }

    navigator.clipboard?.writeText(textToCopy)?.then(
        () => {
            showSnackBarSuccessNotification('Log copied successfully!');
        },
        () => {
            showSnackBarErrorNotification('Unable to copy log');
        },
    );
};

/**
 * @typedef LogTreeItem
 * @property {boolean} isLoopStep
 * @property {string} label
 * @property {number} stepId
 * @property {string} stepType
 * @property {number?} containerStepId
 * @property {Object} iterations
 * @property {string} targetClass
 * @property {boolean} isSuccess
 */

/**
 * @param entries
 * @param output
 * @param stepsCacheListRef
 * @param occurrenceList
 * @returns {{}}
 */
const buildRecursiveMapOfStepsFromLog = (entries, output = {}, stepsCacheListRef = {}, occurrenceList = {}) => {
    if (Array.isArray(entries)) {
        for (const item of entries) {
            if (item?.stepId) {
                const stepData = stepsCacheListRef?.[item.stepId];
                const hasOccurrenceIndex =
                    item?.containerLoopOccurrenceIndex !== null && item?.containerLoopOccurrenceIndex !== undefined;
                const hasLogEntries = Array.isArray(item?.logEntries) && item.logEntries.length > 0;
                const isLoopStep = stepData?.stepType === constants.STEP_TYPE_LOOP;
                const isError = item?.type === 'error';

                if (hasLogEntries || isError) {
                    if (!occurrenceList[item.stepId]) {
                        occurrenceList[item.stepId] = 1;
                    } else {
                        occurrenceList[item.stepId]++;
                    }

                    const dataToIncludeWithStep = {
                        isLoopStep: isLoopStep,
                        label: `${item.stepId}: ${stepData?.stepType}`,
                        stepId: item.stepId,
                        stepType: stepData?.stepType,
                        containerStepId: item?.containerStepId,
                        occurrence: item?.containerLoopOccurrenceIndex,
                        targetClass:
                            isError && isLoopStep
                                ? `log-entry-step-${item.stepId}`
                                : `log-item-scroll-target-${item.stepId}-${occurrenceList[item.stepId].toString()}`,
                        isSuccess: item.stepStatus === 'Success',
                        stepStartTime: item?.stepStartTime,
                        stepEndTime: item?.stepEndTime,
                    };
                    const outputObjectStepIdKey = `stepId-${item.stepId}`;
                    let listToUpdate;
                    if (hasOccurrenceIndex) {
                        if (!output[item.containerLoopOccurrenceIndex]) {
                            output[item.containerLoopOccurrenceIndex] = {};
                        }
                        if (!output[item.containerLoopOccurrenceIndex][outputObjectStepIdKey]) {
                            output[item.containerLoopOccurrenceIndex][outputObjectStepIdKey] = dataToIncludeWithStep;
                        }
                        listToUpdate = output[item.containerLoopOccurrenceIndex][outputObjectStepIdKey];
                    } else {
                        if (!output[outputObjectStepIdKey]) {
                            output[outputObjectStepIdKey] = dataToIncludeWithStep;
                        }

                        listToUpdate = output[outputObjectStepIdKey];
                    }

                    if (isLoopStep) {
                        listToUpdate['iterations'] = {};

                        listToUpdate = listToUpdate['iterations'];
                    }

                    buildRecursiveMapOfStepsFromLog(item.logEntries, listToUpdate, stepsCacheListRef, occurrenceList);
                }
            }
        }
    }

    return output;
};

/**
 * Get all step IDs in log
 * @param {{ log: object[], stepsCacheListRef: React.MutableRefObject }} args
 * @returns {{}}
 */
const getLoopIterations = ({ log, stepsCacheListRef }) => {
    const loopIterations = {},
        processedSteps = {};

    const traverseStepsInLogs = (entries) => {
        if (!entries?.forEach) return;

        for (const item of entries) {
            if (item?.stepId) {
                if (stepsCacheListRef?.[item.stepId]?.stepType === constants.STEP_TYPE_LOOP) {
                    if (!loopIterations?.[item.stepId]) {
                        loopIterations[item.stepId] = {};
                        processedSteps[item.stepId] = {};
                    }
                }

                if (
                    item?.containerStepId &&
                    item?.containerLoopOccurrenceIndex !== null &&
                    item?.containerLoopOccurrenceIndex !== undefined &&
                    loopIterations?.[item?.containerStepId]
                ) {
                    if (!loopIterations[item.containerStepId]?.[item.containerLoopOccurrenceIndex]) {
                        loopIterations[item.containerStepId][item.containerLoopOccurrenceIndex] = [];
                        processedSteps[item.containerStepId][item.containerLoopOccurrenceIndex] = {};
                    }

                    if (!processedSteps[item.containerStepId][item.containerLoopOccurrenceIndex][item.stepId]) {
                        processedSteps[item.containerStepId][item.containerLoopOccurrenceIndex][item.stepId] = 1;
                        loopIterations[item.containerStepId][item.containerLoopOccurrenceIndex].push(
                            stepsCacheListRef?.[item.stepId] || {},
                        );
                    }
                }
            }

            if (item?.logEntries?.length) {
                traverseStepsInLogs(item.logEntries);
            }
        }
    };

    traverseStepsInLogs(log);

    return loopIterations;
};

/**
 * @param logEntries
 * @param stepIdList
 */
const getUniqueLogIds = (logEntries, stepIdList = {}) => {
    if (!logEntries?.forEach) return;

    for (const item of logEntries) {
        if (item?.stepId) {
            stepIdList[item.stepId] = item;

            if (item?.logEntries?.length) {
                getUniqueLogIds(item.logEntries, stepIdList);
            }
        }
    }

    return stepIdList;
};

/**
 * Get log entry step element
 * @param {number} stepId
 * @param {number[]} iterations
 * @returns {HTMLDivElement}
 */
const getLogEntryStepElement = (stepId, iterations) => {
    if (!iterations || !iterations?.length) {
        return (
            document?.querySelector(`.mainTestDataItem-${stepId}`) ||
            document?.querySelector(`.log-entry-step-${stepId}`)
        );
    }

    const logEntryElems = document.querySelectorAll(`.mainTestDataItem-${stepId}-${iterations.join('-')}`),
        // logEntryElem = logEntryElems?.length ? logEntryElems.item(logEntryElems.length - 1) : null;
        logEntryElem = logEntryElems?.length ? logEntryElems.item(0) : null;

    return logEntryElem;
};

/**
 * Scroll to given log step in the rendered log output
 * @param {number} stepId
 */
const scrollToLogStep = (element) => {
    if (!element) return;
    document.querySelector('.is-log-entry-active')?.classList?.remove('is-log-entry-active');
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    element.classList.add('is-log-entry-active');
};

/**
 * Scroll to given log step in the rendered log output
 * @param {number} stepId
 * @param {number[]} iterations
 */
const scrollToLogStepById = (stepId, iterations) => {
    const logEntryElem = getLogEntryStepElement(stepId, iterations);
    document.querySelector('.is-log-entry-active')?.classList?.remove('is-log-entry-active');

    if (logEntryElem) {
        logEntryElem.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        logEntryElem.classList.add('is-log-entry-active');
    }
};

/**
 * @param {string} className
 */
const scrollToLogStepByClass = (className) => {
    document.querySelector('.is-log-entry-active')?.classList?.remove('is-log-entry-active');
    const elementList = document.getElementsByClassName(className);

    if (elementList.length > 0) {
        elementList[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        elementList[0].classList.add('is-log-entry-active');
    }
};

/**
 * Normalize integration steps data
 * @param {Object} args
 * @param {Array<object>} args.steps
 * @returns {Array<object>} Integration steps
 */
const normalizeIntegrationStepsData = ({ steps }) => {
    const _steps = _.cloneDeep(steps);

    const traverseNextSteps = ({ steps }) => {
        for (let i = 0; i < steps.length; i++) {
            const stepType = steps[i]?.stepType;

            if (stepType === constants.STEP_TYPE_LOOP) {
                traverseNextSteps({ steps: steps[i]?.containedSteps });
            } else {
                if (stepType === constants.STEP_TYPE_APP_REQUEST) {
                    steps[i].parameters =
                        steps[i].parameters?.filter(
                            (parameter) => parameter.name && (parameter?.value || parameter?.sourceDisplayLabel),
                        ) || [];
                } else if (stepType === constants.STEP_TYPE_TRIGGER) {
                    if (steps[i].triggerType !== constants.TRIGGER_TYPE_SCHEDULER) {
                        delete steps[i].action;
                        delete steps[i].freq;
                        delete steps[i].interval;
                        delete steps[i].startTime;
                        delete steps[i].monthlyInterval;
                    }
                } else if (stepType === constants.STEP_TYPE_INCREMENT_DECREMENT_COUNTER) {
                    steps[i].counters =
                        steps[i].counters?.map((counter) => ({
                            ...counter,
                            value: parseInt(counter.value),
                        })) || [];
                }
            }
        }
    };

    traverseNextSteps({ steps: _steps });

    return _steps.filter(
        (step) => step !== null && step !== undefined && step?.stepId !== undefined && step?.stepType !== undefined,
    );
};

/**
 * Check whether a step has source display label set
 * @param {{step: object, stepReferencedInOtherStepsRef: React.MutableRefObject}}
 */
const stepHasSourceDisplayLabel = ({ step, stepReferencedInOtherStepsRef }) => {
    if (step?.stepType === constants.STEP_TYPE_APP_REQUEST) {
        for (const parameter of step?.parameters || []) {
            if (parameter?.sourceStepId) {
                if (!stepReferencedInOtherStepsRef.current[step?.stepId]) {
                    stepReferencedInOtherStepsRef.current[step?.stepId] = {};
                }
                parameter?.sourceStepId &&
                    (stepReferencedInOtherStepsRef.current[step?.stepId][parameter?.sourceStepId] = 1);
            }
        }
    } else if (step?.stepType === constants.STEP_TYPE_CONDITIONAL) {
        const walkConditionalExpressions = ({ expressions, expressionIndexes }) => {
            expressions?.map((expression, expressionIndex) => {
                const groupExpressionList = expression.expressionList || [],
                    hasNestedExpression = groupExpressionList?.length > 0,
                    newExpressionIndexes = expressionIndexes.concat(expressionIndex);

                if (!stepReferencedInOtherStepsRef.current[step?.stepId]) {
                    stepReferencedInOtherStepsRef.current[step?.stepId] = {};
                }

                const sourceStepId = expression?.leftOperandSourceStepId || expression?.rightOperandSourceStepId;
                sourceStepId && (stepReferencedInOtherStepsRef.current[step?.stepId][sourceStepId] = 1);

                if (hasNestedExpression) {
                    walkConditionalExpressions({
                        expressions: groupExpressionList,
                        expressionIndexes: newExpressionIndexes,
                    });
                }
            });
        };

        for (const conditionalStep of step?.conditionalList || []) {
            walkConditionalExpressions({
                expressions: conditionalStep?.expressionList || [],
                expressionIndexes: [],
            });
        }
    }
};

/**
 * Setup step source ID references
 * @param {{stepId: number, step: object, stepReferencedInOtherStepsRef: React.MutableRefObjects }}
 */
const setupStepSourceIdReferences = ({ stepId, step, stepReferencedInOtherStepsRef } = {}) => {
    if (!stepId) return;

    const sourceStepId =
        step?.sourceStepId ||
        step?.sourceStepIdForLoop ||
        step?.requestBodySourceStepId ||
        step?.conditionSourceStepIdForComparison;

    if (!stepReferencedInOtherStepsRef.current[stepId]) {
        stepReferencedInOtherStepsRef.current[stepId] = {};
    }

    sourceStepId && (stepReferencedInOtherStepsRef.current[stepId][sourceStepId] = 1);

    new Promise((resolve) => {
        stepHasSourceDisplayLabel({ step, stepReferencedInOtherStepsRef });
        resolve(true);
    })
        .then(() => true)
        .catch();
};

/**
 * Check whether selected step is referenced from other step
 * @param {{stepId: Number, stepReferencedInOtherStepsRef: React.MutableRefObject}}
 * @return {Boolean}
 */
const isStepReferencedInOtherSteps = ({ stepId, stepReferencedInOtherStepsRef }) => {
    for (const _stepId in stepReferencedInOtherStepsRef.current) {
        if (stepReferencedInOtherStepsRef.current[_stepId][stepId]) {
            return true;
        }
    }
    return false;
};

const generateStringFromInputPath = (parentDataTypeId, fieldPath = []) => {
    const allDataTypes = getSchemas();

    if (!parentDataTypeId?.length) {
        return '';
    }

    if (!fieldPath?.length) {
        return '';
    }

    const selectedFieldId = fieldPath.at(-1).dataTypeFieldId;

    if (selectedFieldId === parentDataTypeId) {
        return allDataTypes.find((dataType) => dataType._id === selectedFieldId)?.name || '';
    }

    let parentObject = allDataTypes.find((dataType) => dataType._id === parentDataTypeId);

    if (!parentObject) {
        return '';
    }

    const parentName = `${parentObject.displayName ?? parentObject.name}`;

    const strings = fieldPath.map(({ dataTypeFieldId, occurrenceIndex }) => {
        const fieldObject =
            parentObject?.fields?.length > 0
                ? parentObject.fields.find((field) => field._id === dataTypeFieldId)
                : allDataTypes
                      .find((dataType) => dataType._id === parentObject.dataTypeId)
                      .fields.find((field) => field._id === dataTypeFieldId);

        parentObject = fieldObject || {};

        if (fieldObject) {
            return `${fieldObject.displayName ?? fieldObject.name}${
                occurrenceIndex === null ? '[*]' : occurrenceIndex > 0 ? `[${occurrenceIndex}]` : ''
            }`;
        } else {
            return '';
        }
    });

    return `${parentName}.${strings.join('.')}`;
};

/**
 * Get comparison step data type based on return type
 * @param {Object} comparisonStep
 * @returns {string}
 */
const getComparisonStepDataTypeId = (comparisonStep) => {
    let dataTypeId = '';
    const returnType = comparisonStep?.comparisonReturnType;

    if (returnType === 'csv') {
        dataTypeId = constants.COMPARISON_STEP_CSV_DATA_TYPE_ID;
    } else if (returnType === 'json') {
        dataTypeId = constants.COMPARISON_STEP_JSON_DATA_TYPE_ID;
    }

    return dataTypeId;
};

/**
 * Get tooltip text for modify variable data type
 * @param {string} dataType
 * @param {boolean} isArray
 * @returns {string}
 */
const getTooltipTextForModifyVariableActionType = (dataType, isArray) => {
    switch (dataType) {
        case constants.VARIABLE_DATATYPE_OBJECT:
            if (isArray === true) {
                return `Objects must be in JSON format. Object keys must be surrounded by double quotes unless they are variables.
                    \n Example: [{"key1": "value1"}, {$variable_one: "value2"}]`;
            } else {
                return `Objects must be in JSON format. Object keys must be surrounded by double quotes unless they are variables.
                    \n Example: {"key1": "value1", $variable_one: "value2"}`;
            }
        case constants.VARIABLE_DATATYPE_DATE:
            if (isArray === true) {
                return `Array Dates must be surrounded by double quotes unless using a variable. 
                    \n Example: ["2000-12-13", "12-13-2000", $date_variable]`;
            } else {
                return `Dates must be surrounded by double quotes unless using a variable. 
                    \n Example: "2000-12-13" or "12-13-2000" or $date_variable`;
            }
        case constants.VARIABLE_DATATYPE_STRING:
            if (isArray === true) {
                return `Array Strings must be surrounded by double quotes unless using a variable. 
                    \n Example: ["string1", "string2", $string_variable]`;
            } else {
                return '';
            }
        case constants.VARIABLE_DATATYPE_NUMBER:
            if (isArray === true) {
                return `Enter a comma separated list of numbers. 
                    \n Example: [1, 2, $number_variable]`;
            } else {
                return '';
            }
        case constants.VARIABLE_DATATYPE_BOOLEAN:
            if (isArray === true) {
                return `Enter a comma separated list of booleans. 
                    \n Example: [true, false, $boolean_variable]`;
            } else {
                return '';
            }
        default:
            return '';
    }
};

const updateIntegrationStepDataFromSchemaChanges = (stepData) => {
    // Due to changes on set variable step, we need to default action type to 'Set'
    const updateSetVariableData = (variables) => {
        return variables.map((variable) => {
            if (isValidVariableFieldData(variable) && !variable.actionType) {
                variable.actionType = constants.MODIFY_VARIABLE_TYPE_SET;
            }
            return variable;
        });
    };

    const updateBasedOnStepType = (stepData) => {
        switch (stepData.stepType) {
            case constants.STEP_TYPE_SET_VARIABLE:
                stepData.variables = updateSetVariableData(stepData.variables);
                break;
            case constants.STEP_TYPE_LOOP:
                stepData.containedSteps = stepData.containedSteps.map((step) => updateBasedOnStepType(step));
                break;
            default:
                break;
        }

        return stepData;
    };

    updateBasedOnStepType(stepData);

    return stepData;
};

/**
 * Gets a trimmed array for saving the string email list.
 * @returns {string[]}
 */
const getEmailAddressArray = (currentValue) => {
    const emails = currentValue.split(',');
    const returnEmailList = [];
    for (const email of emails) {
        if (email.trim() !== '') {
            returnEmailList.push(email.trim());
        }
    }

    return returnEmailList;
};

/**
 * @typedef AuthorizationConfigBearerTokenData
 * @property {string} bearerMainTokenVariable
 * @property {boolean} bearerRequireTokenUrl
 * @property {string?} bearerTokenUrlMethod
 * @property {string?} bearerMainAuthorizationEndpoint
 * @property {string?} bearerAuthorizationEndpoint
 * @property {FormKeyValuePair[]?} bearerQueryParams
 * @property {FormKeyValuePair[]?} bearerHeaders
 * @property {string?} bearerMainTokenPropertyName
 * @property {string?} bearerAuthorizationType
 * @property {string?} bearerMethod
 * @property {string?} bearerPersistentTokenData
 * @property {string?} bearerTokenUrl
 * @property {string?} bearerTokenPropertyName
 * @property {string?} bearerClientId
 * @property {string?} bearerClientSecret
 * @property {string?} bearerAudience
 * @property {string?} bearerUsername
 * @property {string?} bearerPassword
 * @property {string?} bearerKeyLocation
 * @property {string?} bearerHeaderField
 * @property {string?} bearerApiKey
 */

/**
 * @typedef AuthorizationConfig
 * @property {string} authorizationType
 * @property {string?} method
 * @property {string?} authorizationEndpoint
 * @property {string?} clientId
 * @property {string?} clientSecret
 * @property {string?} audience
 * @property {string?} persistentTokenData
 * @property {string?} tokenPropertyName
 * @property {string?} username
 * @property {string?} password
 * @property {string?} keyLocation
 * @property {string?} headerField
 * @property {string?} apiKey
 * @property {AuthorizationConfigBearerTokenData?} bearerTokenData
 */

/**
 * TODO Move to lib-common to share with executor?
 * @param {AuthorizationConfig} authorizationObject
 * @param {boolean} returnBoolean
 * @returns {boolean|Object}
 */
const isValidAuthorizationConfig = (authorizationObject, returnBoolean = true) => {
    const errorObject = {};

    if (authorizationObject?.authorizationType) {
        switch (authorizationObject?.authorizationType) {
            case 'basic':
                if (!authorizationObject?.username) {
                    errorObject['username'] = true;
                }
                if (!authorizationObject?.password) {
                    errorObject['password'] = true;
                }
                break;
            case 'apiKey':
                if (!authorizationObject?.keyLocation) {
                    errorObject['keyLocation'] = true;
                }
                if (!authorizationObject?.headerField) {
                    errorObject['headerField'] = true;
                }
                if (!authorizationObject?.apiKey) {
                    errorObject['apiKey'] = true;
                }
                break;
            case 'bearer':
                if (authorizationObject?.bearerTokenData) {
                    const bearerTokenData = authorizationObject.bearerTokenData;

                    if (bearerTokenData?.bearerMainTokenVariable) {
                        if (bearerTokenData?.bearerRequireTokenUrl) {
                            if (
                                Array.isArray(bearerTokenData?.bearerHeaders) &&
                                !isValidKeyValuePairList(bearerTokenData.bearerHeaders)
                            ) {
                                errorObject['bearerHeaders'] = true;
                            }

                            if (
                                Array.isArray(bearerTokenData?.bearerQueryParams) &&
                                !isValidKeyValuePairList(bearerTokenData.bearerQueryParams)
                            ) {
                                errorObject['bearerQueryParams'] = true;
                            }

                            if (!bearerTokenData?.bearerTokenUrlMethod) {
                                errorObject['bearerTokenUrlMethod'] = true;
                            }

                            if (
                                !bearerTokenData?.bearerMainAuthorizationEndpoint ||
                                bearerTokenData.bearerMainAuthorizationEndpoint === ''
                            ) {
                                errorObject['bearerMainAuthorizationEndpoint'] = true;
                            }

                            if (
                                bearerTokenData?.bearerTokenUrlMethod &&
                                bearerTokenData?.bearerMainAuthorizationEndpoint
                            ) {
                                if (bearerTokenData?.bearerAuthorizationType) {
                                    switch (bearerTokenData.bearerAuthorizationType) {
                                        case 'oauth':
                                            if (!bearerTokenData?.bearerClientId) {
                                                errorObject['bearerClientId'] = true;
                                            }
                                            if (!bearerTokenData?.bearerClientSecret) {
                                                errorObject['bearerClientSecret'] = true;
                                            }
                                            break;
                                        case 'apiKey':
                                            if (!bearerTokenData?.bearerKeyLocation) {
                                                errorObject['bearerKeyLocation'] = true;
                                            }
                                            if (!bearerTokenData?.bearerHeaderField) {
                                                errorObject['bearerHeaderField'] = true;
                                            }
                                            if (!bearerTokenData?.bearerApiKey) {
                                                errorObject['bearerApiKey'] = true;
                                            }
                                            break;
                                        case 'basic':
                                            if (!bearerTokenData?.bearerUsername) {
                                                errorObject['bearerUsername'] = true;
                                            }
                                            if (!bearerTokenData?.bearerPassword) {
                                                errorObject['bearerPassword'] = true;
                                            }
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                        }
                    } else {
                        errorObject['bearerMainTokenVariable'] = true;
                    }
                }
                break;
            default:
                break;
        }
    } else {
        errorObject['authorizationType'] = true;
    }

    if (returnBoolean) {
        return Object.keys(errorObject).length === 0;
    } else {
        return errorObject;
    }
};

/**
 * Validates auth step data
 * @param stepData
 * @param errorList
 * @returns {boolean}
 */
const isRestfulAuthorizationStepTypeValid = ({ stepData, errorList }) => {
    if (stepData?.restfulAuthorization) {
        const authorizationConfigErrors = isValidAuthorizationConfig(stepData.restfulAuthorization, false);

        if (authorizationConfigErrors?.['authorizationType']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid authorization type is required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['username'] || authorizationConfigErrors?.['bearerUsername']) {
            addValidationErrorObject(ERROR_CATEGORY_AUTHORIZATION, 'A valid username is required', stepData, errorList);
        }
        if (authorizationConfigErrors?.['password'] || authorizationConfigErrors?.['bearerPassword']) {
            addValidationErrorObject(ERROR_CATEGORY_AUTHORIZATION, 'A valid password is required', stepData, errorList);
        }
        if (authorizationConfigErrors?.['keyLocation']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid key location is required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['keyLocation'] || authorizationConfigErrors?.['bearerKeyLocation']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid key location is required',
                stepData,
                errorList,
            );
        }
        if (
            authorizationConfigErrors?.['headerField'] ||
            authorizationConfigErrors?.['bearerHeaders'] ||
            authorizationConfigErrors?.['bearerHeaderField']
        ) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'Valid header fields is required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['apiKey'] || authorizationConfigErrors?.['bearerApiKey']) {
            addValidationErrorObject(ERROR_CATEGORY_AUTHORIZATION, 'A valid API key is required', stepData, errorList);
        }
        if (authorizationConfigErrors?.['bearerQueryParams']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'Valid query parameters are required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['bearerTokenUrlMethod']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid token URL method is required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['bearerMainAuthorizationEndpoint']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid authorization URL is required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['bearerClientId']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid client ID is required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['bearerClientSecret']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid client secret is required',
                stepData,
                errorList,
            );
        }
        if (authorizationConfigErrors?.['bearerMainTokenVariable']) {
            addValidationErrorObject(
                ERROR_CATEGORY_AUTHORIZATION,
                'A valid token variable is required',
                stepData,
                errorList,
            );
        }

        return Object.keys(authorizationConfigErrors).length === 0;
    } else {
        addValidationErrorObject(
            ERROR_CATEGORY_AUTHORIZATION,
            'A valid authorization configuration is required',
            stepData,
            errorList,
        );
    }

    return false;
};

/**
 * @typedef AuthorizationFieldDefinition
 * @property {string} label
 * @property {string} field
 * @property {boolean} required
 * @property {string} type
 * @property {Object[]?} values
 * @property {(option: string|object) => string?} getItemId
 * @property {(option: string|object) => string?} getItemLabel
 * @property {(option: string|object) => string?} getItemValue
 */

/**
 * Returns a modified version of the field name if necessary.
 * @param fieldName
 * @param fieldNamePrefix
 * @returns {*|string}
 */
const getAuthorizationFieldWithPrefix = (fieldName, fieldNamePrefix = false) => {
    if (fieldNamePrefix !== false && fieldNamePrefix !== '') {
        const fieldNameUpper = fieldName.substring(0, 1).toUpperCase() + fieldName.substring(1);

        return fieldNamePrefix + fieldNameUpper;
    }

    return fieldName;
};

/**
 *
 * @param {AuthorizationConfig} authorizationConfig
 * @param {IntegrationData} selectedIntegrationData
 * @param {string|false} fieldNamePrefix
 * @returns {[{field: string, label: string, type: string, required: boolean}]|[{field: string, label: string, type: string, required: boolean},{field: string, label: string, type: string, required: boolean}]|[{getItemId: (function(*): *), getItemLabel: ((function(*): (string|*))|*), field: string, values: string[], label: string, type: string, required: boolean, getItemValue: (function(*): *)},{field: string, label: string, type: string, required: boolean}]|[{getItemId: (function(*): *), getItemLabel: ((function(*): (string|*))|*), field: string, values: [{name: string},...*|*[]], label: string, type: string, required: boolean, getItemValue: (function(*): string)},{field: string, label: string, type: string, required: boolean},{field: string, label: string, type: string, required: boolean},{field: string, label: string, type: string, required: boolean},{field: string, label: string, type: string},null]|*[]}
 */
const getAuthorizationFieldsForType = (authorizationConfig, selectedIntegrationData, fieldNamePrefix = false) => {
    switch (authorizationConfig?.[getAuthorizationFieldWithPrefix('authorizationType', fieldNamePrefix)]) {
        case 'oauth':
            return [
                {
                    label: 'Client ID',
                    field: getAuthorizationFieldWithPrefix('clientId', fieldNamePrefix),
                    required: true,
                    type: 'text',
                },
                {
                    label: 'Client Secret',
                    field: getAuthorizationFieldWithPrefix('clientSecret', fieldNamePrefix),
                    required: true,
                    type: 'password',
                },
                {
                    label: 'Audience',
                    field: getAuthorizationFieldWithPrefix('audience', fieldNamePrefix),
                    type: 'text',
                },
            ];
        case 'basic':
            return [
                {
                    label: 'Username',
                    field: getAuthorizationFieldWithPrefix('username', fieldNamePrefix),
                    required: true,
                    type: 'text',
                },
                {
                    label: 'Password',
                    field: getAuthorizationFieldWithPrefix('password', fieldNamePrefix),
                    required: true,
                    type: 'password',
                },
            ];
        case 'apiKey':
            return [
                {
                    label: 'Location',
                    field: getAuthorizationFieldWithPrefix('keyLocation', fieldNamePrefix),
                    required: true,
                    type: 'select',
                    values: [
                        { label: 'None', value: '' },
                        { label: 'Query Parameters', value: 'queryParams' },
                        { label: 'Headers', value: 'headers' },
                    ],
                    getItemId: (option) => option.value,
                    getItemLabel: (option) => option.label,
                    getItemValue: (option) => option.value,
                },
                {
                    label: 'Field Name',
                    field: getAuthorizationFieldWithPrefix('headerField', fieldNamePrefix),
                    required: true,
                    type: 'text',
                },
                {
                    label: 'API Key',
                    field: getAuthorizationFieldWithPrefix('apiKey', fieldNamePrefix),
                    required: true,
                    type: 'password',
                },
            ];
        case 'bearer':
            return [];
    }

    return [];
};

/**
 * @param {string} string
 * @param {IntegrationVariable[]} variables
 * @returns {{resolvedString: string, hasError: boolean, errors: string[]}|{resolvedString: string, hasError: boolean, errors: *[]}}
 */
export const resolveDummyDataForIntegration = (string, variables) => {
    if (typeof string !== 'string' || string === '') {
        return {
            resolvedString: '',
            errors: [],
            hasError: false,
        };
    }

    const hasVariablesOrCounters = string.match(/(^|[^\\]|[^\\"])\$\w/) !== null,
        hasFunctions = string.match(/(^|[^\\])\$\{.+\}/) !== null;

    let resolvedString = `${string}`;

    if (hasFunctions) {
        const result = parseFunctionsFromString(resolvedString);
        if (result.hasError) {
            return {
                resolvedString: '',
                errors: result.errors,
                hasError: true,
            };
        }
    }

    if (hasVariablesOrCounters) {
        resolvedString = resolveDummyVariablesInString(resolvedString, variables);
    } else {
        resolvedString = resolvedString.replace(/[\\]\$[\w]/g, (match) => {
            if (match.length > 2) {
                return match.substring(1);
            } else {
                return match;
            }
        });
    }

    if (hasFunctions) {
        const parseFunctionsOutput = parseFunctionsFromString(resolvedString);

        if (parseFunctionsOutput.hasError) {
            return {
                resolvedString: '',
                errors: parseFunctionsOutput.errors,
                hasError: true,
            };
        }

        for (const functionString of parseFunctionsOutput.functions) {
            resolvedString = resolvedString.replace(`\${${functionString}}`, '');
        }
    }

    return {
        resolvedString,
        errors: [],
        hasError: false,
    };
};

/**
 * @param {string} variableType
 * @param {boolean} isArray
 * @returns {string}
 */
export const getDefaultTestValueForVariableType = (variableType, isArray) => {
    let testValue = '';
    let requiresArrayEncapsulation = false;

    switch (variableType) {
        case constants.VARIABLE_DATATYPE_BOOLEAN:
            testValue = 'false';
            break;
        case constants.VARIABLE_DATATYPE_NUMBER:
            testValue = '123';
            break;
        case constants.VARIABLE_DATATYPE_OBJECT:
            testValue = '{}';
            break;
        case constants.VARIABLE_DATATYPE_STRING:
            requiresArrayEncapsulation = true;
            testValue = 'string_value';
            break;
        case constants.VARIABLE_DATATYPE_DATE:
            requiresArrayEncapsulation = true;
            testValue = '2024-01-23';
            break;
        default:
            break;
    }

    if (isArray) {
        if (requiresArrayEncapsulation) {
            return `[ "${testValue}" ]`;
        } else {
            return `[ ${testValue} ]`;
        }
    }

    return testValue;
};

/**
 * @param {string} stringToParse
 * @returns {{functions: string[], errors: string[], hasError: boolean}}
 */
export const parseFunctionsFromString = (stringToParse) => {
    const functions = [],
        errors = [];
    let openBrackets = 0;

    for (let i = 0; i < stringToParse.length; i++) {
        if (stringToParse[i] === '$' && stringToParse[i + 1] === '{' && stringToParse[i - 1] !== '\\') {
            let functionString = '';
            let j = i + 2;
            openBrackets++;

            while (stringToParse[j] !== '}' && j < stringToParse.length) {
                functionString += stringToParse[j];
                j++;
            }

            if (functionString.length > 0) {
                functions.push(functionString);
                openBrackets--;
            }

            i = j;
        }
    }

    if (openBrackets < 0) {
        errors.push('Error: Missing opening bracket in function');
    }

    if (openBrackets > 0) {
        errors.push('Error: Missing closing bracket in function');
    }

    return {
        functions,
        errors,
        hasError: errors.length > 0,
    };
};

/**
 * A hacky way to determine if the selected field is the "root" of a loops iteration data, not the root of the datatype.
 * TODO should implement a dedicated property for this instead of using the label
 * @param {SelectedFieldParameters} selectedFieldParameters
 * @returns {boolean}
 */
export const isSelectedFieldParameterRootOfLoopStepData = (selectedFieldParameters) => {
    return !!(
        (
            selectedFieldParameters?.stepId &&
            /^Step [0-9]+$/.test(selectedFieldParameters?.displayLabel) &&
            selectedFieldParameters?.inputPath?.length === 0
        )
        // selectedFieldParameters?.dataTypeId
    );
};

/**
 * @param {string} string
 * @param {IntegrationVariable[]} variables
 * @returns {string}
 */
const resolveDummyVariablesInString = (string, variables) => {
    let resolvedString = `${string}`;

    const variablesAndCountersInString = parseVariablesAndCountersFromString(string);

    for (let i = 0; i < variablesAndCountersInString.length; i++) {
        const variableOrCounterName = variablesAndCountersInString[i] || '';
        let variableValue;

        if (constants.RESERVED_VARIABLE_NAMES.includes(variableOrCounterName)) {
            variableValue = '1';
        } else {
            const foundVariable = variables?.find((variable) => variable.name === variableOrCounterName);
            if (foundVariable) {
                variableValue = getDefaultTestValueForVariableType(foundVariable.dataType, foundVariable.isArray);
            }
        }

        if (variableValue !== undefined) {
            resolvedString = resolvedString.replace(`$${variableOrCounterName}`, variableValue);
        } else {
            resolvedString = resolvedString.replace(`$${variableOrCounterName}`, '1');
        }
    }

    return resolvedString;
};

/**
 * Helper function to parse out variables from a string
 */
export const parseVariablesAndCountersFromString = (stringToParse) => {
    const variablesAndCounters = [];
    let variableOrCounter = '';

    for (let i = 0; i < stringToParse.length; i++) {
        if (stringToParse[i] === '$' && stringToParse[i - 1] !== '\\') {
            let j = i + 1;

            while (stringUtils.isAlphaNumeric(stringToParse[j] || '', ['_', '-']) && j < stringToParse.length) {
                variableOrCounter += stringToParse[j];
                j++;
            }

            if (variableOrCounter.length > 0) {
                variablesAndCounters.push(variableOrCounter);
                variableOrCounter = '';
            }
        }
    }

    if (variableOrCounter.length > 0) {
        variablesAndCounters.push(variableOrCounter);
    }

    return variablesAndCounters;
};

/**
 * @typedef {{}} SelectedFieldParameters
 * @property {number|null} stepId
 * @property {[]|null} inputPath
 * @property {string|ObjectId|null} dataTypeId
 * @property {string|null} displayLabel
 * @property {[]|null} filters
 * @property {string|ObjectId|null} crosswalkId
 * @property {'length'|'value'} retrieveValueType
 */

/**
 * @typedef {{}} InputTypeIndexes
 * @property {number} [inputMappingIndex] index of the element with inputMappings for the STEP_FIELD_SELECT_INPUT_TYPE_CONVERT type
 * @property {number} [variableIndex] index for variables array for the STEP_FIELD_SELECT_INPUT_TYPE_SET_VARIABLE type
 * @property {number} [crosswalkIndex] index for the valuesToCrosswalk array for the STEP_FIELD_SELECT_INPUT_TYPE_SET_CROSSWALK_INPUT type
 * @property {string} [fieldKey] Field key from the advanced step set field/option input type
 * @property {number} [parameterIndex] index of the parameter within parameters array for STEP_FIELD_SELECT_INPUT_TYPE_STEP_PARAMETER or STEP_FIELD_SELECT_INPUT_TYPE_SET_EXECUTE_SCRIPT_PARAMETER types
 * @property {number} [conditionIndex]
 * @property {number[]} [expressionIndexes]
 * @property {number} [comparisonInputIndex] index of the comparisonInputs array for the STEP_FIELD_SELECT_INPUT_TYPE_COMPARISON_INPUT type
 * @property {number} [restfulRequestStepIndex]
 */

/**
 *
 *
 * @param {number} stepId
 * @param {string} inputType
 * @param {InputTypeIndexes} [indexes] if specified, contains properties which relate to a specific element of certain arrays within the integration step data
 * @returns {SelectedFieldParameters}
 */
const getSelectedFieldDataSourcePropertiesForStepInputByStepId = (stepId, inputType, indexes) => {
    const stepData = getCopyOfIntegrationStepById(stepId);

    switch (inputType) {
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_CONVERT:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.inputMappings[indexes.inputMappingIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_SET_VARIABLE:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.variables[indexes.variableIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_SET_CROSSWALK_INPUT:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.valuesToCrosswalk[indexes.crosswalkIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_ADVANCED_SET_FIELD: {
            const fieldData = stepData?.advancedOptions?.fields?.find((item) => item.key === indexes.fieldKey);
            return getSelectedFieldDataSourcePropertiesForStepInput(fieldData, inputType);
        }
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_ADVANCED_SET_OPTION:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.advancedOptions?.[indexes.fieldKey],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_SET_EXECUTE_SCRIPT_PARAMETER:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.executeScriptParameters?.[indexes.parameterIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_QUERY_PARAM:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.['restfulRequestStepQueryParams']?.[indexes.restfulRequestStepIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_HEADER:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.['restfulRequestStepHeaders']?.[indexes.restfulRequestStepIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_BODY:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.['restfulRequestStepBody']?.[indexes.restfulRequestStepIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_EXPRESSION_GROUP_FIELD_LEFT:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_EXPRESSION_GROUP_FIELD_RIGHT:
            {
                const condition = stepData?.conditionList[indexes.conditionIndex];
                if (condition) {
                    const copyOfExpressionIndexes = [...indexes.expressionIndexes];
                    let nextIndex, selectedExpression;

                    while ((nextIndex = copyOfExpressionIndexes.shift())) {
                        selectedExpression = condition.expressionList[nextIndex];
                    }

                    return getSelectedFieldDataSourcePropertiesForStepInput(selectedExpression, inputType);
                }
            }
            break;
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMPARISON_INPUT:
            return getSelectedFieldDataSourcePropertiesForStepInput(
                stepData?.comparisonInputs?.[indexes.comparisonInputIndex],
                inputType,
            );
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_SPLIT_FILE_RELATIVE_PATH:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_SPLIT_FILE_DESTINATION_PATH:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_RELATIVE_PATH:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_DESTINATION_PATH:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_FILE_NAME:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMPARISON_FILE_DESTINATION:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_CASE_CONDITIONAL:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_CASE_CONDITIONAL_VALUE:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_RUN_INTEGRATION_TRIGGER_DATA:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_STEP_PARAMETER:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_REQUEST_BODY_STEP_OUTPUT:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_LOOP_INTELY_STORAGE_FILE_PATH:
        case constants.STEP_FIELD_SELECT_INPUT_TYPE_LOOP_STEP_LOOP_ON:
        default:
            break;
    }

    return getSelectedFieldDataSourcePropertiesForStepInput(stepData, inputType);
};

/**
 * Get a normalized object of selected field properties back from different type of input properties off of step data.
 *
 * @param data
 * @param inputType
 * @returns {SelectedFieldParameters}
 */
const getSelectedFieldDataSourcePropertiesForStepInput = (data, inputType) => {
    const returnValue = {
        stepId: null,
        dataTypeId: null,
        inputPath: [],
        filters: [],
        displayLabel: '',
        crosswalkId: null,
        retrieveValueType: constants.STEP_FIELD_SELECT_RETRIEVE_VALUE_TYPE_VALUE,
    };

    if (data) {
        switch (inputType) {
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_STEP_PARAMETER:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceDataTypeInputPath;
                returnValue.displayLabel = data?.sourceDisplayLabel;
                returnValue.filters = data?.sourceDataTypeInputPathFilters;
                returnValue.crosswalkId = data?.crosswalkId;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_CONVERT:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_ADVANCED_SET_OPTION:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_ADVANCED_SET_FIELD:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceInputPath;
                returnValue.displayLabel = data?.sourceDisplayLabel;
                returnValue.filters = data?.sourceInputFilters;
                returnValue.crosswalkId = data?.crosswalkId;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_REQUEST_BODY_STEP_OUTPUT:
                returnValue.stepId = data?.requestBodySourceStepId;
                returnValue.dataTypeId = data?.requestBodySourceDataTypeId;
                returnValue.inputPath = data?.requestBodySourceInputPath;
                returnValue.displayLabel = data?.requestBodySourceDisplayLabel;
                returnValue.filters = data?.requestBodyInputFilters;
                returnValue.crosswalkId = data?.requestBodyCrosswalkId;
                returnValue.retrieveValueType = data?.requestBodySourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_SET_VARIABLE:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceInputPath;
                returnValue.displayLabel = data?.sourceDisplayLabel;
                returnValue.filters = data?.sourceInputFilters;
                returnValue.crosswalkId = data?.crosswalkId;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_SET_CROSSWALK_INPUT:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceInputPath;
                returnValue.displayLabel = data?.sourceDisplayLabel;
                returnValue.filters = data?.sourceInputFilters;
                returnValue.crosswalkId = data?.crosswalkId;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_SPLIT_FILE_RELATIVE_PATH:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_SPLIT_FILE_DESTINATION_PATH:
                {
                    let displayLabel = '',
                        innerDataObject;

                    if (inputType === constants.STEP_FIELD_SELECT_INPUT_TYPE_SPLIT_FILE_RELATIVE_PATH) {
                        innerDataObject = data?.relativeFilePath;
                    } else {
                        innerDataObject = data?.destinationFolderPath;
                    }

                    if (innerDataObject?.sourceStepId) {
                        displayLabel = generateStringFromInputPath(
                            innerDataObject.sourceDataTypeId,
                            innerDataObject.sourceInputPath,
                        );
                    } else {
                        displayLabel = innerDataObject?.path;
                    }

                    returnValue.stepId = innerDataObject?.sourceStepId;
                    returnValue.dataTypeId = innerDataObject?.sourceDataTypeId;
                    returnValue.inputPath = innerDataObject?.sourceInputPath;
                    returnValue.displayLabel = displayLabel;
                    returnValue.filters = innerDataObject?.sourceInputFilters;
                    returnValue.crosswalkId = innerDataObject?.crosswalkId;
                    returnValue.retrieveValueType = innerDataObject?.sourceRetrieveValueType;
                }
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_RELATIVE_PATH:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceInputPath;
                returnValue.displayLabel = data?.sourceStepId
                    ? generateStringFromInputPath(data.sourceDataTypeId, data.sourceInputPath)
                    : data?.path || '';
                returnValue.filters = data?.sourceInputFilters;
                returnValue.crosswalkId = data?.crosswalkId;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_DESTINATION_PATH:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_FILE_NAME:
                {
                    let displayLabel = '';
                    if (inputType === constants.STEP_FIELD_SELECT_INPUT_TYPE_COMBINE_FILE_FILE_NAME) {
                        displayLabel = data?.fileName?.sourceStepId
                            ? generateStringFromInputPath(data.fileName.sourceDataTypeId, data.fileName.sourceInputPath)
                            : data?.fileName?.name || '';
                    } else {
                        displayLabel = data?.destinationFolderPath?.sourceStepId
                            ? generateStringFromInputPath(
                                  data.destinationFolderPath.sourceDataTypeId,
                                  data.destinationFolderPath.sourceInputPath,
                              )
                            : data?.destinationFolderPath?.path || '';
                    }

                    returnValue.stepId = data?.sourceStepId;
                    returnValue.dataTypeId = data?.sourceDataTypeId;
                    returnValue.inputPath = data?.sourceInputPath;
                    returnValue.displayLabel = displayLabel;
                    returnValue.filters = data?.sourceInputFilters;
                    returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                    returnValue.crosswalkId = data?.crosswalkId;
                }
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMPARISON_INPUT:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_COMPARISON_FILE_DESTINATION:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceInputPath;
                returnValue.displayLabel = data?.sourceDisplayLabel;
                returnValue.filters = data?.sourceInputFilters;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                returnValue.crosswalkId = data?.crosswalkId;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_SET_EXECUTE_SCRIPT_PARAMETER:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceInputPath;
                returnValue.displayLabel = data?.sourceDisplayLabel;
                returnValue.filters = data?.sourceInputFilters;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                returnValue.crosswalkId = data?.crosswalkId;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_LOOP_INTELY_STORAGE_FILE_PATH:
                returnValue.stepId = data?.loopInputIntelyFilePathSourceStepId;
                returnValue.dataTypeId = data?.loopInputIntelyFilePathDataTypeId;
                returnValue.inputPath = data?.loopInputIntelyFilePathInputPath;
                returnValue.displayLabel = data?.loopInputIntelyFilePathDisplayLabel;
                returnValue.filters = data?.loopInputFilters;
                returnValue.retrieveValueType = data?.loopInputRetrieveValueType;
                returnValue.crosswalkId = data?.loopInputCrosswalkId;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_CASE_CONDITIONAL:
                returnValue.stepId = data?.conditionSourceStepIdForComparison;
                returnValue.dataTypeId = data?.conditionSourceDataTypeIdForComparison;
                returnValue.inputPath = data?.conditionSourceInputPathForComparison;
                returnValue.displayLabel = data?.conditionSourceDataTypeDisplayLabelForComparison;
                returnValue.filters = data?.conditionSourceFilters;
                returnValue.retrieveValueType = data?.conditionSourceRetrieveValueType;
                returnValue.crosswalkId = data?.conditionCrosswalkId;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_CASE_CONDITIONAL_VALUE:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_EXPRESSION_GROUP_FIELD_RIGHT:
                returnValue.stepId = data?.rightOperandSourceStepId;
                returnValue.dataTypeId = data?.rightOperandSourceDataTypeId;
                returnValue.inputPath = data?.rightOperandSourceInputPath;
                returnValue.displayLabel = data?.rightOperandSourceDataTypeDisplayLabel || data?.rightOperandValue;
                returnValue.filters = data?.rightOperandSourceFilters;
                returnValue.crosswalkId = data?.rightOperandCrosswalkId;
                returnValue.retrieveValueType = data?.rightOperandSourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_EXPRESSION_GROUP_FIELD_LEFT:
                returnValue.stepId = data?.leftOperandSourceStepId;
                returnValue.dataTypeId = data?.leftOperandSourceDataTypeId;
                returnValue.inputPath = data?.leftOperandSourceInputPath;
                returnValue.displayLabel = data?.leftOperandSourceDataTypeDisplayLabel || data?.leftOperandValue;
                returnValue.filters = data?.leftOperandSourceFilters;
                returnValue.crosswalkId = data?.leftOperandCrosswalkId;
                returnValue.retrieveValueType = data?.leftOperandSourceRetrieveValueType;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_LOOP_STEP_LOOP_ON:
                returnValue.stepId = data?.sourceStepIdForLoop;
                returnValue.dataTypeId = data?.sourceDataTypeIdForLoop;
                returnValue.inputPath = data?.sourceInputPathForLoop;
                returnValue.displayLabel = data?.sourceDataTypeDisplayLabelForLoop;
                returnValue.filters = data?.sourceFiltersForLoop;
                returnValue.retrieveValueType = data?.sourceRetrieveValueTypeForLoop;
                returnValue.crosswalkId = data?.crosswalkIdForLoop;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_RUN_INTEGRATION_TRIGGER_DATA:
                returnValue.stepId = data?.integrationDataSourceStepId;
                returnValue.dataTypeId = data?.integrationDataTypeId;
                returnValue.inputPath = data?.integrationDataSourceDataTypeInputPath;
                returnValue.displayLabel = data?.integrationDataSourceDisplayLabel;
                returnValue.filters = data?.integrationDataFilters;
                returnValue.retrieveValueType = data?.integrationDataRetrieveValueType;
                returnValue.crosswalkId = data?.integrationDataCrosswalkId;
                break;
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_QUERY_PARAM:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_HEADER:
            case constants.STEP_FIELD_SELECT_INPUT_TYPE_RESTFUL_REQUEST_BODY:
                returnValue.stepId = data?.sourceStepId;
                returnValue.dataTypeId = data?.sourceDataTypeId;
                returnValue.inputPath = data?.sourceInputPath;
                returnValue.displayLabel = data?.sourceDisplayLabel;
                returnValue.filters = data?.sourceInputFilters;
                returnValue.retrieveValueType = data?.sourceRetrieveValueType;
                returnValue.crosswalkId = data?.crosswalkId;
                break;
        }
    }

    return returnValue;
};

export {
    getStepLabel,
    getStepTypes,
    traverseSteps,
    getTriggerList,
    getStepTypeIcon,
    getLoopIterations,
    scrollToLogStep,
    getTriggerStepData,
    getDefaultStepList,
    getMutableLoopStep,
    scrollToLogStepById,
    scrollToLogStepByClass,
    getEnabledStepTypes,
    isIntegrationActive,
    getDefaultStepObject,
    isIntegrationPending,
    getEmailAddressArray,
    resolveDummyVariablesInString,
    getEndConditionalStep,
    isIntegrationDisabled,
    getLastGeneratedStepId,
    getLogEntryStepElement,
    getDefaultTriggerObject,
    getTriggerValueForLabel,
    isValidAuthorizationConfig,
    isContentTypeRawInputValue,
    getIntegrationStepByStepId,
    traverseStepsForValidation,
    getMutableIntegrationSteps,
    generateStringFromInputPath,
    generateSelectedFieldObject,
    getComparisonStepDataTypeId,
    setupStepSourceIdReferences,
    isStepReferencedInOtherSteps,
    getTranslatedStepListForSave,
    updateIntegrationStepIndexes,
    getAuthorizationFieldsForType,
    getLogOutputEntryObjectStepId,
    getMutableIntegrationStepDeep,
    filterIntegrationStepByStepId,
    normalizeIntegrationStepsData,
    highlightIntegrationTestOutput,
    getSelectedIntegrationStepData,
    hasValidTriggerSchedulerValues,
    getAuthorizationFieldWithPrefix,
    getLogOutputParentWrapperStyles,
    isValidTriggerSchedulerInterval,
    addEndConditionalStepToStepList,
    isValidTriggerSchedulerFrequency,
    filterConditionalStepExpression,
    normalizeAppRequestStepParameter,
    getPreviousStepsInRelatedBranches,
    getMutableIntegrationStepByStepId,
    addLastElseConditionToConditionList,
    clearConditionalIndexValidationError,
    filterIntegrationStepMetadataByStepId,
    getConditionalStepRelatedNextStepsIds,
    getTranslatedStepDataFromIntegrationData,
    getIntegrationLogOutputHeadingStyleObject,
    hasValidTriggerSchedulerIntervalAndFrequency,
    isListOfContainedStepsValidForParallelLoopStep,
    hasValidTriggerSchedulerIntervalAndMonthlyInterval,
    isValidTriggerSchedulerRecurringMonthlyFrequencyInterval,
    getTooltipTextForModifyVariableActionType,
    updateIntegrationStepDataFromSchemaChanges,
    handleSetStepValidationErrorState,
    buildRecursiveMapOfStepsFromLog,
    getUniqueLogIds,
    getCopyOfIntegrationStepById,
    getSelectedFieldDataSourcePropertiesForStepInput,
    getSelectedFieldDataSourcePropertiesForStepInputByStepId,
};
